import React, { useEffect, useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
// Services
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const TapriManDialog = props =>{

    const zoneItems = [
        {name : 'East', code: 'east'},
        {name : 'West', code: 'west'},
        {name : 'South', code: 'south'},
        {name : 'North', code: 'north'}
    ]

    const [country,setCountry] = useState(null)
    const [state,setState] = useState(null)
    const [city,setCity] = useState(null)
    const [selectedCountry,setSelectedCountry] = useState(null)
    const [selectedState,setSelectedState] = useState(null)
    const [selectedCity,setSelectedCity] = useState(null)
    const [cnoVerify,setCnoVerify] = useState(false)
    const [selectedZone,setSelectedZone] = useState(null)
    const [permissions,setPermissions] = useState(null)
    const [accountManagers,setAccountManagers] = useState(null)
    const [selectedOwner,setSelectedOwner] = useState(null)
    const [contactNum,setContactNum] = useState(null)
    const [userData,setUserData] = useState(null)

    const toast = useRef(null)


    useEffect(()=>{
        getUserData(props.taprimanData,props.allUsers)
        getCountry()
    },[props.taprimanData])


    const getUserData = async(data,data1) =>{
        if(data){
            setUserData(data)
            setContactNum(data?.cno)
            if(data?.userdata?.zone == 'east'){
                setSelectedZone({name : 'East', code: 'east'})
            }else if(data?.userdata?.zone == 'west'){
                setSelectedZone({name : 'West', code: 'west'})
            }else if(data?.userdata?.zone == 'south'){
                setSelectedZone({name : 'South', code: 'south'})
            }else if(data?.userdata?.zone == 'north'){
                setSelectedZone({name : 'North', code: 'north'})
            }


            setSelectedCountry(data?.userdata?.country)
            const res = await apiServices.getState({ country_id: data?.userdata?.country.id });
            setState(res.data);
            setSelectedState(data?.userdata?.state)
            const res1 = await apiServices.getCity({ state_id: data?.userdata?.state.id });
            setCity(res1.data);
            setSelectedCity(data?.userdata?.city)
            setPermissions(data?.permission)

            if(props.selectedUserType == 'superman'){

                // const accMan = data1?.filter((pos) => pos.userType == 'marketmanager')
                const accMan = data1?.filter((pos) => pos.userType == 'accountmanager')
                setAccountManagers(accMan)
                const selected = accMan.filter((x)=>{if(x.userid == data?.addedBy?.accountmanager){return x}})
                setSelectedOwner((selected.length > 0) ? selected[0] : null)
            }

            if(props.selectedUserType == 'accountmanager'){
                const accMan = data1?.filter((pos) => pos.userType == 'marketmanager')
                setAccountManagers(accMan)
                const selected = accMan.filter((x)=>{if(x.userid == data?.addedBy?.marketmanager){return x}})
                setSelectedOwner((selected.length > 0) ? selected[0] : null)
            }   
        }
    }


    const getCountry = async() =>{
        const res = await apiServices.getCountry();
        setCountry(res?.data)
    }

    const changeCountry = async(e) =>{
        setSelectedCountry(e.target.value)
        const res = await apiServices.getState({ country_id: e.target.value.id });
        setState(res.data);
        setSelectedState(null)
        setSelectedCity(null)
        setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
    }

    const changeState = async(e) =>{
        setSelectedState(e.target.value)
        const res1 = await apiServices.getCity({ state_id: e.target.value.id });
        setCity(res1.data)
        setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
    }

    const changeCity = (e) =>{
        setSelectedCity(e.target.value)
        setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
    }

    const changeData = (e) =>{

        if(e.target.name == 'accountmanager'){
            setSelectedOwner(e.value)
            setUserData({...userData,addedBy:{...userData.addedBy,[e.target.name]:e.value.userid}})
        }

        if(e.target.name == 'zone'){
            setSelectedZone(e.value)
            setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.value.code}})
        }

        if(e.target.name == 'locality' || e.target.name == 'cluster'){
            setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
        }

        if(e.target.name == 'uid'){
            setUserData({...userData,userdata:{...userData.userdata,[e.target.name]:e.target.value}})
        }

        if(e.target.name == 'name' || e.target.name == 'lastName' || e.target.name == 'email'){
            setUserData({...userData,[e.target.name]:e.target.value})
        }

        if(e.target.name == 'cno'){
            if(e.target.value.length == 10){
                const contact = '+91' + e.target.value;
                callVerification(contact)
                setUserData({...userData,[e.target.name]:contact})
            }
            // else if(e.target.value.length > 10){
            //     callVerification(e.target.value)
            //     setUserData({...userData,[e.target.name]:e.target.value})
            // }
            else{
                setCnoVerify(false)
            }

        }

        if(e.target.name == 'caddr' || e.target.name == 'paddr'){
            setUserData({...userData,addr:{...userData.addr,[e.target.name]:e.target.value}})
        }

        if(e.target.name == 'market' || e.target.name == 'sale' || e.target.name == 'service'){
            setPermissions({...permissions,[e.target.name]:e.target.checked})
        }
    }

    const callVerification = async(data) =>{
        if(contactNum != data){
            const res = await apiServices.verifyUser({cno: data});
            res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
        }else{
            setCnoVerify(false)
        }
    }
    
    const ontaprimanSubmit = async(e) =>{
        e.preventDefault();
        const data = {...userData,permission:permissions}
        const response = await apiServices.updateUser(data)
        if(response.data.success){
            toast.current.show({ severity: 'success', summary: 'Successful', detail:'User Updated Successfully', life: 3000 });
        }else{
            toast.current.show({ severity: 'error', summary: 'Failed', detail:'Failed to Update', life: 3000 });
        }
        props.getUser()
        props.setLoading(true)
        props.setTaprimanModal(false)
    }

    const taprimanDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{props.setTaprimanModal(false)}} />
            <Button label="Submit" type="submit" form='tapri-form' icon="pi pi-check" className="p-button-success" disabled={cnoVerify} />
        </>
    )

    return (
        <>
            <Toast ref={toast} position='center' />
            <Dialog visible={props.taprimanModal} style={{ width: "850px" }} header={`Update ${props.selectedUserType == 'superman' ? 'Tapriman' : '' } ${props.selectedUserType == 'accountmanager' ? 'Account Manager' : '' } `} modal footer={taprimanDialogFooter} onHide={()=>{props.setTaprimanModal(false)}} className="p-fluid"  maximizable={true} blockScroll={true} >
                <form onSubmit={ontaprimanSubmit} id="tapri-form" className="p-fluid">
                    <h5>Personal Information</h5>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                        <div className="p-fluid p-formgrid p-grid">

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="owner">Account Owner</label>
                                <Dropdown name='accountmanager' filter options={accountManagers} value={selectedOwner} onChange={changeData} optionLabel="name" placeholder="Account Owner" required ></Dropdown>
                            </div>                            
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="uniq-id">Unique Id</label>
                                <InputText id="uniq-id" name="uid" type="text" placeholder="Unique Id" defaultValue={userData?.userdata?.uid} onChange={changeData} required/>
                            </div>

                            {/* <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="prefix">Prefix</label>
                                <Dropdown name='prefix' filter options={accountManagers} value={selectedOwner} onChange={changeData} optionLabel="name" placeholder="Prefix" required ></Dropdown>
                            </div> */}

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="firtName">First Name</label>
                                <InputText id="firtName" name="name" type="text" placeholder="First Name" defaultValue={userData?.name} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText id="lastName" name="lastName" type="text" placeholder="Last Name" defaultValue={userData?.lastName} onChange={changeData} required/>
                            </div>

                            <div className="p-field p-col-12">
                                <label htmlFor="c-address">Current Address</label>
                                <InputTextarea id="c-address" name="caddr" rows="2" placeholder="Current Address" defaultValue={userData?.addr?.caddr} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="p-address">Permanent Address</label>
                                <InputTextarea id="p-address" name="paddr" rows="2" placeholder="Permanent Address" defaultValue={userData?.addr?.paddr} onChange={changeData} required/>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="contact">Contact Number</label>
                                <InputText id="contact" name="cno" type="text" minLength={10} maxLength={10} placeholder="Contact Number" defaultValue={userData?.cno} onChange={changeData} required/>  
                                { cnoVerify && <small className="p-error block">Number Already Exists.</small> }             
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="email-id">Email Id</label>
                                <InputText id="email-id" name="email" type="text" placeholder="Email ID" defaultValue={userData?.email} onChange={changeData} required/>
                            </div>
                            </div>
                        </div>
                        </div>
                        <h5>AREA INFORMATION</h5>
                        <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="zone">Zone</label>
                                <Dropdown name="zone" filter value={selectedZone} options={zoneItems} onChange={changeData} optionLabel="name" placeholder="Select Zone" required ></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="locality">Locality</label>
                                <InputText id="locality" name="locality" type="text" placeholder="Locality" defaultValue={userData?.userdata?.locality} onChange={changeData} required/>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="country">Country</label>
                                <Dropdown name="country" filter optionLabel="name" placeholder="Select Country" value={selectedCountry} options={country} onChange={changeCountry} required ></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dstate">State</label>
                                <Dropdown id="dstate" name="state"  optionLabel="name" filter placeholder="Select State" value={selectedState} options={state} onChange={changeState} required></Dropdown>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="dcity">City</label>
                                <Dropdown id="dcity" name="city" optionLabel="name" filter placeholder="Select City" value={selectedCity} options={city} onChange={changeCity} required></Dropdown>
                            </div>

                            {
                                props.selectedUserType == 'accountmanager' &&
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cluster">Cluster</label>
                                    <InputText id="cluster" name="cluster" type="text" placeholder="Cluster" defaultValue={userData?.userdata?.cluster} onChange={changeData} />
                                </div>
                            }

                        </div>
                        </div>
                        </div>

                        {
                            props.selectedUserType == 'superman' &&

                            <>
                                <h5>PERMISSIONS</h5>
                                <div className="p-grid">
                                <div className="p-col-12 p-md-12">
                                    <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-4">
                                        <Checkbox className="p-mb-1" inputId="cb1" name="market" checked={permissions?.market} onChange={changeData}></Checkbox>
                                        <label htmlFor="cb1" className="p-checkbox-label p-ml-3">market And Research</label>
                                    </div>
                                    <div className="p-field p-col-12 p-md-4">
                                        <Checkbox className="p-mb-1" inputId="cb2" name="sale" checked={permissions?.sale} onChange={changeData}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label p-ml-3">Sales</label>
                                    </div>
                                    <div className="p-field p-col-12 p-md-4">
                                        <Checkbox className="p-mb-1" inputId="cb3" name="service" checked={permissions?.service} onChange={changeData}></Checkbox>
                                        <label htmlFor="cb3" className="p-checkbox-label p-ml-3">Service</label>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </>
                        }
                        
                </form>
            </Dialog>
        </>
    )
}