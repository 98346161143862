export const position = [
    { name: "All",code: "all"},
    { name: "Account", code: "customer"},
    { name: "Owner", code: "owner"},
    { name: "Partner", code: "distributor"},
    { name: "Formulation", code: "formulation"},
    { name: "Technoman", code: "superman" },
    { name: "Sales Customer", code: "Sales Customer"},
    // { name: "Sales Manager", code: "Sales Manager" },
    { name: "Market Manager", code: "marketmanager" },
    { name: "Key Account Manager", code: "accountmanager" },
    // { name: "SCO", code: "sco"},
    // { name: "Dealer", code: "dealer" },
    // { name: "Tech",code: "tech" },
    { name: "Operation", code: "operation"},
    // { name: "Production", code: "production"},
    { name: "Quality Control Technician", code: "techqc" },
    { name: "Warehouse", code: "wareHouse" },
    { name: "Finance", code: "finance" },
    { name: "Inventory", code: "inventory" },
    { name: "Invenstor", code: "invenstor" },
    { name : "Tech Operation", code: "techops"}
    
];

export const positionForMarketAcc = [
    { name: "All",code: "all"},
    { name: "Account", code: "customer"},
    { name: "Technoman", code: "superman" },
    { name: "Key Account Manager", code: "accountmanager" }
];

export const positionForMarketAccM = [
    { name: "All",code: "all"},
    { name: "Account", code: "customer"}
    // { name: "Technoman", code: "superman" }
]

export const positionForDistributor = [
    { name: "All",code: "all"},
    // { name: "Account", code: "customer"}
    // { name: "Technoman", code: "superman" }
]

export const positionForCustomer = [
    { name: "Account", code: "customer"}
]

export const useTypesValues = [
    { name: "Partner", code: "distributor" },
    { name: "Cherise Team", code: "cherise-team" },
    { name: "Owner", code: "viewer"}
];

export const useTypesValuesForOperation = [
    { name: "Partner", code: "distributor" },
    { name: "Cherise Team", code: "cherise-team" }
];

export const useTypeValuesForMarketing = [{ name: "Cherise Team", code: "cherise-team" }];

export const departmentItems = [
    { name: "Developement", code: "developement" },
    { name: "Marketing", code: "marketing" },
    { name: "Tech Team", code: "techteam" },
    { name: "Finance", code: "finance" }
    
    // { name: "Finance", code: "finance" },
    // { name: "Design", code: "design" },
];

export const zoneDropdownItems = [
    { name: "East", code: "east" },
    { name: "West", code: "west" },
    { name: "North", code: "north" },
    { name: "South", code: "south" }
];

export const developementDropdownItems = [
    { name: "Formulation", code: "formulation" },
    { name: "Production", code: "production" },
    // { name: "Finance", code: "finance" },
    // { name: "Technical", code: "technical" },
    // { name: "Quality Control Technician ", code: "techqc" }
];

export const techTeamDropdownItems = [
    { name: "Quality Control Technician ", code: "techqc" },
    { name: "Operation", code: "operation" },
    { name: "Inventory", code: "inventory" },
    { name : "Invenstor", code: "invenstor"},
    { name : "Tech Operation", code: "techops"}
];

export const marketingDropdownItems = [
    { name: "Marketing Manager", code: "marketmanager" },
    { name: "Key Account Manager", code: "accountmanager" },
    { name: "Technoman", code: "tapriman" }
];

export const marketingDropdownItemsForAcc = [ { name: "Marketing Manager", code: "marketmanager" },{ name: "Technoman", code: "tapriman" }]

export const marketingDropdownItemsForAccM = [ { name: "Technoman", code: "tapriman" } ]

export const ownershipDropdownItems = [
    { name: "Self", code: "self" },
    { name: "Partner", code: "distributor" }
];

export const ownerRoles = [
    { name: "Owner", code: "owner" },
    { name: "Viewer", code: "viewer" }
];

