import React, {useState,useEffect} from 'react';
import { SubAnalytics } from '../Analytics/SubAnalytics';
import io from "socket.io-client";
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices =  new ApiServices();

const ENDPOINT = process.env.REACT_APP_IOT;

export const DemoMISStats = (props) =>{

    const useStatInput1 = {id:[""]}
    const [demomachineCount, setDemomachineCount] = useState('-');
    const [demoCupCount, setDemoCupCount] = useState('-');

    const [timeWiseData, setTimeWiseData] = useState();
    const [hourWiseData, setHourWiseData] = useState();

    const [nineToTwoData, setNineToTwoData] = useState();
    const [twoToSevenData, setTwoToSevenData] = useState();
    const [sevenToTwelveData, setSevenToTwelveData] = useState();
    const [twelveToNineData, setTwelveToNineData] = useState();
    
    const [weekdaysData, setWeekdaysData] = useState();
    const [weekendData, setWeekendData] = useState();
    const [morningData, setMorningData] = useState();
    const [eveningData, setEveningData] = useState();
    
    const getDemoData1 = async()=>{

        const response2 = await apiServices.findMachines();
        let result = response2?.data?.data.filter((m)=>{return m.siteName != ''}).map(a => a.id);     
        const response1 = await apiServices.getMaterialType();
        var response = await apiServices.getUserStats({...useStatInput1,id:result});

        let totalConsumption = response?.data?.data?.usagesStats[0]?.totalCup;

        console.log(totalConsumption)

        const date1 = new Date('3/27/2022');
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        const machinesCount = 512+diffDays;

        let NoofCups = 62;
        let NoOfMachines = machinesCount;
        let DaysPerMonth = 25;
        let NoOfMonths = 18;


        const inorganicIni = (NoofCups * NoOfMachines * DaysPerMonth * NoOfMonths) + totalConsumption;

        setDemomachineCount(machinesCount)
        setDemoCupCount(inorganicIni)

        
        setNineToTwoData(Math.ceil(inorganicIni * 40 / 100))
        setTwoToSevenData(Math.ceil(inorganicIni * 30 / 100))
        setSevenToTwelveData(Math.ceil(inorganicIni * 20 / 100))
        setTwelveToNineData(Math.ceil(inorganicIni * 10 / 100))

        setWeekdaysData(Math.ceil(inorganicIni * 80 / 100))
        setWeekendData(Math.ceil(inorganicIni * 20 / 100))
        setMorningData(Math.ceil(inorganicIni * 70 / 100))
        setEveningData(Math.ceil(inorganicIni * 30 / 100))

        console.log(Math.ceil(inorganicIni * 80 / 100))
        const dataOfSalesWise = [Math.ceil(inorganicIni * 80 / 100), Math.ceil(inorganicIni * 20 / 100), Math.ceil(inorganicIni * 70 / 100), Math.ceil(inorganicIni * 30 / 100)]
        setTimeWiseData(dataOfSalesWise)
      
        const dataOfSalesWise1 = [Math.ceil(inorganicIni * 80 / 100), Math.ceil(inorganicIni * 20 / 100), Math.ceil(inorganicIni * 70 / 100), Math.ceil(inorganicIni * 30 / 100)]
        /////////DEMO CALC/////////////////////////////
        
        const socket = io(ENDPOINT);
        // console.log(socket)
        const Livearr = [];

        const nineToTwoArr = [];
        const twoToSevenArr = [];
        const sevenToTwelveArr = [];
        const TwelveToNineArr = [];

        const weekDaysArr = [];
        const weekendArr = [];
        const morningArr = [];
        const eveningArr = [];




        // console.log(dataLength)

        socket.on("consumption", data1 => {
            console.log(data1.data.data.id)
                Livearr.push(data1)
               
                console.log(Livearr[0]?.data?.data?.id)

                // const date1 = new Date('3/27/2022');
                // const date2 = new Date();
                // const diffTime = Math.abs(date2 - date1);
                // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        
                // const machinesCount = 512+diffDays;
                // setDemomachineCount(machinesCount)

                console.log(totalConsumption + Livearr.length)
                let organicCups = totalConsumption + Livearr.length ;
                let NoofCups = 62;
                let NoOfMachines = machinesCount;
                let DaysPerMonth = 25;
                let NoOfMonths = 18;
        
                const inorganic = (NoofCups * NoOfMachines * DaysPerMonth * NoOfMonths) + organicCups;

                setDemoCupCount(inorganic)
                

                const date = new Date;
                const hour = date.getHours();
                console.log(hour)


                const now = new Date();
                const day = now.getDay();
                console.log(day)

                
                if(day >= 1 && day <= 5){
                    weekDaysArr.push(data1)
                    const weekdays = dataOfSalesWise[0] + weekDaysArr.length;
                    dataOfSalesWise1[0] = weekdays;
                }

                if(day == 6 || day == 7){
                    weekendArr.push(data1)
                    var weekend = dataOfSalesWise[1] + weekendArr.length;
                    dataOfSalesWise1[1] = weekend;
                }

                if(hour > 6 && hour < 12){
                    morningArr.push(data1)
                    var morning = dataOfSalesWise[2] + morningArr.length;
                    dataOfSalesWise1[2] = morning;
                }

                if(hour > 15 && hour < 19){
                    eveningArr.push(data1)
                    var evening = dataOfSalesWise[3] + eveningArr.length;
                    dataOfSalesWise1[3] = evening;
                }

                setTimeWiseData(dataOfSalesWise1)

                // console.log(dataOfSalesWise)
                // console.log(dataOfSalesWise1)

                const HourWiseArr = [Math.ceil(inorganic * 40 / 100),Math.ceil(inorganic * 30 / 100),Math.ceil(inorganic * 20 / 100),Math.ceil(inorganic * 10 / 100)];
                const HourWiseArr1 = [Math.ceil(inorganic * 40 / 100),Math.ceil(inorganic * 30 / 100),Math.ceil(inorganic * 20 / 100),Math.ceil(inorganic * 10 / 100)];

                if(hour > 9 && hour < 14){
                    nineToTwoArr.push(data1)

                    console.log(nineToTwoArr.length)
                    var nineToTwo = HourWiseArr[0] + nineToTwoArr.length;
                    // console.log(nineToTwo)
                    setNineToTwoData(nineToTwo)
                    HourWiseArr1[0] = nineToTwo;

                }

                if(hour > 14 && hour < 19){
                    twoToSevenArr.push(data1)
                    var twoToSeven = HourWiseArr[1] + twoToSevenArr.length;
                    // console.log(twoToSeven)
                    setTwoToSevenData(twoToSeven)
                    HourWiseArr1[1] = twoToSeven;
                }

                if(hour > 19 && hour < 23){
                    sevenToTwelveArr.push(data1)
                    var sevenToTwelve = HourWiseArr[2] + sevenToTwelveArr.length;
                    setSevenToTwelveData(sevenToTwelve)
                    // console.log(sevenToTwelve)
                    HourWiseArr1[2] = sevenToTwelve;

                }

                if(hour > 0 && hour < 9){
                    TwelveToNineArr.push(data1)
                    var twelveToNine = HourWiseArr[3] + TwelveToNineArr.length;
                    // console.log(twelveToNine)
                    setTwelveToNineData(twelveToNine)
                    HourWiseArr1[3] = twelveToNine;

                }
                console.log(HourWiseArr1)
                setHourWiseData(HourWiseArr1)
              
        });
    }


    useEffect(() => {
        getDemoData1()
    }, []);


    return (
        <>
            <SubAnalytics header="No. Of Distributors"  value={props?.ioStats?.partnerCount ? props?.ioStats?.partnerCount : '-'} icon="pi pi-user"/>
            <SubAnalytics header="No of CVES Accounts"  value={props?.ioStats?.accountCount ? props?.ioStats?.accountCount : '-'} icon="pi pi-th-large"/>
            <SubAnalytics header="No of CVES's"  value={props.onboardMachines?.length ? props.onboardMachines?.length : '-'} icon="pi pi-sitemap"/>
            {   
                !props.iOLoading &&
                <SubAnalytics header="Number Of Cups Sold (till date)"  value={props.totalCupCount ? props.totalCupCount : '-'} icon="pi pi-slack"/>
            }
            <SubAnalytics header="Average Per Cup Sale Cost"  value="INR 6.01 + GST" icon="pi pi-wallet"/>
            <SubAnalytics header="All Time Favourite Flavor"  value="MADRAS COFFEE" icon="pi pi-star-o"/>
        </>
    )
}

