import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Image } from 'primereact/image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faThermometerThreeQuarters } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
// Common
import { copyData, failureToast, successToast } from "../Common/CommonFunctions";
// Compponents
import { CustomerDialog } from "./CustomerDialog";
import { DistributorDialog } from "./DistributorDialog";
import { FormulationDialog } from "./FormulationDialog";
import { TapriManDialog } from "./TapriManDialog";
import { CommonForDetails } from "../CRM/Leads";
import { UsersDataTableSkeleton } from "../Common/CommonComponents";
// Context
import { permissionContext } from "../../AppWrapper";
// Common
import { position, positionForMarketAcc, positionForDistributor, positionForCustomer, positionForMarketAccM, useTypesValues, useTypesValuesForOperation, useTypeValuesForMarketing, departmentItems, zoneDropdownItems, developementDropdownItems, techTeamDropdownItems, marketingDropdownItems, marketingDropdownItemsForAcc, marketingDropdownItemsForAccM, ownershipDropdownItems, ownerRoles } from "./Constants";
import { srBodyTemplate, CommonBodyForTable } from "../Common/CommonComponents";
import { exportReport, showInformation } from "../Common/CommonFunctions";
import { commonFormDialogFooter } from "../Common/CommonComponents";
// Services
import ApiServices from "../../service/api/ApiServices";
import { TableWrapper } from "../../utilComponents/DataTableWrapper";
const apiServices = new ApiServices();

// UserData
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ============================================================================================================
// ===================== Users Page=========================================================================
// ========================= Swapnil k========================================================================
// ============================================================================================================

export const Users = () => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true);
    const [globalFilter1, setGlobalFilter1] = useState("");
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState(null);
    const [usersForTable, setUsersForTable] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [uniSelectedData, setUniSelectedData] = useState(null);
    const [defaultPosition, setDefaultPosition] = useState({ name: "Account", code: "customer" });
    const [addDistriDialog, setAddDistriDialog] = useState(false);
    const [addCheriseTeamDialog, setAddCheriseTeamDialog] = useState(false);
    const [addViewerDialog, setAddViewerDialog] = useState(false);
    const [distributor, setdistributor] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedDept, setSelectedDept] = useState(null);
    const [showOption, setShowOption] = useState(false);
    const [showUserTypeOption, setShowUserTypeOption] = useState(null);
    const [showPersonalOption, setShowPersonalOption] = useState(false);
    const [showAccManagerOption, setShowAccManagerOption] = useState(null);
    const [showTaparimanOption, setShowTaparimanOption] = useState(null);
    const [zones, setZones] = useState(null);
    const [cheriseUserType, setCheriseUserType] = useState(null);
    const [facilityDialog, setFacilityDialog] = useState(false);
    const [facilityUser, setFacilityUser] = useState([]);
    const [salesDialog, setSalesDialog] = useState(false);
    const [editFormulation, setEditFormulation] = useState(null);
    const [taprimanModal, setTaprimanModal] = useState(false);
    const [taprimanData, setTaprimanData] = useState(null);
    const [dealerModal, setDealerModal] = useState(false);
    const [editFacDialog, setEditFacDialog] = useState(false);
    const [dealerData, setDealerData] = useState(null);
    const [userRole, setUserRole] = useState(null);
    // Adding Dist
    const [cnoVerify, setCnoVerify] = useState(false)
    const [viewerTemplate, setViewerTemplate] = useState({})
    //Add Facility
    const [defaultCheck, setDefaultCheck] = useState(true)
    const [addFacilityTemp, setAddFacilityTemp] = useState({})
    const [facilityAddTemp, setFacilityAddTemp] = useState({})
    const [fcStates, setFcStates] = useState([]);
    const [fcCity, setFcCity] = useState([]);
    const [fcSelectedCountry, setFcSelectedCountry] = useState(null);
    const [fcSelectedState, setFcSelectedState] = useState(null);
    const [fcSelectedCity, setFcSelectedCity] = useState(null);
    // View
    const [viewFcDialog, setViewFcDialog] = useState(false);
    // Edit
    const [fcEditStatus, setFcEditStatus] = useState(false);
    const [editFacData, setEditFacData] = useState(false);
    // Delete
    const [deleteFcDialog, setDeleteFcDialog] = useState(false);
    // User
    const [selectedUser, setSelectedUser] = useState(true)
    // OwnerShip
    const [ownershipDialog, setOwnershipDialog] = useState(false)
    const [selectedOwnership, setSelectedOwnership] = useState({})
    const [ownershipClicks, setOwnershipClicks] = useState(false)
    const [distributors, setDistributors] = useState()
    const [selectedDist, setSelectedDist] = useState(null)
    const [distributorRole, setDistributorRole] = useState(null)
    // Sales Manager
    const [salesmanTemp, setSalesmanTemp] = useState({})
    const [addUser, setAddUser] = useState(null)
    // marketing mana
    const [marketingMan, setMarketingMan] = useState(null)
    const [selectedMarketingMan, setSelectedMarketingMan] = useState(null)
    // TapariMan
    const [tapriPermTemp, setTapriPermTemp] = useState({ market: false, sale: false, service: false })
    // CheriseTeam
    const [cheriseTeamTemp, setCheriseTeamTemp] = useState({})
    // Marketing Team
    const [cheriseMarketingTemp, setCheriseMarketingTemp] = useState({})
    // Delete User
    const [deleteUserDialog, setDeleteUserDialog] = useState(false)
    // Test
    const [defaultAdmins, setDefaultAdmins] = useState({ name: "", cno: "" });
    const [defaultAdminTab, setDefaultAdminTab] = useState([]);
    // Customer
    const [viewCustomerDialog, setViewCustomerDialog] = useState(false)
    const [editCustomerDialog, setEditCustomerDialog] = useState(false)
    const [customerData, setCustomerData] = useState(null)
    const [editNumberDialog, setEditNumberDialog] = useState(false)
    const [editNumber, setEditNumber] = useState('')
    // Formulation
    const [openFormulationDialog, setOpenFormulationDialog] = useState(false)
    // 
    const [selectedUserType, setSelecteduserType] = useState(null)
    const [deptDisable, setDeptDisable] = useState(false)
    // 
    const [facilityLoading, setFacilityLoading] = useState(true)
    // 
    const [selectedOwnerRole, setSelectedOwnerRole] = useState(null)
    const [sameAsAboveCheck, setSameAsAboveCheck] = useState(null)
    const [sameAsAccCheck, setSameAsAccCheck] = useState(null)
    // AddOptions
    const [addUserOptions, setAddUserOptions] = useState(null)
    const [facilityMachines, setFacilityMachines] = useState(null)

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getUser();
        getCountries();
        getAddOptions();
    }, [])


    const getUser = async () => {
        // setLoading(true)

        if (initialValue?.userType === 'marketmanager') {
            setLoading(true)
            const response1 = await apiServices.getUsersByCTVM();
            const response2 = await apiServices.getUsersByType({ [initialValue?.userType]: initialValue.userid })
            const arrData1 = response1?.data?.data.map((x) => { return { ...x, comapny: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } }).filter((x) => { return x.userType == 'customer' })
            const arrData2 = response2?.data?.data;
            getUserForTable([...arrData1, ...arrData2]);
            setUsers([...arrData1, ...arrData2]);
            setAllUsers([...arrData1, ...arrData2]);
            setDefaultPosition({ name: "All", code: "all" })
            setLoading(false)
        } else if (initialValue?.userType === 'accountmanager' || initialValue?.userType === 'distributor') {
            setLoading(true)
            const response2 = await apiServices.getUsersByCTVM();
            console.log(response2?.data);
            const arrData = response2?.data?.data.map((x) => { return { ...x, comapny: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } }).filter((x) => { return x.userType == 'customer' })
            getUserForTable(arrData);
            setUsers(arrData);
            setAllUsers(arrData);
            setDefaultPosition({ name: "All", code: "all" })
            setLoading(false)
        } else {
            setLoading(true)
            const response1 = await apiServices.getUsersByType({ userType: "customer" })
            setLoading(false)
            const data1 = response1?.data?.data?.map((x) => { return { ...x, comapny: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } })
            const finalArr = data1?.sort((a, b) => a.comapny?.trim() !== b.comapny?.trim() ? a.comapny?.trim() < b.comapny?.trim() ? -1 : 1 : 0)

            if(initialValue?.userType === 'customer'){
                const filtered = finalArr.filter((x)=>{ return x?.userid == initialValue?.userid})
                getUserForTable(filtered)
                setUsers(filtered)
            }else{
                getUserForTable(finalArr)
                setUsers(finalArr)
            }

            setLoading(false)

            const response = await apiServices.getUsers();
            const data = response?.data?.data?.map((x) => { return { ...x, comapny: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' } })
            const sortedAll = data?.sort((a, b) => a.comapny.trim() !== b.comapny.trim() ? a.comapny.trim() < b.comapny.trim() ? -1 : 1 : 0)
            setAllUsers(sortedAll);

            // if(defaultPosition?.code != 'all'){
            // const posData = data?.filter((pos) => pos.userType == defaultPosition.code);
            // setUsers(posData)
            // getUserForTable(posData);
            // }else{
            // setUsers(data);
            // getUserForTable(data);
            // }   
        }

        // setLoading(false)
    };


    const getUserForTable = (data) => {
        const arr = []
        data?.map((x, i) => {
            const res = {
                ...x,
                index: i + 1, address: x?.addr ? x?.addr?.addr : 'NA',
                fullName: x.name + '' + x.lastName,
                billingName: x.billing_details?.company
            }
            arr.push(res)
        })
        setUsersForTable(arr)
    }

    const getCountries = async () => {
        const res = await apiServices.getCountry();
        setCountries(res?.data)
    };

    const getAddOptions = () => {
        if (initialValue.userType !== 'marketmanager' && initialValue.userType !== 'accountmanager') {
            initialValue.userType === 'operation' ? setAddUserOptions(useTypesValuesForOperation) : setAddUserOptions(useTypesValues)
        } else {
            setAddUserOptions(useTypeValuesForMarketing)
        }
    }

    const checkPincode = async (data) => {
        const response = await apiServices.getPincodeDetails(data);

        if (response.data[0]?.PostOffice) {

            const country = response.data[0]?.PostOffice[0]?.Country;
            const state = response.data[0]?.PostOffice[0]?.State;
            const city = response.data[0]?.PostOffice[0]?.District;
            const block = response.data[0]?.PostOffice[0]?.Block;

            const condata = countries.filter((x) => { return x.name == country })
            setFcSelectedCountry(condata[0])

            const res = await apiServices.getState({ country_id: condata[0].id });
            setFcStates(res.data);

            const statedata = res.data.filter((x) => { return x.name == state })
            setFcSelectedState(statedata[0])

            const res1 = await apiServices.getCity({ state_id: statedata[0].id });
            setFcCity(res1.data);

            const citydata = res1.data.filter((x) => { return x.name == city || x.name == block })
            setFcSelectedCity(citydata[0])

            const finalData = { ...facilityAddTemp, address: { ...facilityAddTemp.address, country: condata[0], state: statedata[0], city: citydata[0], pincode: data } }
            console.log(finalData);
            setFacilityAddTemp(finalData)

        } else {
            setDefaultShipp()
        }
    }

    const onclickThis = (data) => {
        copyData(data)
        showInformation(toast)
    }

    const icon = (<i className="pi pi-eye"></i>)

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Profile</span>
                <Image style={{ borderRadius: '100%' }} src={!rowData.image?.data ? "assets/demo/images/user/default-user.png" : rowData.image?.data} template={icon} alt="profile" preview width="30" />
            </>
        )
    }

    const comapnyBodyTemplate = (rowData, props) => {
        return (
            <> <span className="p-column-title">{props?.header}</span> {rowData?.billing_details ? rowData?.billing_details[props.field] : 'CIPL'} </>
        )
    }

    const mbBodyTemplate = (rowData, props) => {
        const mb = rowData[props.field]?.length == 10 ? '+91' + rowData[props.field] : rowData[props.field];
        const copyNumber = mb?.replace("+91", "");

        return (
            <> {rowData[props.field] ? <><span className="p-column-title">{props?.header}</span><Button label={mb} className="p-button-secondary p-button-text p-p-0" iconPos="right" onClick={() => { onclickThis(copyNumber) }} /> </> : 'NA'} </>
        )
    }

    const nameBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {`${rowData?.prefix ? rowData?.prefix : ""} ${rowData?.name} ${rowData?.lastName ? rowData?.lastName : ""}`}
            </>
        )
    }

    const positionBodyTemplate = (rowData) => {

        let userTyped;

        switch (rowData.userType) {
            case 'superman':
                userTyped = 'Technoman'
                break;
            case 'customer':
                userTyped = 'Account'
                break;
            case 'distributor':
                userTyped = 'Partner'
                break;
            case 'techqc':
                userTyped = 'Quality Control Technician'
                break;
            case 'accountmanager':
                userTyped = 'Account Manager'
                break;
            case 'techops':
                userTyped = 'Tech Operation'
                break;
            default:
                userTyped = rowData.userType;
                break;
        }

        return (<> <span className="p-column-title">Position</span>{userTyped}</>)
    };


    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Address</span>
                {rowData?.addr?.addr || rowData?.addr?.caddr ? rowData?.addr?.addr || rowData?.addr?.caddr : 'NA'}
            </>
        );
    };

    const removeFac = (e) => {
        delete defaultAdminTab[e.target.id]
        const arr = defaultAdminTab.filter((x) => { return x != null })
        setDefaultAdminTab(arr)
    }

    const facActionTemplate = (rowData, props) => { return (<> <Button icon="pi pi-times" title="Delete" id={props.rowIndex} className="p-button-rounded p-button-danger p-button-text" onClick={removeFac} /></>) }
    const facilityAddressTemplate = (data) => { return (<> <span className="p-column-title">Facility Address</span>{data?.address?.address}</>) }

    const callFacility = async (userid) => {
        setFacilityLoading(true)
        const response = await apiServices.getFacility({ userid: userid });
        const IDdata = response.data.data.map((x, i) => { x.srid = i + 1; return x; });
        console.log(IDdata);
        setFacilityUser(IDdata);
        setFacilityLoading(false)
    }

    const viewFacility = async (e, rowData) => {
        console.log(rowData);
        setFacilityUser([])
        callFacility(rowData.userid)
        setFacilityDialog(true);
        setSelectedUser(rowData)
    };

    const getMachinesByFac = async (data) => {
        const payload = {
            facid: data?.facid,
            userType: 'customer',
            userid: data?.userid
        }
        const response = await apiServices.getMachineByFacid(payload);
        console.log(response);
        setFacilityMachines(response?.data?.data)
    }

    const openOwnership = (rowData) => {
        setDistributorRole(null)
        setSelectedDist(null)
        setEditFacData(rowData)
        setOwnershipDialog(true)
        const distData = allUsers?.filter((pos) => pos.userType == 'distributor');
        setDistributors(distData)

        if (rowData?.distributor_data?.userType === 'distributor') {
            setSelectedOwnership({ name: "Partner", code: "distributor" })
            const data = distData.filter((x) => { return x.userid === rowData?.distributor_data?.userid })
            setSelectedDist(data[0])
            for (var key in data[0].userRole) { if (data[0].userRole[key] === true) { setDistributorRole(key) } }
        } else {
            setSelectedOwnership({ name: "Self", code: "self" })
        }
    }

    const editDistributor = async (e, rowData) => {
        setAddDistriDialog(true);
        setdistributor(rowData);
        
        if (rowData.userRole.financial) {
            setUserRole({ name: "Financial Partner", code: "financial_partner" })
        } else if (rowData.userRole.partner) {
            setUserRole({ name: "Distributor Partner", code: "distributor_partner" })
        } else if (rowData.userRole.stratigical) {
            setUserRole({ name: "Stratergy Partner", code: "stratergy_partner" })
        }
    }

    const editCustomer = (e, rowData) => {
        console.log(rowData);
        setEditCustomerDialog(true)
        setCustomerData(rowData)
    }

    const viewCustomer = (e, rowData) => {
        console.log(rowData);
        setViewCustomerDialog(true)
        setCustomerData(rowData)
    }

    const techEdit = (e, rowData) => {
        setSelecteduserType('tech')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    };

    const openEditOwner = (rowData) => {
        console.log(rowData);
        setSelecteduserType('owner')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    }

    const openEditWareHouse = (rowData) => {
        console.log(rowData);
        setSelecteduserType('wareHouse')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    }

    const openEditTechQc = (rowData) => {
        console.log(rowData);
        setSelecteduserType('techqc')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    }

    const openEditFormulation = (rowData) => {
        setSelecteduserType('formulation')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    }

    const editProduction = async (e, rowData) => {
        setSelecteduserType('production')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
        console.log(rowData);
    };

    const editTapriman = async (e, rowData) => {
        console.log(rowData);
        setSelecteduserType('superman')
        setTaprimanModal(true);
        setTaprimanData(rowData);
    };

    const editAccountManager = async (e, rowData) => {
        setSelecteduserType('accountmanager')
        setTaprimanModal(true);
        setTaprimanData(rowData);
        console.log(rowData);
    };

    const editMarketManager = async (e, rowData) => {
        setSelecteduserType('marketmanager')
        setEditFormulation(rowData)
        setOpenFormulationDialog(true)
    };

    const editDealer = async (e, rowData) => {
        setDealerModal(true);
        setDealerData(rowData);
        alert('Pending , beacuse of error from main dashboard while updating')
    }

    const openDeleteUser = (rowData) => {
        console.log(rowData);
        setDeleteUserDialog(true)
        setUniSelectedData(rowData)
    }

    const openNumberDialog = (rowData) =>{
        console.log(rowData);
        setEditNumberDialog(true)
        setCustomerData(rowData)
        setEditNumber(rowData?.cno)
    }

    const deleteUserFn = async () => {
        setDeleteUserDialog(false)
        const response = await apiServices.deleteUser({ userid: uniSelectedData?.userid });
        response?.data?.success ? successToast(toast, 'User Deleted Successfully') : failureToast(toast, 'Failed to delete')
        setDeleteUserDialog(false)
        getUser()
        setLoading(true)
    }

    const exportCSV = () => { exportReport(dt) }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Actions</span>
                {
                    rowData.userType == "customer" &&
                    <>
                        {/* <Button title="Set Temperature" className="p-button-rounded p-button-danger p-p-1 p-mr-2 p-mb-2" ria-label="Temperature" >
                            <FontAwesomeIcon className="pi fontAwasome-icon-table" icon={faEye} />
                        </Button> */}
                        {/* {<Button title="View Customer" className="p-button-rounded p-button-warning p-p-1 p-mr-2 p-mb-2" onClick={(e) => viewCustomer(e, rowData)}> <FontAwesomeIcon className="pi fontAwasome-icon-table" icon={faEye} /> </Button>} */}

                        {<Button icon="pi pi-eye" title="View Customer" className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={(e) => viewCustomer(e, rowData)} />}
                        {rolePermissions?.UserCustomerEditBtn && <Button icon="pi pi-pencil" title="Edit Customer" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editCustomer(e, rowData)} />}
                        {rolePermissions?.UserCustomerFacBtn && <Button icon="pi pi-th-large" title="Facility" className="p-button-rounded p-button-info p-mr-2 p-mb-2" id={rowData._id} onClick={(e) => viewFacility(e, rowData)} />}
                        {/* { rolePermissions?.UserCustomerOwnerShipBtn && <Button icon="pi pi-user-edit" title="OwnerShip" className="p-button-rounded p-button-help p-mr-2 p-mb-2" onClick={() => openOwnership(rowData)}/> }   */}
                    </>
                }

                {
                    rowData.userType === "distributor" &&
                    <>
                        {rolePermissions?.UserDistEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editDistributor(e, rowData)} />}
                        {rolePermissions?.UserDistSalesBtn && <Button icon="pi pi-user-plus" title="Add Sales Manager" className="p-button-rounded p-button-warning p-mr-2 p-mb-2" onClick={(e) => { setSalesDialog(true); setAddUser('sales'); setUniSelectedData(rowData) }} />}
                    </>
                }

                {
                    rowData.userType === "Sales Customer" && 
                    <>
                        {rolePermissions?.UserCustomerFacBtn && <Button icon="pi pi-th-large" title="Facility" className="p-button-rounded p-button-info p-mr-2 p-mb-2" id={rowData._id} onClick={(e) => viewFacility(e, rowData)} />}
                    </>
                }

                {
                    rowData.userType === "owner" &&
                    <>
                        {rolePermissions?.userOwnerEditBtn && <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => openEditOwner(rowData)} />}
                    </>
                }

                {
                    rowData.userType === "wareHouse" &&
                    <>
                        {rolePermissions?.userWareHouseEditBtn && <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => openEditWareHouse(rowData)} />}
                        {/* { rolePermissions?.UserCustomerFacBtn && <Button icon="pi pi-th-large" title="Facility" className="p-button-rounded p-button-info p-mr-2 p-mb-2" id={rowData._id} onClick={(e) => viewFacility(e, rowData)}/> } */}
                    </>
                }

                {
                    rowData.userType === "techqc" &&
                    <>
                        {rolePermissions?.userTechqcEditBtn && <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => openEditTechQc(rowData)} />}
                    </>
                }

                {
                    rowData.userType === "formulation" &&
                    <>
                        {rolePermissions?.UserFormulationEditBtn && <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => openEditFormulation(rowData)} />}
                    </>
                }

                {
                    rowData.userType === "Sales Manager" &&
                    <>
                        {rolePermissions?.UserSalesEditBtn && <Button icon="pi pi-plus" title="Add Customer" id={rowData.userid} className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={(e) => { setSalesDialog(true); setAddUser('customer'); setUniSelectedData(rowData) }} />}
                    </>
                }

                {
                    rowData.userType === "tech" &&
                    <>
                        {rolePermissions?.UserTechEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => techEdit(e, rowData)} />}
                    </>
                }

                {
                    rowData.userType === "superman" &&
                    <>
                        {rolePermissions?.UserTechEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editTapriman(e, rowData)} />}
                        {rolePermissions?.UserCustomerFacBtn && <Button icon="pi pi-th-large" title="Facility" className="p-button-rounded p-button-info p-mr-2 p-mb-2" id={rowData._id} onClick={(e) => viewFacility(e, rowData)} />}
                    </>
                }

                {
                    rowData.userType === "marketmanager" &&
                    <>
                        {rolePermissions?.UserMarketEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editMarketManager(e, rowData)} />}
                    </>
                }

                {
                    rowData.userType === "accountmanager" &&
                    <>
                        {/* { rolePermissions?.UserAccEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editAccountManager(e, rowData)}/> } */}
                    </>
                }
                {
                    rowData.userType === "dealer" &&
                    <>
                        {rolePermissions?.UserDelarEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editDealer(e, rowData)} />}
                    </>
                }
                {
                    rowData.userType === "production" &&
                    <>
                        {rolePermissions?.UserProductionEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editProduction(e, rowData)} />}
                    </>
                }
                {
                    rowData.userType === "operation" &&
                    <>
                        {rolePermissions?.UserOperationEditBtn && <Button icon="pi pi-pencil" title="Edit" id={rowData.userid} className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={(e) => editProduction(e, rowData)} />}
                    </>
                }

                {
                    (rolePermissions?.UserDeleteBtn && !(rowData?.cno === initialValue?.cno)) &&
                    <>
                        {/* <Button icon="pi pi-trash" title="Delete User" className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => openDeleteUser(rowData)} /> */}
                    </>
                }

                {
                    rolePermissions?.UserEditCno &&
                    <Button icon="pi pi-user-edit" title="Update Mobile Number" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => openNumberDialog(rowData)} />
                }

            </>
        )
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right">
                <h6 className="p-mt-3 p-mr-5">Users</h6>
            </span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                </div>
            </span>
        </div>
    )

    const onPositionChange = (e) => {
        setDefaultPosition(e.value)

        if (e.value?.code === 'all') {
            if (allUsers) {
                getUserForTable(allUsers);
                setUsers(allUsers)
            } else {
                getAllUsersFromFilter()
            }
        } else {
            if (allUsers) {
                const posData = allUsers?.filter((pos) => pos.userType == e.value?.code);
                const finalArr = posData?.sort((a, b) => a.comapny?.trim() !== b.comapny.trim() ? a.comapny?.trim() < b.comapny.trim() ? -1 : 1 : 0)

                e.value.code != 'all' ? setUsers(finalArr) : setUsers(allUsers);
                getUserForTable(posData);
            } else {
                console.log('Hello');
                getUsersByType(e.value?.code)
            }
        }
    }

    const getAllUsersFromFilter = async () => {
        setLoading(true)
        const response = await apiServices.getUsers();

        const data = response?.data?.data?.map((x) => {
            return { 
                ...x, 
                comapny: x?.billing_details?.company ? x?.billing_details?.company : 'CIPL' 
            } 
        })

        if(data){
            setAllUsers(data);
            getUserForTable(allUsers);
            allUsers ? setUsers(allUsers) : setUsers(data);
            setLoading(false)
        }
        // setLoading(false)
        // setUsers(data)
        // setUsers(allUsers)
    }

    const getUsersByType = async (data) => {
        setLoading(true)
        const response = await apiServices.getUsersByType({ userType: data })
        const finalArr = response?.data?.data?.sort((a, b) => a.comapny?.trim() !== b.comapny?.trim() ? a.comapny?.trim() < b.comapny?.trim() ? -1 : 1 : 0)
        getUserForTable(finalArr);
        setUsers(finalArr)
        setLoading(false)
    }

    const selectUserToAdd = (e) => {

        if (e.value.code === "distributor") {
            setAddDistriDialog(true);
            setdistributor(null)
        } else if (e.value.code === "cherise-team") {

            if (initialValue?.userType === 'marketmanager') {
                setShowOption(true);
                setDeptDisable(true)
                setSelectedDept({ name: "Marketing", code: "marketing" })
                setShowUserTypeOption(marketingDropdownItemsForAcc);
            } else if (initialValue?.userType === 'accountmanager') {
                setShowOption(true);
                setDeptDisable(true)
                setSelectedDept({ name: "Marketing", code: "marketing" })
                setShowUserTypeOption(marketingDropdownItemsForAccM);
            }

            setCheriseUserType(null)
            setAddCheriseTeamDialog(true);
            setShowAccManagerOption(false);
            setShowTaparimanOption(false);
            setDefaultValues()

        } else if (e.value.code === "viewer") {
            setAddViewerDialog(true);
        }
    }

    const setDefaultValues = () => {
        setSelectedDept(null)
        setShowOption(false);
        setCheriseUserType(null)
        setShowPersonalOption(false)
        setShowAccManagerOption(false)
        setShowTaparimanOption(false)
    }

    const openAddFac = (e) => {
        setEditFacDialog(true);
        setFcEditStatus(false);
        setDefault()
    }

    const setDefault = () => {
        setDefaultAdminTab([])
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
        setFcSelectedCountry(null)
        setFcSelectedState(null)
        setFcSelectedCity(null)
    }

    const hideCteamDialog = () => {
        setAddCheriseTeamDialog(false)
    }

    const leftToolbarTemplate = () => {
        let dropdownOptions;
        if (initialValue?.userType === 'marketmanager') {
            dropdownOptions = positionForMarketAcc;
        } else if (initialValue?.userType === 'accountmanager') {
            dropdownOptions = positionForMarketAccM;
        }else if(initialValue?.userType === 'customer'){
            dropdownOptions = positionForCustomer;
        }else if(initialValue?.userType === 'distributor'){
            dropdownOptions = positionForDistributor;
        }else {
            dropdownOptions = position;
        }

        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="USER TYPE" />
                    <Dropdown className="multiselect-custom" options={dropdownOptions} optionLabel="name" onChange={onPositionChange} filter value={defaultPosition} placeholder="Select User Position" />
                </div>
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="ADD NEW" />
                    <Dropdown onChange={selectUserToAdd} options={addUserOptions} optionLabel="name" placeholder="User" filter className="multiselect-custom" />
                </div>
            </>
        )
    }

    const addFacRightToolbarTemplate = () => (
        <Button label="Add Facility" icon="pi pi-user-plus" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={(e) => { openAddFac() }} />
    )

    const CTeamDialogFooter = commonFormDialogFooter('Cancel', hideCteamDialog, 'Submit', 'team-form')

    // const CTeamDialogFooter = (
    //     <>
    //         <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={()=>{setAddCheriseTeamDialog(false)}}/>
    //         <Button label="Submit" type="submit" form="team-form" icon="pi pi-check" className="p-button-success" />
    //     </>
    // );

    const viewerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { deleteOwnerDialog() }} />
            <Button label="Submit" type="submit" form='viewer-form' icon="pi pi-check" className="p-button-success" disabled={cnoVerify} />
        </>
    );

    const facilityFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { setEditFacDialog(false) }} />
            <Button label="Submit" type="submit" form="facility-form" icon="pi pi-check" className="p-button-success" />
        </>
    );

    const facilityDFooter = (
        <> <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { setFacilityDialog(false) }} /></>
    )

    const salesFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => { setSalesDialog(false) }} />
            <Button label="Submit" type="submit" form="salesman-form" icon="pi pi-check" className="p-button-success" disabled={cnoVerify} />
        </>
    );

    const viewCustomerFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setViewCustomerDialog(false) }} /></>)
    const viewFcFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setViewFcDialog(false) }} /></>)

    // const success = (msg) => {
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: `${msg}`, life: 3000 });
    // }

    // const failure = (msg) => {
    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: `${msg}`, life: 3000 });
    // }

    const onFcCountryDrop = async (e) => {
        setFcSelectedCountry(e.value);
        const res = await apiServices.getState({ country_id: e.value.id });
        setFcStates(res.data);
        const data = { ...facilityAddTemp, address: { ...facilityAddTemp.address, [e.target.name]: e.value } }
        console.log(data);
        setFacilityAddTemp(data)
    };

    const onFcStateDrop = async (e) => {
        setFcSelectedState(e.value);
        console.log(e.value);
        const res = await apiServices.getCity({ state_id: e.value.id });
        setFcCity(res.data);
        const data = { ...facilityAddTemp, address: { ...facilityAddTemp.address, [e.target.name]: e.value } }
        setFacilityAddTemp(data)
    };

    const onFcCityDrop = (e) => {
        setFcSelectedCity(e.value)
        const data = { ...facilityAddTemp, address: { ...facilityAddTemp.address, [e.target.name]: e.value } }
        console.log(data);
        setFacilityAddTemp(data)
    }

    const onFcAddChange = (e) => {
        console.log(facilityAddTemp);
        const data = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, [e.target.name]: e.target.value } }
        setFacilityAddTemp(data)
    }

    const onFcCCS = async (e) => {
        if (e.target.name == 'country') {
            setSelectedCountry(e.value)
            const res = await apiServices.getState({ country_id: e.value.id });
            setStates(res.data);
        } else if (e.target.name == 'state') {
            const res = await apiServices.getCity({ state_id: e.value.id });
            setCity(res.data);
            setSelectedState(e.value)
        } else if (e.target.name == 'city') {
            setSelectedCity(e.value)
        }
        const data = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, [e.target.name]: e.value } }
        setFacilityAddTemp(data)
    }

    const createUserCall = async (data) => {
        const res = await apiServices.createUser(data);
        res.data.success ? successToast(toast, "User Added Successfully") : failureToast(toast, "Failed to Add")
    }

    const onTeamSubmit = async (e) => {
        e.preventDefault();

        console.log("============CHERISE TEAM============");

        if (cheriseUserType.code == 'formulation' || cheriseUserType.code == 'production' || cheriseUserType.code == 'technical' || cheriseUserType.code == 'techqc' || cheriseUserType.code == 'operation' || cheriseUserType.code == 'finance' || cheriseUserType.code == 'inventory' || cheriseUserType.code == 'invenstor' || cheriseUserType.code == 'techops' ) {
            let data = { ...cheriseTeamTemp, status: true, userType: cheriseUserType.code }
            createUserCall(data)
        } else if (cheriseUserType.code == 'marketmanager') {
            let data = { ...cheriseTeamTemp, status: true, userType: cheriseUserType.code, addedBy: { owner: initialValue.userid } }
            createUserCall(data)
        } else if (cheriseUserType.code == 'accountmanager') {
            let data = { ...cheriseMarketingTemp, userType: "accountmanager", addedBy: { marketmanager: selectedMarketingMan.userid, owner: selectedMarketingMan.addedBy.owner } }
            createUserCall(data)
        } else if (cheriseUserType.code == 'tapriman') {
            let data = { ...cheriseMarketingTemp, userType: "superman", addedBy: { accountmanager: selectedMarketingMan.userid, marketmanager: selectedMarketingMan.addedBy.marketmanager, owner: selectedMarketingMan.addedBy.owner }, permission: tapriPermTemp }
            console.log(data);
            createUserCall(data)
        } else {
            console.log("Invalid User Type");
        }

        setAddCheriseTeamDialog(false)
        getUser()
    }

    const onViewer = async (e) => {
        let viewerTemp;
        if (e.target.name == 'cno') {

            const cno = '+91' + e.target.value
            setCnoVerify(false)
            if (e.target.value.length == 10) {
                const res = await apiServices.verifyUser({ cno: cno });
                if (res?.data?.success)
                    setCnoVerify(true)
            }
            viewerTemp = { ...viewerTemplate, [e.target.name]: cno }

        } else if (e.target.name == 'userRole') {
            setSelectedOwnerRole(e.value)
            viewerTemp = { ...viewerTemplate, [e.target.name]: { auth: e.value.code }, userType: e.value.code }
        } else {
            viewerTemp = { ...viewerTemplate, [e.target.name]: e.target.value }
        }
        setViewerTemplate(viewerTemp)
    }

    const onViewerSubmit = async (e) => {
        e.preventDefault();
        const data = { ...viewerTemplate, addedBy: { owner: initialValue?.userid, name: initialValue?.name } };
        const res = await apiServices.createUser(data);
        res.data.success ? successToast(toast,'User Added Successfully') : failureToast(toast,'Failed to Add');
        setAddViewerDialog(false)
        getUser()
        setViewerTemplate({})
    }

    const onFacilitySubmit = async (e) => {
        e.preventDefault();

        if (fcEditStatus) {
            const data = { ...facilityAddTemp, admin_default: defaultCheck, admins: defaultAdminTab, billing_default: false }
            const data2 = { billing_details: { ...editFacData.billing_details, ...data.billing_details } }
            const data3 = { address: { ...editFacData.address, ...data.address, location: { ...data?.address?.location ? data.address.location : editFacData?.address?.location } } }
            const fdata = { ...data2, ...data3, admin_default: defaultCheck, admins: defaultCheck ? [] : defaultAdminTab, billing_default: false, facname: data.facname }
            const updateData = { ...editFacData, ...fdata }
            console.log(updateData);
            const response = await apiServices.updateFacility(updateData);
            response?.data?.success ? successToast(toast,'Facility Updated Successfully') : failureToast(toast,'Failed to Update');
            callFacility(selectedUser.userid)

        } else {

            const data = { ...facilityAddTemp, admin_default: defaultCheck, admins: defaultAdminTab, billing_default: false }
            // const data1 = {...addFacilityTemp,d_id:selectedUser.d_id,userid:selectedUser.userid}
            const data2 = { ...addFacilityTemp, d_id: selectedUser.d_id, userid: selectedUser.userid, facility: [data] }
            const response = await apiServices.addFacility(data2);
            response?.data?.success ? successToast(toast,'Facility Added Successfully') : failureToast(toast,'Failed to Add');
            callFacility(selectedUser.userid)
        }
        setEditFacDialog(false)
    }

    const onFacUpdateName = (e) => {
        const data = { ...facilityAddTemp, [e.target.name]: e.target.value }
        setFacilityAddTemp(data)
    }

    const onFcLtLng = (e) => {
        const data = { ...facilityAddTemp, address: { ...facilityAddTemp?.address, location: { ...facilityAddTemp?.address?.location, [e.target.name]: e.target.value } } }
        // const data = {...facilityAddTemp,location:{...facilityAddTemp.location,[e.target.name] : e.target.value} }
        setFacilityAddTemp(data)
    }

    const setDefaultShipp = () => {
        setFcSelectedCountry(null);
        setFcStates(null);
        setFcSelectedState(null);
        setFcCity(null);
        setFcSelectedCity(null)
    }

    const onFacUpPincode = (e) => {
        e.target.value.length == 6 ? checkPincode(e.target.value) : setDefaultShipp();
    }

    const onFacUpAddr = (e) => {
        const data = { ...facilityAddTemp, address: { ...facilityAddTemp.address, [e.target.name]: e.target.value } }
        setFacilityAddTemp(data)
    }

    const changeDepartment = (e) => {

        setSelectedDept(e.value)
        setCheriseTeamTemp({})
        setShowPersonalOption(false);
        if (e.value.code == "developement") {
            setShowOption(true);
            setShowUserTypeOption(developementDropdownItems);
        } else if (e.value.code == "marketing") {
            setShowOption(true);
            setShowUserTypeOption(marketingDropdownItems);
        } else if (e.value.code == "finance" || e.value.code == "design") {
            setCheriseUserType(e.value)
            setShowOption(false);
            // setShowFinanceOption(true);
            setShowPersonalOption(true);
        } else if (e.value.code == "techteam") {
            setShowOption(true);
            setShowUserTypeOption(techTeamDropdownItems);
        } else {
            setShowOption(false);
        }
    }

    const setCheriseUserTypes = (e) => {

        // setCheriseTeamTemp({})
        setCheriseUserType(e.value);
        if (e.value.code == "formulation" || e.value.code == "production" || e.value.code == "technical" || e.value.code == "techqc" || e.value.code == "marketmanager" || e.value.code == "operation" || e.value.code == 'finanace' || e.value.code == 'inventory' || e.value.code == 'invenstor' || e.value.code == 'techops') {
            setShowPersonalOption(true);
            setShowAccManagerOption(false);
        } else if (e.value.code == "accountmanager") {
            setShowPersonalOption(false);
            setShowAccManagerOption(true);
            const marMan = allUsers?.filter((pos) => pos.userType == 'marketmanager');
            setMarketingMan(marMan)
        } else if (e.value.code == "tapriman") {
            const accMan = allUsers?.filter((pos) => pos.userType == 'accountmanager');
            // const accMan = allUsers?.filter((pos) => pos.userType == 'marketmanager');
            setMarketingMan(accMan)
            setShowPersonalOption(false);
            setShowAccManagerOption(true);
            setShowTaparimanOption(true);
        }
    };

    const callVerification = async (data) => {
        const res = await apiServices.verifyUser({ cno: data });
        res?.data?.success ? setCnoVerify(true) : setCnoVerify(false)
    }

    const deleteOwnerDialog = () => {
        console.log('Cancelled');
        setAddViewerDialog(false)
        setViewerTemplate({})
        setSelectedOwnerRole(null)
    }

    const onDefaultValue = (e) => {
        setDefaultCheck(e.target.checked)
    }

    const setAddresses = (e) => {

        if (e.target.name == 'sameasabove') {
            setSameAsAboveCheck(e.target.checked)

            if (e.target.checked) {

                const data = {
                    addr: facilityAddTemp?.address?.address,
                    country: facilityAddTemp?.address?.country,
                    state: facilityAddTemp?.address?.state,
                    city: facilityAddTemp?.address?.city,
                    pincode: facilityAddTemp?.address?.pincode
                }

                const data1 = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, ...data } }
                setFacilityAddTemp(data1)
                setCSCOfAbove()

            } else {

                const data = { cmpname: '', gstnumber: '', addr: '', locality: '', country: '', state: '', city: '', pincode: '' }
                const data1 = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, ...data } }
                setFacilityAddTemp(data1)
                setSelectedCountry(null)
                setSelectedState(null)
                setSelectedCity(null)
            }


        } else if (e.target.name == 'sameasacc') {

            setSameAsAccCheck(e.target.checked)
            console.log(selectedUser);

            if (e.target.checked) {

                const data = {
                    cmpname: selectedUser?.billing_details?.company,
                    gstnumber: selectedUser?.gstNumber,
                    addr: selectedUser?.addr.addr,
                    locality: selectedUser?.addr?.locality,
                    country: selectedUser?.addr?.country,
                    state: selectedUser?.addr?.state,
                    city: selectedUser?.addr?.city,
                    pincode: selectedUser?.addr?.pincode
                }

                const data1 = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, ...data } }
                setFacilityAddTemp(data1)
                setCSC()

            } else {

                const data = { cmpname: '', gstnumber: '', addr: '', locality: '', country: '', state: '', city: '', pincode: '' }
                const data1 = { ...facilityAddTemp, billing_details: { ...facilityAddTemp?.billing_details, ...data } }
                setFacilityAddTemp(data1)
                setSelectedCountry(null)
                setSelectedState(null)
                setSelectedCity(null)
            }
        }
    }

    const setCSCOfAbove = async () => {
        setSelectedCountry(facilityAddTemp?.address?.country)
        const res2 = await apiServices.getState({ country_id: facilityAddTemp?.address?.country?.id });
        setStates(res2.data)
        setSelectedState(facilityAddTemp?.address?.state)
        const res3 = await apiServices.getCity({ state_id: facilityAddTemp?.address?.state?.id });
        setCity(res3.data)
        setSelectedCity(facilityAddTemp?.address?.city)
    }

    const setCSC = async () => {
        setSelectedCountry(selectedUser?.addr?.country)
        const res2 = await apiServices.getState({ country_id: selectedUser?.addr?.country?.id });
        setStates(res2.data)
        setSelectedState(selectedUser?.addr?.state)
        const res3 = await apiServices.getCity({ state_id: selectedUser?.addr?.state?.id });
        setCity(res3.data)
        setSelectedCity(selectedUser?.addr?.city)
    }


    const onAdmin = (e) => {
        let addTem = { ...defaultAdmins, [e.target.name]: e.target.value }
        setDefaultAdmins(addTem)
    }

    const setAdmins = () => {
        const x = defaultAdminTab ? [defaultAdmins] : [...defaultAdminTab, defaultAdmins]
        setDefaultAdminTab(x)
    }

    const openFcEdit = async (rowData) => {
        console.log(rowData);
        setEditFacDialog(true)
        setFcEditStatus(true)
        setEditFacData(rowData)
        setFacilityAddTemp({})

        setDefaultCheck(rowData?.admin_default)
        setDefaultAdminTab(rowData?.admins)
        setFcSelectedCountry(rowData?.address?.country)

        const res = await apiServices.getState({ country_id: rowData?.address?.country?.id });
        setFcStates(res.data);
        setFcSelectedState(rowData?.address?.state)
        const res1 = await apiServices.getCity({ state_id: rowData?.address?.state?.id });
        setFcCity(res1.data);
        setFcSelectedCity(rowData?.address?.city)

        setSelectedCountry(rowData?.billing_details?.country)
        const res2 = await apiServices.getState({ country_id: rowData?.billing_details?.country?.id });
        setStates(res2.data)
        setSelectedState(rowData?.billing_details?.state)
        const res3 = await apiServices.getCity({ state_id: rowData?.billing_details?.state?.id });
        setCity(res3.data)
        setSelectedCity(rowData?.billing_details?.city)
    }

    const openViewFc = (rowData) => {
        console.log(rowData);
        setEditFacData(rowData)
        setViewFcDialog(true)
        getMachinesByFac(rowData)
    }

    const openDeleteDialog = (rowData) => {
        setEditFacData(rowData)
        setDeleteFcDialog(true)
    }

    const deletefacility = async () => {
        const res = await apiServices.deleteFacilityData(editFacData);
        res?.data?.success ? successToast(toast,'Facility Deleted Successfully') : failureToast(toast,'Failed to Delete');
        setDeleteFcDialog(false)
        callFacility(selectedUser.userid)
    }

    const onOwnershipSubmit = async (e) => {
        e.preventDefault();
        let data;
        if (selectedOwnership?.code == 'self') {
            data = { customer: editFacData.userid, d_id: ["self"] }
        } else if (selectedOwnership?.code == 'distributor') {

            data = {
                customer: editFacData.userid,
                d_id: selectedDist.d_id[0],
                distributor_data: {
                    d_id: selectedDist.d_id[0],
                    name: selectedDist.name,
                    userRole: selectedDist.userRole,
                    userType: selectedDist.userType,
                    userid: selectedDist.userid
                }
            }
        }

        const res = await apiServices.assignDistributor(data)
        res?.data?.success ? successToast(toast,'User Ownership Updated Successfully') : failureToast(toast, 'Failed to Update')
        setOwnershipDialog(false)
        setUsers([])
        getUser()
    }

    const onMobileSubmit = async(e) =>{
        e.preventDefault();
        setEditNumberDialog(false)
        const payload = { "userid": customerData?.userid, "cno": editNumber }
        const response = await apiServices.editUserCno(payload)
        console.log(response?.data?.success);
        if(response?.data?.success){
            successToast(toast,'User Updated Successfully');
            getUser()
        }else{
            failureToast(toast, 'Failed to Update')
        }
    }

    const changeOwnerShip = async (e) => {
        setSelectedOwnership(e.value);
        setOwnershipClicks(e.value)
    }

    const changeSelectDist = (e) => {
        setSelectedDist(e.value)
        for (var key in e.value.userRole) { if (e.value.userRole[key] == true) { setDistributorRole(key) } }
    }

    const onSalesManagerSubmit = async (e) => {
        e.preventDefault();
        const userTypeOption = (addUser == 'sales') ? 'Sales Manager' : 'Sales Customer';
        const data = { ...salesmanTemp, userType: userTypeOption, d_id: uniSelectedData.d_id[0] }
        const res = await apiServices.createUser(data);
        res.data.success ? toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Added Successfully', life: 3000 }) : toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to Add', life: 3000 });
        setSalesDialog(false)
        setUsers([])
        getUser()
    }

    const onSaleInput = async (e) => {

        if (e.target.name == 'addr' || e.target.name == 'locality') {
            var addTem = { ...salesmanTemp, addr: { ...salesmanTemp.addr, [e.target.name]: e.target.value } }
        } else if (e.target.name == 'country' || e.target.name == 'state' || e.target.name == 'city') {

            if (e.target.name == 'country') {
                const res = await apiServices.getState({ country_id: e.value.id });
                setStates(res.data);
                setSelectedCountry(e.value)
            } else if (e.target.name == 'state') {
                const res = await apiServices.getCity({ state_id: e.value.id });
                setCity(res.data);
                setSelectedState(e.value)
            } else if (e.target.name == 'city') {
                setSelectedCity(e.value)
            }

            var addTem = { ...salesmanTemp, addr: { ...salesmanTemp.addr, [e.target.name]: e.value } }
        } else {
            if (e.target.name == 'cno') {
                if (e.target.value.length == 10) {
                    const contact = '+91' + e.target.value;
                    callVerification(contact)
                }
            }
            var addTem = { ...salesmanTemp, [e.target.name]: e.target.value }
        }
        setSalesmanTemp(addTem)
    }

    const onTeamInput = async (e) => {

        // console.log(cheriseUserType.code == '')

        if (e.target.name == 'zone') {
            setZones(e.value)
            var tem = { ...cheriseTeamTemp, addr: { ...cheriseTeamTemp.addr, [e.target.name]: e.value.code } }

        } else if (e.target.name == 'addr') {
            var tem = { ...cheriseTeamTemp, addr: { ...cheriseTeamTemp.addr, [e.target.name]: e.target.value } }

        } else if (e.target.name == 'country' || e.target.name == 'state' || e.target.name == 'city') {
            if (e.target.name == 'country') {
                const res = await apiServices.getState({ country_id: e.value.id });
                setStates(res.data);
                setSelectedCountry(e.value)
            } else if (e.target.name == 'state') {
                const res = await apiServices.getCity({ state_id: e.value.id });
                setCity(res.data);
                setSelectedState(e.value)
            } else if (e.target.name == 'city') {
                setSelectedCity(e.value)
            }
            var tem = { ...cheriseTeamTemp, addr: { ...cheriseTeamTemp.addr, [e.target.name]: e.value } }
        } else {
            if (e.target.name == 'cno') {
                let contact = '+91' + e.target.value;
                var tem = { ...cheriseTeamTemp, [e.target.name]: contact }
            } else {
                var tem = { ...cheriseTeamTemp, [e.target.name]: e.target.value }
            }
        }

        setCheriseTeamTemp(tem)
    }

    const onTeamInput1 = async (e) => {

        if (e.target.name == "uid" || e.target.name == "cluster" || e.target.name == "locality") {

            var temp = { ...cheriseMarketingTemp, userdata: { ...cheriseMarketingTemp?.userdata, [e.target.name]: e.target.value } }
            console.log(temp);
        } else if (e.target.name == "zone") {
            console.log(e.value);
            setZones(e.value)
            var temp = { ...cheriseMarketingTemp, userdata: { ...cheriseMarketingTemp?.userdata, [e.target.name]: e.value.code } }

        } else if (e.target.name == 'country' || e.target.name == 'state' || e.target.name == 'city') {

            if (e.target.name == 'country') {
                const res = await apiServices.getState({ country_id: e.value.id });
                setStates(res.data);
                setSelectedCountry(e.value)
            } else if (e.target.name == 'state') {
                const res = await apiServices.getCity({ state_id: e.value.id });
                setCity(res.data);
                setSelectedState(e.value)
            } else if (e.target.name == 'city') {
                setSelectedCity(e.value)
            }

            var temp = { ...cheriseMarketingTemp, userdata: { ...cheriseMarketingTemp?.userdata, [e.target.name]: e.value } }

        } else if (e.target.name == 'cno' || e.target.name == 'email' || e.target.name == 'lastName' || e.target.name == 'name') {

            if (e.target.name == 'cno') {
                let contact = '+91' + e.target.value;
                var temp = { ...cheriseMarketingTemp, [e.target.name]: contact }
            } else {
                var temp = { ...cheriseMarketingTemp, [e.target.name]: e.target.value }
            }

        } else if (e.target.name == 'caddr' || e.target.name == 'paddr') {

            var temp = { ...cheriseMarketingTemp, addr: { ...cheriseMarketingTemp.addr, [e.target.name]: e.target.value } }

        } else if (e.target.name == 'owner') {
            console.log(e.value);
            setSelectedMarketingMan(e.value)

        } else if (e.target.name == 'market' || e.target.name == 'sale' || e.target.name == 'service') {
            var check = { ...tapriPermTemp, [e.target.name]: e.target.checked }
            setTapriPermTemp(check)
            var temp = { ...cheriseMarketingTemp, permission: check }
        }

        setCheriseMarketingTemp(temp)
    }


    const actionFacBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    {/* <span className="p-column-title">Actions</span> */}
                    {<Button icon="pi pi-eye" title="View facility" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { openViewFc(rowData) }} />}
                    {rolePermissions?.UserCustomerFacEditBtn && <Button icon="pi pi-pencil" title="Edit" className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { openFcEdit(rowData) }} />}
                    {rolePermissions?.UserCustomerFacDeleteBtn && <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => { openDeleteDialog(rowData) }} />}
                </div>
            </>
        );
    };

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Yes, Delete it." icon="pi pi-check" className=" p-button-success" onClick={() => { deletefacility() }} />
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={() => { setDeleteFcDialog(false) }} />
            </>
        )
    }

    const ownershipDialogFooter = () => {
        return (
            <>
                <Button label="Close" icon="pi pi-times" className=" p-button-danger" onClick={() => { setOwnershipDialog(false) }} />
                <Button label="Submit" icon="pi pi-check" type="submit" form="ownership-form" className=" p-button-success" />
            </>
        )
    }

    const mobileDialogFooter = () => {
        return (
            <>
                <Button label="Close" icon="pi pi-times" className=" p-button-danger" onClick={() => { setEditNumberDialog(false) }} />
                <Button label="Submit" icon="pi pi-check" type="submit" form="mobile-form" className=" p-button-success" />
            </>
        )
    }

    const deleteUserDialogFooter = () => {
        return (
            <>
                <Button label="Cancel" icon="pi pi-times" className=" p-button-success" onClick={() => { setDeleteUserDialog(false) }} />
                <Button label="Yes, Delete it." icon="pi pi-check" className=" p-button-danger" onClick={() => { deleteUserFn() }} />
            </>
        )
    }

    return (
        <>

            <Toast ref={toast} position='center' />

            {
                <>
                    <TableWrapper>
                    {
                        loading ?
                            <UsersDataTableSkeleton />
                            :
                            <>
                                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rolePermissions?.UsersAddUserBtn ? rightToolbarTemplate : null} ></Toolbar>
                                <DataTable value={users} dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users" globalFilter={globalFilter}
                                    emptyMessage="No Data found." header={header} loading={loading} >
                                    <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                                    <Column header="Profile" body={imageBodyTemplate} style={{ width: '8rem' }} ></Column>
                                    <Column field="comapny" header="Account Name" body={CommonBodyForTable} sortable ></Column>
                                    <Column field="name" header="User Name" sortable body={nameBodyTemplate} ></Column>
                                    <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} sortable ></Column>
                                    <Column field="userid" header="User ID" body={CommonBodyForTable} sortable ></Column>
                                    <Column field="position" header="User Type" sortable body={positionBodyTemplate} ></Column>
                                    <Column field="cno" header="Contact" sortable body={mbBodyTemplate} ></Column>
                                    {/* <Column field="email" header="Email Id" body={CommonBodyForTable} sortable ></Column> */}
                                    <Column field="addr" header="Address" body={addressBodyTemplate} sortable ></Column>
                                    {/* <Column field="isActive" header="Active" body={CommonBodyForTable} sortable ></Column> */}
                                    <Column body={rolePermissions?.userActions && actionBodyTemplate}></Column>
                                </DataTable>
                            </>
                    }

                        <DataTable ref={dt} value={usersForTable} dataKey="id" emptyMessage="No Data found." exportFilename={`${defaultPosition?.name} User Details`} style={{ display: 'none' }} >
                            <Column field="index" header="Sr.No." body={CommonBodyForTable} alignHeader="center" ></Column>
                            <Column field="fullName" header="Name" body={CommonBodyForTable} ></Column>
                            <Column field="billingName" header="Billing Name" body={CommonBodyForTable} ></Column>
                            <Column field="userid" header="User Id" body={CommonBodyForTable} ></Column>
                            <Column field="userType" header="Position" body={CommonBodyForTable} ></Column>
                            <Column field="cno" header="Contact" body={CommonBodyForTable} ></Column>
                            <Column field="email" header="Email ID" body={CommonBodyForTable} ></Column>
                            <Column field="address" header="Address" body={CommonBodyForTable} ></Column>
                        </DataTable>

                    </TableWrapper>
                </>
            }

            <Dialog visible={addCheriseTeamDialog} style={{ width: "750px" }} header="Add Cherise Team" modal footer={CTeamDialogFooter} onHide={() => { setAddCheriseTeamDialog(false) }} className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={onTeamSubmit} id="team-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="user-department"> Select Department </label>
                                    <Dropdown id="user-department" value={selectedDept} onChange={changeDepartment} options={departmentItems}
                                        optionLabel="name" placeholder="Select User Department" required autoFocus disabled={deptDisable} ></Dropdown>
                                </div>
                                {
                                    showOption && (
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="user-type">User Type</label>
                                            <Dropdown id="user-type" value={cheriseUserType} onChange={setCheriseUserTypes} options={showUserTypeOption} optionLabel="name" placeholder="Select User Type" required ></Dropdown>
                                        </div>
                                    )
                                }

                                {
                                    showPersonalOption && (
                                        <>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="cfirstname">First Name</label>
                                                <InputText id="cfirstname" type="text" placeholder="First Name" name="name" onChange={onTeamInput} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="clastname">Last Name</label>
                                                <InputText id="clastname" type="text" placeholder="Last Name" name="lastName" onChange={onTeamInput} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="cemail">Email Id</label>
                                                <InputText id="cemail" type="email" placeholder="Email Id" name="email" onChange={onTeamInput} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="ccontactNumber">Contact Number</label>
                                                <InputText id="ccontactNumber" type="text" placeholder="Contact Number" maxLength={10} name="cno" onChange={onTeamInput} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="zone">Zone</label>
                                                <Dropdown id="zone" value={zones} options={zoneDropdownItems} optionLabel="name" placeholder="Select Zones" name="zone" onChange={onTeamInput} filter required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12">
                                                <label htmlFor="address">Address</label>
                                                <InputTextarea id="address" rows="2" placeholder="Address" name="addr" onChange={onTeamInput} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="country">Country</label>
                                                <Dropdown id="country" value={selectedCountry} onChange={onTeamInput} options={countries} optionLabel="name" name="country" placeholder="Select Country" filter required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="state">State</label>
                                                <Dropdown id="state" value={selectedState} onChange={onTeamInput} options={states} optionLabel="name" name="state" placeholder="Select State" filter required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="city">City</label>
                                                <Dropdown id="city" value={selectedCity} onChange={onTeamInput} options={city} optionLabel="name" name="city" placeholder="Select City" filter required></Dropdown>
                                            </div>
                                        </>
                                    )
                                }

                            </div>

                            <div>
                                {showAccManagerOption && (
                                    <>
                                        <h6>PERSONAL INFORMATION</h6>
                                        <div className="p-grid">
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="acc-owner">Account Owner</label>
                                                <Dropdown id="acc-owner" options={marketingMan} onChange={onTeamInput1} value={selectedMarketingMan} optionLabel="name" name="owner" placeholder="Select Account owner" required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="uniq-id">Unique Id</label>
                                                <InputText id="uniq-id" placeholder="Unique Id" type="number" name="uid" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="Accfirst">First Name</label>
                                                <InputText id="Accfirst" type="text" placeholder="First Name" name="name" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="AccLast">Last Name</label>
                                                <InputText id="AccLast" type="text" placeholder="Last Name" name="lastName" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12">
                                                <label htmlFor="accaddress">Current Address</label>
                                                <InputTextarea id="accaddress" rows="2" placeholder="Current Address" name="caddr" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12">
                                                <label htmlFor="accpaddress">Permanant Address</label>
                                                <InputTextarea id="accpaddress" rows="2" placeholder="Permanant Address" name="paddr" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="AccCon">Contact Number</label>
                                                <InputText id="AccCon" type="text" maxLength={10} placeholder="Contact Number" name="cno" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="accemail">Email Id</label>
                                                <InputText id="accemail" type="email" placeholder="Email ID" name="email" onChange={onTeamInput1} required />
                                            </div>
                                        </div>
                                        <h6>AREA INFORMATION</h6>
                                        <div className="p-grid">
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="acc-zone">Zone</label>
                                                <Dropdown id="acc-zone" options={zoneDropdownItems} onChange={onTeamInput1} value={zones} optionLabel="name" name="zone" placeholder="Select Account owner" required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="locality">Locality</label>
                                                <InputText id="locality" type="text" name="locality" placeholder="Locality" onChange={onTeamInput1} required />
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="country">Country</label>
                                                <Dropdown id="country" filter value={selectedCountry} onChange={onTeamInput1} options={countries} optionLabel="name" name="country" placeholder="Select Country" required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="state">State</label>
                                                <Dropdown id="state" filter value={selectedState} onChange={onTeamInput1} options={states} optionLabel="name" name="state" placeholder="Select State" required></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="city">City</label>
                                                <Dropdown id="city" filter value={selectedCity} onChange={onTeamInput1} options={city} optionLabel="name" name="city" placeholder="Select City" required ></Dropdown>
                                            </div>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="acc-cluster">Cluster</label>
                                                <InputText id="acc-cluster" type="text" placeholder="Cluster" name="cluster" onChange={onTeamInput1} required />
                                            </div>
                                        </div>
                                    </>

                                )}

                            </div>

                            <div>
                                {showTaparimanOption && (
                                    <>
                                        <h6>PERMISSIONS</h6>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-4">
                                                <div className="p-field-checkbox">
                                                    <Checkbox inputId="checkOption1" name="market" checked={tapriPermTemp.market} onChange={onTeamInput1} />
                                                    <label htmlFor="checkOption1"> Market And Research</label>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <div className="p-field-checkbox">
                                                    <Checkbox inputId="checkOption2" name="sale" checked={tapriPermTemp.sale} onChange={onTeamInput1} />
                                                    <label htmlFor="checkOption2">Sales</label>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <div className="p-field-checkbox">
                                                    <Checkbox inputId="checkOption3" name="service" checked={tapriPermTemp.service} onChange={onTeamInput1} />
                                                    <label htmlFor="checkOption3">Service</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={addViewerDialog} style={{ width: "650px" }} header="Add Owner" modal footer={viewerDialogFooter} onHide={deleteOwnerDialog} lassName="p-fluid" blockScroll={true}>

                <h5>PERSONAL DETAILS</h5>
                <form onSubmit={onViewerSubmit} id="viewer-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">

                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="vfirstname">First Name</label>
                                    <InputText id="vfirstname" name="name" type="text" placeholder="First Name" onChange={onViewer} required autoFocus autoComplete="off" />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="vlastname">Last Name</label>
                                    <InputText id="vlastname" name="lastName" type="text" placeholder="Last Name" onChange={onViewer} required autoComplete="off" />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="vcontactNumber">Contact Number</label>
                                    <InputText id="vcontactNumber" name="cno" type="text" placeholder="Contact Number" maxLength={10} onChange={onViewer} required autoComplete="off" />
                                    {cnoVerify && <small className="p-error block">Number Already Exists.</small>}
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="vrole">Role</label>
                                    <Dropdown id="vrole" optionLabel="name" filter placeholder="Select Role" name="userRole" options={ownerRoles} value={selectedOwnerRole} onChange={onViewer} required></Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={facilityDialog} style={{ width: "950px" }} header={`Facility Details (${selectedUser?.comapny ? selectedUser?.comapny : 'NA'})`} modal className="p-fluid" onHide={() => { setFacilityDialog(false) }} footer={facilityDFooter} maximizable={true} blockScroll={true} >
                <>
                    <TableWrapper>
                        {
                            (rolePermissions?.UserCustomerFacAddBtn && selectedUser?.userType == "Sales Customer") && 
                            <Toolbar right={addFacRightToolbarTemplate}></Toolbar>
                        }
                        <DataTable value={facilityUser} paginator className="datatable-responsive" rows={10} dataKey="_id" rowHover emptyMessage="No facility found." globalFilter={globalFilter1} loading={facilityLoading} >
                            <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                            <Column field="facid" header="Facility Id" sortable body={CommonBodyForTable}></Column>
                            <Column field="facname" header="Facility Name" sortable body={CommonBodyForTable}></Column>
                            <Column field="facility_address" header="Facility Address" sortable body={facilityAddressTemplate} ></Column>
                            <Column field="kam_userid" header="KAM Id" sortable body={CommonBodyForTable} ></Column>
                            <Column field="kam_name" header="KAM Name" sortable body={CommonBodyForTable} ></Column>
                            <Column header="Action" body={actionFacBodyTemplate}></Column>
                        </DataTable>
                    </TableWrapper>
                </>
            </Dialog>

            <Dialog visible={salesDialog} style={{ width: "850px" }} header={`Add ${addUser == 'sales' ? 'Sales Manager' : 'Sales Customer'}`} modal className="p-fluid" footer={salesFooter} onHide={() => { setSalesDialog(false) }} maximizable={true} blockScroll={true} >
                <form onSubmit={onSalesManagerSubmit} id="salesman-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="sfirstname">First Name</label>
                                    <InputText id="sfirstname" type="text" name="name" placeholder="First Name" onChange={onSaleInput} autoFocus required autoComplete="off" />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="slastname">Last Name</label>
                                    <InputText id="slastname" type="text" name="lastName" placeholder="Last Name" onChange={onSaleInput} required autoComplete="off" />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="semailid">Email Id</label>
                                    <InputText id="semailid" type="email" name="email" placeholder="Email Id" onChange={onSaleInput} required autoComplete="off" />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="scontact">Contact Number</label>
                                    <InputText id="scontact" type="text" name="cno" maxLength={10} placeholder="Contact Number" onChange={onSaleInput} required autoComplete="off" />
                                    {cnoVerify && <small className="p-error block">Number Already Exists.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="saddress">Address</label>
                                    <InputTextarea id="saddress" rows="2" name="addr" placeholder="Address" onChange={onSaleInput} required />
                                </div>
                                <div className="p-field p-col-6 ">
                                    <label htmlFor="scontact">Locality</label>
                                    <InputText type="text" placeholder="Locality" name="locality" onChange={onSaleInput} required autoComplete="off" />
                                </div>
                                <div className="p-field p-col-6 ">
                                    <label htmlFor="country">Country</label>
                                    <Dropdown id="dcountry" filter optionLabel="name" placeholder="Select Country" name="country" options={countries} value={selectedCountry} onChange={onSaleInput} required></Dropdown>
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="state">State</label>
                                    <Dropdown id="dstate" optionLabel="name" filter placeholder="Select State" name="state" options={states} value={selectedState} onChange={onSaleInput} required></Dropdown>
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="city">City</label>
                                    <Dropdown id="dcity" optionLabel="name" filter placeholder="Select City" name="city" options={city} value={selectedCity} onChange={onSaleInput} required></Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={editFacDialog} style={{ width: "900px" }} header={`${fcEditStatus ? 'Edit' : 'Add'} Facility`} modal className="p-fluid" footer={facilityFooter} onHide={() => { setEditFacDialog(false) }} maximizable={true} blockScroll={true}>

                <form onSubmit={onFacilitySubmit} id="facility-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 ">
                                    <label htmlFor="firstname">Facility Name</label>
                                    <InputText id="firstname" type="text" name="facname" onChange={onFacUpdateName} defaultValue={fcEditStatus ? editFacData?.facname : ''} placeholder="Facility Name" required autoComplete="off" />
                                </div>

                                <h5 className="p-ml-2">SHIPPING DETAILS : <small className="p-error"> This will be considered as shipping address </small> </h5>

                              
                                <div className="p-field p-col-12 ">
                                    <label htmlFor="lastname">Address</label>
                                    <InputTextarea id="address" rows="2" name="address" onChange={onFacUpAddr} defaultValue={fcEditStatus ? editFacData?.address?.address : ''} placeholder="Address" required />
                                </div>


                                {
                                    fcEditStatus ?
                                        <>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="coun">Country</label>
                                                <InputText id="coun" type="text" placeholder="Country" value={fcEditStatus ? editFacData?.address?.country?.name : ''} disabled />
                                            </div>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="stat">State</label>
                                                <InputText id="stat" type="text" placeholder="State" value={fcEditStatus ? editFacData?.address?.state?.name : ''} disabled />
                                            </div>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="cat">City</label>
                                                <InputText id="cat" type="text" placeholder="City" value={fcEditStatus ? editFacData?.address?.city?.name : ''} disabled />
                                            </div>

                                        </> :

                                        <>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="emailid">Country</label>
                                                <Dropdown filter optionLabel="name" placeholder="Select Country" name="country" options={countries} onChange={onFcCountryDrop} value={fcSelectedCountry} disabled={fcEditStatus} ></Dropdown>
                                            </div>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="contact">State</label>
                                                <Dropdown id="dstate" optionLabel="name" filter placeholder="Select State" name="state" onChange={onFcStateDrop} value={fcSelectedState} options={fcStates} disabled={fcEditStatus} ></Dropdown>
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="address">City</label>
                                                <Dropdown id="dcity" optionLabel="name" filter placeholder="Select City" name="city" value={fcSelectedCity} onChange={onFcCityDrop} options={fcCity} disabled={fcEditStatus}></Dropdown>
                                            </div>
                                        </>

                                }


                                <div className="p-field p-col-6 ">
                                    <label htmlFor="locality">Latitude</label>
                                    <InputText id="locality" type="text" name="lat" onChange={onFcLtLng} placeholder="Latitude" defaultValue={fcEditStatus ? editFacData?.address?.location?.lat : ''} autoComplete="off" />
                                </div>
                                <div className="p-field p-col-6 ">
                                    <label htmlFor="country">Longitude</label>
                                    <InputText id="locality" type="text" name="lng" onChange={onFcLtLng} placeholder="Longitude" defaultValue={fcEditStatus ? editFacData?.address?.location?.lng : ''} autoComplete="off" />
                                </div>

                                <div className="p-field p-col-6 ">
                                    <label htmlFor="sip-pincode">Pincode</label>
                                    <InputText id="sip-pincode" type="text" name="pincode" onChange={onFacUpPincode} maxLength={6} placeholder="Pincode" defaultValue={fcEditStatus ? editFacData?.address?.pincode : ''} autoComplete="off" />
                                </div>



                                {/*  */}

                                <div className="p-field p-col-12 ">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <label htmlFor="city">Default Details:</label>
                                        <Checkbox inputId="binary" onChange={onDefaultValue} checked={defaultCheck} style={{ marginLeft: "10px" }} />
                                        <p style={{ marginLeft: "3px" }}>
                                            Default Admin Details
                                        </p>
                                    </div>


                                    <div>
                                        {
                                            !defaultCheck &&
                                            <div className="Admin_default_section">
                                                <div className="Facility_defaultsettings">
                                                    <h5>Admin Details</h5>
                                                </div>
                                                <div className="p-grid">
                                                    <div className="p-field p-col-6 ">
                                                        <label htmlFor="experiod"> Admin Name </label>
                                                        <InputText type="text" name="name" defaultValue={defaultAdmins.name} onChange={onAdmin} placeholder="Admin Name" autoComplete="off" />
                                                    </div>
                                                    <div className="p-field p-col-6 ">
                                                        <label htmlFor="experiod">Admin Contact</label>
                                                        <InputText type="text" maxLength={10} name="cno" defaultValue={defaultAdmins.cno} placeholder="Admin Contact" onChange={onAdmin} autoComplete="off" />
                                                    </div>
                                                    <div className="p-field p-col-2 ">
                                                        <Button label="Add New" icon="pi pi-check" className="p-button-info" disabled={(defaultAdmins.name == "" || defaultAdmins.cno == "") ? true : false} onClick={setAdmins} />
                                                    </div>
                                                </div>

                                                <div className="p-grid crud-demo">
                                                    <div className="card" >
                                                        <DataTable className="datatable-responsive" value={defaultAdminTab} style={{ margin: '0 auto', textAlign: 'center' }} responsiveLayout="scroll">
                                                            <Column field="name" header="Admin Name" body={CommonBodyForTable}></Column>
                                                            <Column field="cno" header="Admin Contact" body={CommonBodyForTable}></Column>
                                                            <Column field="Action" header="Action" body={facActionTemplate}></Column>
                                                        </DataTable>
                                                    </div>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5>BILLING DETAILS :  <small className="p-error"> This will be considered as Billing Address</small></h5>


                        <div className="p-grid">

                            {
                                !fcEditStatus &&
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox inputId="binary" name="sameasabove" onChange={setAddresses} checked={sameAsAboveCheck} style={{ marginLeft: "10px" }} />
                                        <p style={{ marginLeft: "3px" }}> Same as Above </p>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox inputId="binary" name="sameasacc" onChange={setAddresses} checked={sameAsAccCheck} style={{ marginLeft: "10px" }} />
                                        <p style={{ marginLeft: "3px" }}> Same as Account</p>
                                    </div>
                                </>
                            }

                            <div className="p-col-12 p-md-12">
                                <div className="p-fluid p-formgrid p-grid">
                                    {/* <div className="p-field p-col-6 ">
                            <label htmlFor="experiod">Extension Period (Calendar Days)</label>
                                <InputText id="experiod" type="number" name="extension" onChange={onFcAddChange} min={0} defaultValue={fcEditStatus?editFacData?.billing_details?.extension:''} placeholder="Extension Period" required autoComplete="off"/>
                            </div> */}


                                    <div className="p-field p-col-6 ">
                                        <label htmlFor="company">Company Name</label>
                                        <InputText id="company" type="text" name="cmpname" onChange={onFcAddChange} placeholder="Comapny Name" defaultValue={fcEditStatus ? editFacData?.billing_details?.cmpname : facilityAddTemp?.billing_details?.cmpname} required autoComplete="off" />
                                    </div>
                                    <div className="p-field p-col-6 ">
                                        <label htmlFor="gstno">GST Number</label>
                                        <InputText id="gstno" type="text" name="gstnumber" onChange={onFcAddChange} placeholder="GST Number" defaultValue={fcEditStatus ? editFacData?.billing_details?.gstnumber : facilityAddTemp?.billing_details?.gstnumber} required autoComplete="off" />
                                    </div>
                                    <div className="p-field p-col-12">
                                        <label htmlFor="address">Address</label>
                                        <InputTextarea id="address" rows="2" name="addr" onChange={onFcAddChange} placeholder="Address" defaultValue={fcEditStatus ? editFacData?.billing_details?.addr : facilityAddTemp?.billing_details?.addr} required autoComplete="off" />
                                    </div>
                                    {/* <div className="p-field p-col-6 ">
                                <label htmlFor="locality">Locality</label>
                                <InputText id="locality" type="text" name="locality" onChange={onFcAddChange} placeholder="Locality" defaultValue={fcEditStatus ? editFacData?.billing_details?.locality : facilityAddTemp?.billing_details?.locality} required autoComplete="off"/>
                            </div> */}


                                    {

                                        fcEditStatus ?
                                            <>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="coun">Country</label>
                                                    <InputText id="coun" type="text" placeholder="Country" value={fcEditStatus ? editFacData?.billing_details?.country?.name : ''} disabled />
                                                </div>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="stat">State</label>
                                                    <InputText id="stat" type="text" placeholder="State" value={fcEditStatus ? editFacData?.billing_details?.state?.name : ''} disabled />
                                                </div>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="cat">City</label>
                                                    <InputText id="cat" type="text" placeholder="City" value={fcEditStatus ? editFacData?.billing_details?.city?.name : ''} disabled />
                                                </div>

                                            </> :
                                            <>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="country">Country</label>
                                                    <Dropdown id="dcountry" filter optionLabel="name" name="country" options={countries} onChange={onFcCCS} value={selectedCountry} placeholder="Select Country" disabled={fcEditStatus}></Dropdown>
                                                </div>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="state">State</label>
                                                    <Dropdown id="dstate" optionLabel="name" filter name="state" options={states} onChange={onFcCCS} value={selectedState} placeholder="Select State" disabled={fcEditStatus}></Dropdown>
                                                </div>
                                                <div className="p-field p-col-6 ">
                                                    <label htmlFor="city">City</label>
                                                    <Dropdown id="dcity" optionLabel="name" name="city" filter options={city} onChange={onFcCCS} value={selectedCity} placeholder="Select City" disabled={fcEditStatus}></Dropdown>
                                                </div>
                                            </>

                                    }


                                    <div className="p-field p-col-6 ">
                                        <label htmlFor="bill-Pincode">Pincode</label>
                                        <InputText id="bill-Pincode" type="text" name="pincode" onChange={onFcAddChange} placeholder="Pincode" defaultValue={fcEditStatus ? editFacData?.billing_details?.pincode : facilityAddTemp?.billing_details?.pincode} required autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </Dialog>

            <Dialog visible={deleteFcDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={(e) => { setDeleteFcDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want to delete Facility?</span></b> <br /><br />
                    </center>
                </div>
            </Dialog>

            <Dialog visible={ownershipDialog} style={{ width: '750px' }} header="Edit OwnerShip" modal footer={ownershipDialogFooter} onHide={(e) => { setOwnershipDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={onOwnershipSubmit} id="ownership-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 ">
                                    <label htmlFor="firstname">Select Ownership</label>
                                    <Dropdown id="dcity" optionLabel="name" name="" filter onChange={(e) => { changeOwnerShip(e) }} options={ownershipDropdownItems} value={selectedOwnership} placeholder="Select Ownership" required></Dropdown>
                                </div>
                                {
                                    selectedOwnership?.code == 'distributor' &&
                                    <>
                                        <div className="p-field p-col-6">
                                            <label htmlFor="own-dist1">Distributor</label>
                                            <Dropdown id="own-dist1" optionLabel="name" name="" filter options={distributors} onChange={(e) => { changeSelectDist(e) }} value={selectedDist} placeholder="Select Distributor" disabled={editFacData?.distributor_data?.userType == 'distributor' ? true : false} required></Dropdown>
                                        </div>

                                        {
                                            distributors &&
                                            <div className="p-field p-col-6">
                                                <label htmlFor="dist-role">Role</label>
                                                <InputText id="dist-role" type="text" placeholder="User Role" value={distributorRole} disabled required />
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={editNumberDialog} style={{ width: '450px' }} header="Edit Mobile Number" modal footer={mobileDialogFooter} onHide={(e) => { setEditNumberDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={onMobileSubmit} id="mobile-form" className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="mbNumber">Mobile Number</label>
                                    <InputText id="mbNumber" type="text" placeholder="Enter Mobile Number" value={editNumber} onChange={(e)=>{setEditNumber(e.target.value)}} required />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={(e) => { setDeleteUserDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Are you sure you want to remove user ?</span></b>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={viewCustomerDialog} style={{ width: '650px' }} header={`Customer Details (${customerData?.billing_details?.company}) `} modal footer={viewCustomerFooter} onHide={() => { setViewCustomerDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Name" value={customerData?.prefix + ' ' + customerData?.name + ' ' + (customerData?.lastName ? customerData?.lastName : '')} />
                                        <CommonForDetails name="Contact Number" value={customerData?.cno} />
                                        <CommonForDetails name="Email Id" value={customerData?.email} />
                                        <CommonForDetails name="Pincode" value={customerData?.addr?.pincode || 'NA'} />
                                        <CommonForDetails name="Country" value={customerData?.addr?.country?.name} />
                                        <CommonForDetails name="State" value={customerData?.addr?.state?.name} />
                                        <CommonForDetails name="City" value={customerData?.addr?.city?.name} />
                                        {/* <CommonForDetails name="GST Number" value={customerData?.billing_details?.gstNumber || customerData?.billing_details?.gstnumber} /> */}
                                        {/* <CommonForDetails name="Account Name" value={customerData?.billing_details?.company} /> */}
                                        <CommonForDetails name="Beverage Offset" value={customerData?.beverages_offset || 0} />
                                        <CommonForDetails name="Shipping Address" value={customerData?.addr?.addr} />
                                        <CommonForDetails name="Billing Address" value={customerData?.billing_details?.addr} />
                                        <CommonForDetails name="Ownership" value={customerData?.ownerShip || 'NA'} />
                                    </div>
                                    <hr></hr>
                                    <h6 className="p-ml-2" >Payment Terms</h6>
                                    <hr></hr>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Mode" value={customerData?.payemntTerms?.mode || 'NA'} />
                                        { customerData?.payemntTerms?.mode != 'PREPAID' &&  <CommonForDetails name="Extension Period" value={customerData?.payemntTerms?.extPeriod || 'NA'} /> } 
                                        <CommonForDetails name="Advance Amount Percent" value={customerData?.payemntTerms?.minAmtPercent || 'NA'} />
                                        <CommonForDetails name="Zoho Id" value={customerData?.payemntTerms?.zohoID || 'NA'} />
                                        <CommonForDetails name="Credit Limit" value={customerData?.payemntTerms?.Credit_Limit || 'NA'} />
                                        {/* <CommonForDetails name="Prepaid Payment Type" value={customerData?.payemntTerms?.Prepaid_Payment_Type ? (customerData?.payemntTerms?.Prepaid_Payment_Type ? 'Yes' : 'No') : 'NA'} /> */}
                                        <CommonForDetails name="Billing Pattern" value={customerData?.billing_pattern || 'NA'} />
                                        { customerData?.billing_pattern == 'Cupwise' && <CommonForDetails name="Cup Cost" value={customerData?.cup_cost || 0} /> }
                                        { customerData?.billing_pattern == 'PacketWise' && <CommonForDetails name="Packet Cost" value={customerData?.packet_cost || 'NA'} /> }

                                    </div>

                                    {
                                        customerData?.distributor_data &&
                                        <>
                                            <hr></hr>
                                            <h6 className="p-ml-2">Distributor's Data</h6>
                                            <hr></hr>
                                            <div className='p-grid'>
                                                <CommonForDetails name="Company Name" value={customerData?.distributor_data?.company_name || 'NA'} />
                                                <CommonForDetails name="Cup Cost" value={customerData?.distributor_data?.cup_cost || 'NA'} />
                                                <CommonForDetails name="Packet Cost" value={customerData?.distributor_data?.packet_cost || 'NA'} />
                                                <CommonForDetails name="Role" value={customerData?.distributor_data?.role || 'NA'} />
                                                <CommonForDetails name="Speciality Cost" value={customerData?.distributor_data?.speciality_cost || 'NA'} />
                                            </div>
                                        </>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={viewFcDialog} style={{ width: '650px' }} header={`Facility Details`} modal footer={viewFcFooter} onHide={() => { setViewFcDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Facility Name" value={editFacData?.facname} />
                                        {/* editFacData?.facname */}
                                    </div>
                                    <h5>Shipping Details</h5>
                                    <div className='p-grid'>

                                        <CommonForDetails name="Pincode" value={editFacData?.address?.pincode ? editFacData?.address?.pincode : 'NA'} />
                                        <CommonForDetails name="Address" value={editFacData?.address?.address} />
                                        <CommonForDetails name="Country" value={editFacData?.address?.country?.name} />
                                        <CommonForDetails name="State" value={editFacData?.address?.state?.name} />
                                        <CommonForDetails name="City" value={editFacData?.address?.city?.name} />
                                        <CommonForDetails name="Latitude" value={editFacData?.address?.location?.lat} />
                                        <CommonForDetails name="Longitude" value={editFacData?.address?.location?.lng} />
                                    </div>

                                    <h5>Billing Details</h5>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Company Name" value={editFacData?.billing_details?.cmpname} />
                                        <CommonForDetails name="GST Number" value={editFacData?.billing_details?.gstnumber} />
                                        <CommonForDetails name="Address" value={editFacData?.billing_details?.addr} />
                                        <CommonForDetails name="State" value={editFacData?.billing_details?.state?.name} />
                                        <CommonForDetails name="City" value={editFacData?.billing_details?.city?.name} />
                                        <CommonForDetails name="Pincode" value={editFacData?.billing_details?.pincode} />
                                    </div>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-grid crud-demo">
                            <DataTable value={facilityMachines} className="datatable-responsive" rows={10} dataKey="_id" rowHover emptyMessage="No CVES found." >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column>
                                <Column field="id" header="CVES ID" sortable body={CommonBodyForTable}></Column>
                                <Column field="subid" header="Subscription ID" sortable body={CommonBodyForTable}></Column>
                            </DataTable>
                        </div>
                    </div>

                </div>
            </Dialog>

            <CustomerDialog 
                editCustomerDialog={editCustomerDialog} 
                setEditCustomerDialog={setEditCustomerDialog} 
                customerData={customerData} 
                getUser={getUser} 
            />

            <DistributorDialog 
                addDistriDialog={addDistriDialog} 
                setAddDistriDialog={setAddDistriDialog} 
                distributor={distributor} 
                getUser={getUser} 
            />

            <FormulationDialog 
                setOpenFormulationDialog={setOpenFormulationDialog} 
                openFormulationDialog={openFormulationDialog} 
                editFormulation={editFormulation} 
                getUser={getUser} 
                setLoading={setLoading} 
                selectedUserType={selectedUserType} 
            />

            <TapriManDialog 
                taprimanModal={taprimanModal} 
                setTaprimanModal={setTaprimanModal} 
                taprimanData={taprimanData} 
                getUser={getUser} 
                allUsers={allUsers} 
                setLoading={setLoading} 
                selectedUserType={selectedUserType} 
            />

        </>
    )
}


