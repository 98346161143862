export const sideMenuOptions = {
    "developer": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-table', to: '/machine-orders' },
                { label: 'CVES Onboard Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                { label: 'Order Tracker', icon: 'pi pi-list', to: '/order-master' },
                // { label: 'CTVM Order', icon: 'pi pi-th-large', to: '/ctvm-orders' },
                // { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-order' },
                // { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-order' },


            ]
        },
        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'CVES', icon: 'pi pi-sliders-h', to: '/fgmachines' },
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },

        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [

                { label: 'Inventory Overview', icon: 'pi pi-palette', to: '/inventory' },
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' },
                { label: 'Subscription Packages', icon: 'pi pi-inbox', to: '/packages' },
                { label: 'Raw Materials', icon: 'pi pi-table', to: '/raw-material' },
                // { label: 'Contacts', icon: 'pi pi-users',to: '/contacts' },
            ]
        },

        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },

        // {
        //     label: 'FOG', icon: 'pi pi-list',
        //     items: [
        //         { label: 'FOG Overview', icon: 'pi pi-palette',to: '/fog-overview'}
        //     ]   
        // },

        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Test Analytics', icon: 'pi pi-chart-line', to: '/analytics-copy'
        },
        {
            label: 'CVES Maintenance', icon: 'pi pi-chart-bar', to: '/cves-predictive-maintenance'
        },
        {
            label: 'Audit System', icon: 'pi pi-align-center', to: '/audit-system'
        },


        // {
        //     label: 'Old Analytics', icon: 'pi pi-chart-line',to: '/analyticsMain'
        // },

        {
            label: 'Performance', icon: 'pi pi-chart-bar',
            items: [

                { label: 'Performance Overview', icon: 'pi pi-th-large', to: '/performance' },
                { label: 'KAM Performance', icon: 'pi pi-users', to: '/kam-performance' },
            ]
        },

        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        // {
        //     label: 'Transaction', icon: 'pi pi-dollar',
        //     items: [

        //         { label: 'QR Transaction', icon: 'pi pi-book', to: '/qr-transaction' },
        //         { label: 'RFID Transaction', icon: 'pi pi-book', to: '/rfid-transaction' },
        //         { label: 'Custom QR', icon: 'pi pi-book', to: '/custom-qr-transaction' },
        //     ]
        // },

        {
            label: 'Notifications', icon: 'pi pi-mobile', to: '/notifications'
        },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [

        //         // { label: 'Analytics', icon: 'pi pi-chart-line', to: '/crm-analytics' },
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //         { label: 'Tasks', icon: 'pi pi-list', to: '/tasks' }
        //     ]
        // },  
        { label: 'Billing', icon: 'pi pi-money-bill', to: '/billing' },
        {
            label: 'More', icon: 'pi pi-th-large',
            items: [
                { label: 'Technoman Tracking', icon: 'pi pi-users', to: '/technoman-tracking' },
                { label: 'FAQ Details', icon: 'pi pi-question', to: '/faq-details' },
                { label: 'Training Videos', icon: 'pi pi-video', to: '/training-videos' },
                { label: 'Sales Targets', icon: 'pi pi-shield', to: '/sales-targets' },
                { label: 'CVES Actions', icon: 'pi pi-sliders-h', to: '/cves-actions' },
                { label: 'App Version Control', icon: 'pi pi-tablet', to: '/app-version-control' },
                { label: 'Merchants', icon: 'pi pi-credit-card', to: '/merchants' },
                { label: 'CVES Versions', icon: 'pi pi-file-o', to: '/cves-versions' }
            ]
        },
        // {
        //     label: 'App Manager', icon: 'pi pi-mobile',
        //     items: [
        //         { label: 'App Manager', icon: 'pi pi-cog', to: '/app-manager' }                
        //     ]
        // },
        {
            label: 'Admin Manager', icon: 'pi pi-cog',
            items: [
                // { label: 'Add Demo Stats', icon: 'pi pi-plus', to: '/addStats' }
                { label: 'OTP Manager', icon: 'pi pi-key', to: '/admin-manager' },
                // { label: 'Delivery Challan', icon: 'pi pi-pdf', to: '/delivery-challan' },

            ]
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },

        // {
        //     label: 'UI Kit', icon: 'pi pi-star-o',
        //     items: [
        //         { label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout' },
        //         { label: 'Input', icon: 'pi pi-check-square', to: '/input' },
        //         { label: 'Float Label', icon: 'pi pi-bookmark', to: '/floatlabel' },
        //         { label: 'Invalid State', icon: 'pi pi-exclamation-circle', to: '/invalidstate' },
        //         { label: 'Button', icon: 'pi pi-mobile', to: '/button', className: 'rotated-icon' },
        //         { label: 'Table', icon: 'pi pi-table', to: '/table' },
        //         { label: 'List', icon: 'pi pi-list', to: '/list' },
        //         { label: 'Tree', icon: 'pi pi-share-alt', to: '/tree' },
        //         { label: 'Panel', icon: 'pi pi-tablet', to: '/panel' },
        //         { label: 'Overlay', icon: 'pi pi-clone', to: '/overlay' },
        //         { label: 'Media', icon: "pi pi-image", to: '/media' },
        //         { label: 'Menu', icon: 'pi pi-bars', to: '/menu' },
        //         { label: 'Message', icon: 'pi pi-comment', to: '/message' },
        //         { label: 'File', icon: 'pi pi-file', to: '/file' },
        //         { label: 'Chart', icon: 'pi pi-chart-bar', to: '/chart' },
        //         { label: 'Misc', icon: 'pi pi-circle-off', to: '/misc' }
        //     ]
        // },
        // {
        //     label: 'Utilities', icon: 'pi pi-compass',
        //     items: [
        //         { label: 'Display', icon: 'pi pi-desktop', to: '/display' },
        //         { label: 'Elevation', icon: 'pi pi-external-link', to: '/elevation' },
        //         { label: 'Flexbox', icon: 'pi pi-directions', to: '/flexbox' },
        //         { label: 'Icons', icon: 'pi pi-search', to: '/icons' },
        //         { label: 'Widgets', icon: 'pi pi-star-o', to: '/widgets' },
        //         { label: 'Grid System', icon: 'pi pi-th-large', to: '/grid' },
        //         { label: 'Spacing', icon: 'pi pi-arrow-right', to: '/spacing' },
        //         { label: 'Typography', icon: 'pi pi-align-center', to: '/typography' },
        //         { label: 'Text', icon: 'pi pi-pencil', to: '/text' },
        //     ]
        // },
        // {
        //     label: 'Pages', icon: 'pi pi-briefcase',
        //     items: [
        //         { label: 'Crud', icon: 'pi pi-pencil', to: '/crud' },
        //         { label: 'Calendar', icon: 'pi pi-calendar-plus', to: '/calendar' },
        //         { label: 'Timeline', icon: 'pi pi-calendar', to: '/timeline' },
        //         { label: 'Landing', icon: 'pi pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
        //         { label: 'Login', icon: 'pi pi-sign-in', to: '/login' },
        //         { label: 'Invoice', icon: 'pi pi-dollar', to: '/invoice' },
        //         { label: 'Help', icon: 'pi pi-question-circle', to: '/help' },
        //         { label: 'Error', icon: 'pi pi-times-circle', to: '/error' },
        //         { label: 'Not Found', icon: 'pi pi-exclamation-circle', to: '/notfound' },
        //         { label: 'Access Denied', icon: 'pi pi-lock', to: '/access' },
        //         { label: 'Empty Page', icon: 'pi pi-circle-off', to: '/empty' }
        //     ]
        // },
        // {
        //     label: 'Hierarchy', icon: 'pi pi-align-left',
        //     items: [
        //         {
        //             label: 'Submenu 1', icon: 'pi pi-align-left',
        //             items: [
        //                 {
        //                     label: 'Submenu 1.1', icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.1.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.1.2', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.1.3', icon: 'pi pi-align-left' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 1.2', icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.2.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.2.2', icon: 'pi pi-align-left' }
        //                     ]
        //                 },
        //             ]
        //         },
        //         {
        //             label: 'Submenu 2', icon: 'pi pi-align-left',
        //             items: [
        //                 {
        //                     label: 'Submenu 2.1', icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 2.1.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 2.1.2', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 2.1.3', icon: 'pi pi-align-left' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 2.2', icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 2.2.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 2.2.2', icon: 'pi pi-align-left' }
        //                     ]
        //                 },
        //             ]
        //         }
        //     ]
        // },
        // {
        //     label: 'Start', icon: 'pi pi-download',
        //     items: [
        //         { label: 'Documentation', icon: 'pi pi-question', to: '/documentation' },
        //         { label: 'Buy Now', icon: 'pi pi-shopping-cart', command: () => { window.location = "https://www.primefaces.org/store" } }
        //     ]
        // }

    ],

    "management": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' },
                // { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        // {
        //     label: 'Orders', icon: 'pi pi-shopping-cart',
        //     items: [
        //         // { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
        //         // { label: 'CTVM Order', icon: 'pi pi-th-large', to: '/ctvm-orders' },
        //         { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-order' }
        //     ]
        // },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //     ]
        // },
        { label: 'Billing', icon: 'pi pi-money-bill', to: '/billing' },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "owner": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                // { label: 'CTVM Order', icon: 'pi pi-th-large', to: '/ctvm-orders' },
                { label: 'CVES Onboard Orders', icon: 'pi pi-briefcase', to: '/orders' },
                // { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-order' }
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },
            ]
        },
        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'CVES', icon: 'pi pi-sliders-h', to: '/fgmachines' },
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },

        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [

                { label: 'Inventory Overview', icon: 'pi pi-palette', to: '/inventory' },
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' },
                { label: 'Subscription Packages', icon: 'pi pi-inbox', to: '/packages' },
                { label: 'Raw Materials', icon: 'pi pi-table', to: '/raw-material' }
            ]
        },

        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },

        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },

        {
            label: 'Performance', icon: 'pi pi-chart-bar',
            items: [

                { label: 'Performance Overview', icon: 'pi pi-th-large', to: '/performance' },
                { label: 'KAM Performance', icon: 'pi pi-users', to: '/kam-performance' },
            ]
        },

        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        // {
        //     label: 'Transaction', icon: 'pi pi-dollar',
        //     items: [

        //         { label: 'QR Transaction', icon: 'pi pi-book', to: '/qr-transaction' },
        //         { label: 'RFID Transaction', icon: 'pi pi-book', to: '/rfid-transaction' },
        //         { label: 'Custom QR', icon: 'pi pi-book', to: '/custom-qr-transaction' },

        //     ]
        // },
        // {
        //     label: 'Notifications', icon: 'pi pi-mobile',to: '/notifications'
        // },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [

        //         // { label: 'Analytics', icon: 'pi pi-chart-line', to: '/crm-analytics' },
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //         { label: 'Tasks', icon: 'pi pi-list', to: '/tasks' }
        //     ]
        // },

        { label: 'Billing', icon: 'pi pi-money-bill', to: '/billing' },
        {
            label: 'More', icon: 'pi pi-th-large',
            items: [
                { label: 'Technoman Tracking', icon: 'pi pi-users', to: '/technoman-tracking' },
                { label: 'FAQ Details', icon: 'pi pi-question', to: '/faq-details' },
                { label: 'CVES Actions', icon: 'pi pi-sliders-h', to: '/cves-actions' },
            ]
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "viewer": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                // { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                // { label: 'CTVM Order', icon: 'pi pi-th-large', to: '/ctvm-orders' },
                { label: 'CVES Onboard Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-order' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-order' }
            ]
        },
        // {
        //     label: 'Finished Goods', icon: 'pi pi-check-circle',
        //     items: [
        //         { label: 'CVES', icon: 'pi pi-sliders-h', to: '/fgmachines' },
        //         { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
        //     ]
        // },

        // {
        //     label: 'Material Inventory', icon: 'pi pi-th-large',
        //     items: [

        //         { label: 'Inventory Overview', icon: 'pi pi-palette',to: '/inventory'},
        //         { label: 'Inventory Cycle', icon: 'pi pi-replay',to: '/inventory-cycle' },
        //         { label: 'Subscription Packages', icon: 'pi pi-inbox',to: '/packages' },
        //         { label: 'Raw Materials', icon: 'pi pi-table',to: '/raw-material' }
        //     ]   
        // },

        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },

        // {
        //     label: 'Users', icon: 'pi pi-users',to: '/users'
        // },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },

        {
            label: 'Performance', icon: 'pi pi-chart-bar',
            items: [

                { label: 'Performance Overview', icon: 'pi pi-th-large', to: '/performance' },
                { label: 'KAM Performance', icon: 'pi pi-users', to: '/kam-performance' },
            ]
        },

        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        // {
        //     label: 'Transaction', icon: 'pi pi-dollar',
        //     items: [

        //         { label: 'QR Transaction', icon: 'pi pi-book', to: '/qr-transaction' },
        //         { label: 'RFID Transaction', icon: 'pi pi-book', to: '/rfid-transaction' },
        //         { label: 'Custom QR', icon: 'pi pi-book', to: '/custom-qr-transaction' },

        //     ]
        // },

        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [

        //         // { label: 'Analytics', icon: 'pi pi-chart-line', to: '/crm-analytics' },
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //         { label: 'Tasks', icon: 'pi pi-list', to: '/tasks' }
        //     ]
        // },

        // { label: 'Billing', icon: 'pi pi-money-bill',to: '/billing' },
        // {
        //     label: 'More', icon: 'pi pi-th-large',
        //     items: [    
        //         { label: 'Technoman Tracking', icon: 'pi pi-users', to: '/technoman-tracking' },
        //     ]
        // },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "marketmanager": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        // {
        //     label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        // },
        {
            label: 'Performance', icon: 'pi pi-chart-bar', to: '/performance'
        },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "accountmanager": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' }
            ]
        },

        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                // { label: 'CTVM Order', icon: 'pi pi-th-large', to: '/ctvm-orders' },
                // { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-order' },
                // { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-order' },
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },

            ]
        },

        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        {
            label: 'Performance', icon: 'pi pi-chart-bar', to: '/performance'
        },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        // {
        //     label: 'MIS Report', icon: 'pi pi-file-o',to: '/mis-report'
        // },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "plantmanager": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'CVES Onboard Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "operation": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },

        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' }
            ]
        },

        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },
        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [
                { label: 'Inventory Overview', icon: 'pi pi-palette', to: '/inventory' },
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' }
            ]
        },
        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        {
            label: 'Performance', icon: 'pi pi-chart-bar', to: '/performance'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },

        // {
        //     label: 'Transaction', icon: 'pi pi-dollar',
        //     items: [

        //         { label: 'QR Transaction', icon: 'pi pi-book', to: '/qr-transaction' },
        //         { label: 'RFID Transaction', icon: 'pi pi-book', to: '/rfid-transaction' },
        //         { label: 'Custom QR', icon: 'pi pi-book', to: '/custom-qr-transaction' },

        //     ]
        // },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [

        //         // { label: 'Analytics', icon: 'pi pi-chart-line', to: '/crm-analytics' },
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //         { label: 'Tasks', icon: 'pi pi-list', to: '/tasks' }
        //     ]
        // },
        // {
        //     label: 'Notifications', icon: 'pi pi-mobile',to: '/notifications'
        // },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "techops": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },

        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' }
            ]
        },

        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },

        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [
                { label: 'Inventory Overview', icon: 'pi pi-palette', to: '/inventory' },
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' }
            ]
        },
        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        {
            label: 'Performance', icon: 'pi pi-chart-bar', to: '/performance'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },

        // {
        //     label: 'Transaction', icon: 'pi pi-dollar',
        //     items: [

        //         { label: 'QR Transaction', icon: 'pi pi-book', to: '/qr-transaction' },
        //         { label: 'RFID Transaction', icon: 'pi pi-book', to: '/rfid-transaction' },
        //         { label: 'Custom QR', icon: 'pi pi-book', to: '/custom-qr-transaction' },

        //     ]
        // },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },
        // {
        //     label: 'CRM', icon: 'pi pi-sliders-h',
        //     items: [

        //         // { label: 'Analytics', icon: 'pi pi-chart-line', to: '/crm-analytics' },
        //         { label: 'Leads', icon: 'pi pi-users', to: '/leads' },
        //         { label: 'Tasks', icon: 'pi pi-list', to: '/tasks' }
        //     ]
        // },
        // {
        //     label: 'Notifications', icon: 'pi pi-mobile',to: '/notifications'
        // },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "superman": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Ticket Manager', icon: 'pi pi-ticket',
            items: [
                { label: 'Ticket List', icon: 'pi pi-file', to: '/ticket-manager' },
                { label: 'Ticket Analytics', icon: 'pi pi-chart-bar', to: '/ticket-analytics' },
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Performance', icon: 'pi pi-chart-bar', to: '/performance'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        {
            label: 'MIS Report', icon: 'pi pi-file-o', to: '/mis-report'
        },

        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        },
    ],

    "distributor": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "techqc": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'CVES', icon: 'pi pi-sliders-h', to: '/fgmachines' },
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },
        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' }
            ]
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "formulation": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' }
            ]
        },
        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "customerOne": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                // { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' }, 
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        // {
        //     label: 'Ticket Manager', icon: 'pi pi-ticket',
        //     items: [
        //         { label: 'Ticket List', icon: 'pi pi-file',to: '/ticket-manager'},
        //     ]   
        // },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },

        {
            label: 'Notifications', icon: 'pi pi-mobile', to: '/notifications'
        },
        {
            label: 'Account', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Support', icon: 'pi pi-cog', to: '/ticket-manager'
        },
        // {
        //     label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        // }
    ],

    "customerTwo": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                // { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' }, 
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        // {
        //     label: 'Ticket Manager', icon: 'pi pi-ticket',
        //     items: [
        //         { label: 'Ticket List', icon: 'pi pi-file',to: '/ticket-manager'},
        //     ]   
        // },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        // {
        //     label: 'Transactions', icon: 'pi pi-money-bill',to: '/transactions'
        // },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },

        {
            label: 'Notifications', icon: 'pi pi-mobile', to: '/notifications'
        },
        {
            label: 'Account', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Support', icon: 'pi pi-cog', to: '/ticket-manager'
        }
        // {
        //     label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        // }
    ],

    "customerThree": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                // { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' }, 
                // { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        // {
        //     label: 'Ticket Manager', icon: 'pi pi-ticket',
        //     items: [
        //         { label: 'Ticket List', icon: 'pi pi-file',to: '/ticket-manager'},
        //     ]   
        // },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },

        {
            label: 'Notifications', icon: 'pi pi-mobile', to: '/notifications'
        },
        {
            label: 'Account', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Support', icon: 'pi pi-cog', to: '/ticket-manager'
        }
        // {
        //     label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        // }
    ],

    "customerFour": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                // { label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                // { label: 'CVES Orders', icon: 'pi pi-briefcase', to: '/orders' }, 
                // { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Orders', icon: 'pi pi-shopping-cart',
            items: [
                { label: 'Refill Order', icon: 'pi pi-list', to: '/refill-orders' },
                { label: 'New CVES Order', icon: 'pi pi-folder', to: '/machine-orders' },
                { label: 'My Orders', icon: 'pi pi-user', to: '/my-order' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        // {
        //     label: 'Ticket Manager', icon: 'pi pi-ticket',
        //     items: [
        //         { label: 'Ticket List', icon: 'pi pi-file',to: '/ticket-manager'},
        //     ]   
        // },
        {
            label: 'Inventory', icon: 'pi pi-table', to: '/inventory'
        },
        // {
        //     label: 'Transactions', icon: 'pi pi-money-bill',to: '/transactions'
        // },
        {
            label: 'Billing', icon: 'pi pi-money-bill', to: '/billing'
        },

        {
            label: 'Notifications', icon: 'pi pi-mobile', to: '/notifications'
        },
        {
            label: 'Account', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Support', icon: 'pi pi-cog', to: '/ticket-manager'
        }
        // {
        //     label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        // }
    ],

    "production": [],
    "dealer": [],

    "finance": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' }
            ]
        },
        {
            label: 'Users', icon: 'pi pi-users', to: '/users'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'

        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "inventory": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' }
            ]
        },
        {
            label: 'Finished Goods', icon: 'pi pi-check-circle',
            items: [
                { label: 'Beverage', icon: 'pi pi-palette', to: '/fgbeverage' }
            ]
        },
        {
            label: 'Material Inventory', icon: 'pi pi-th-large',
            items: [

                { label: 'Inventory Overview', icon: 'pi pi-palette', to: '/inventory' },
                { label: 'Inventory Cycle', icon: 'pi pi-replay', to: '/inventory-cycle' },
            ]
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],
    "invenstor": [
        {
            label: 'Overview', icon: 'pi pi-home', to: '/overview'
        },
        // {
        //     label: 'CVES Manager', icon: 'pi pi-palette', to: '/cves-manager'
        // },
        {
            label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard'
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Support', icon: 'pi pi-cog', command: () => { window.location = "https://cheriseglobal.com/contactus" }, target: '_blank'
        }
    ],

    "facilityOne": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
    ],

    "facilityTwo": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
                { label: 'Employees', icon: 'pi pi-users', to: '/employees' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        }
    ],

    "facilityThree": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
        {
            label: 'Transactions', icon: 'pi pi-money-bill', to: '/transactions'
        },
    ],

    "facilityFour": [
        {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
                { label: 'Overview', icon: 'pi pi-eye', to: '/overview' },
                { label: 'CVES On-Board', icon: 'pi pi-briefcase', to: '/cves-onboard' },
            ]
        },
        {
            label: 'Analytics', icon: 'pi pi-chart-line', to: '/analytics'
        },
    ],
    "else": [

    ]

}

