import React, {useEffect,useState} from 'react';


export const SubAnalytics = (props) =>{

    // console.log(props.value);
    const [loadingTime, setLoadingTime] = useState(true);


    useEffect(()=>{
        setTimeout(()=>{
            setLoadingTime(false)
        }, 3000);
       
    },[])



    return (

            <div className="p-col-12 p-md-4">
            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                {props.header}
                </span>
                <div className="p-d-flex p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        <div className="overview-text">
                            {
                                loadingTime ?
                                <b><i className="pi pi-spin pi-spinner" /></b>:
                                <b>{props.value}</b>
                            }
                        </div>                               
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>
            </div>
    )

}