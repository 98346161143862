import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
// Common
import { successToast, failureToast } from "../Common/CommonFunctions";
import { KettleProducts } from "./KettleProducts";

import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ===============================================================================================
// ================================= Kettle User Form ============================================
// ===============================================================================================

export const KettleUserForm = (props) =>{

    const appOptions = {
        headers: {
            "Authorization" : props.verifiedUserData?.token,
            "Content-type": "application/json"   }
    };

    const payloadData = {
        "country_code":"+91",
        "contact":"",
        "prefix": "Mr.",
        "name": '',
        "last_name":'',
        "shipping_address":"",
        "shipping_state":"",
        "shipping_city": '',
        "shipping_pincode":"",
        "business_name": '',
        "business_address": '',
        "business_state": '',
        "business_city":"",
        "business_pincode": '',
        "business_registration": '',
        "gst": '',
        "fsaai": '',
        "is_power": false,
        "is_space": false,
        "is_water": false,
        "business_lat": '',
        "business_lng": '',
        "address_lat": '',
        "address_lng": '',
        "user_id": '',
        "user_image": "",
        "proof_front":"",
        "proof_back" : "",
        "proof_store" :""
    }

    const countryOption = [
        {name : 'India'}
    ]

    const prefixOptions = [
        { name : 'Mr.'},
        { name : 'Mrs.'}
    ]

    // const cityData = [
    //     { name : 'Mumbai'},
    //     { name : 'Pune'},
    //     { name : 'Goa'},
    //     { name : 'Kolkata'},
    //     { name : 'Delhi'},
    //     { name : 'Hyderabad'},
    //     { name : 'Banglore'},
    // ]

    const [openKettleFormDialog, setOpenKettleFormDialog] = useState(false)
    const [isProceeded,setIsProceeded] = useState(false);
    const [proceedPayload,setProceedPayload] = useState(payloadData);
    // const [selectedCity,setSelectedCity] = useState(null);
    const [isRegistered,setIsRegistered] = useState(false);
    const [registeredUser,setRegisteredUser] = useState(null);
    const [bussinessPincode,setBussinessPincode] = useState('');
    const [states, setStates] = useState(null)
    const [selectedStates, setSelectedStates] = useState(null)
    const [cities, setCities] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [shippingPincode,setShippingPincode] = useState('');
    const [selectedPrefix,setSelectedPrefix] = useState(prefixOptions[0]);
    const [selectedBStates,setSelectedBStates] = useState(null);
    const [bussCities,setBussCities] = useState(null);
    const [selectedBCity, setSelectedBCity] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [packetRateAfterGST, setPacketRateAfterGST] = useState(null);
    const [cupRate, setCupRate] = useState(null);

    const toast = useRef(null);

    useEffect(()=>{
        setOpenKettleFormDialog(props.openKettleFormDialog);
    },[props.openKettleFormDialog])

    useEffect(() => {
        console.log(props.verifiedUserData)
    }, [props.verifiedUserData])

    useEffect(() => {
        getInitiate()
    }, [])

    const getInitiate = async() =>{
        const states = await apiServices.getState({country_id: '101'})
        setStates(states?.data)
    }

    const getCity = async(data) =>{
        console.log(data);
        const city = await apiServices.getCity({state_id: data?.id})
        setCities(city?.data)
    }

    const getBussCity = async(data) =>{
        console.log(data);
        const city = await apiServices.getCity({state_id: data?.id})
        setBussCities(city?.data)
    }

    const proceedKettleOrder = () =>{
        setIsProceeded(true)
        setOpenKettleFormDialog(false)
    }

    const changeKettle = (e) =>{

        const name = e.target.name;

        if(name == 'name' || name == 'last_name' || name == 'shipping_address' || name == 'business_name' || name == 'business_address' || name == 'business_registration'
            || name == 'gst' || name == 'fsaai' || name == 'business_pincode'){
            setProceedPayload({...proceedPayload, [name] : e.target.value})
        }

        if(name == 'prefix'){
            setSelectedPrefix(e.value)
            setProceedPayload({...proceedPayload, [name] : e.value.name})
        }
        
        if(name == 'shipping_state'){
            console.log(e.value);
            setSelectedStates(e.value)
            setProceedPayload({...proceedPayload, [name] : e.value.name})
            getCity(e.value)
        }

        if(name == 'business_state'){
            console.log(e.value);
            setSelectedBStates(e.value)
            setProceedPayload({...proceedPayload, [name] : e.value.name})
            getBussCity(e.value)
        }

        if(name == 'business_city'){
            setSelectedBCity(e.value)
            setProceedPayload({...proceedPayload, [name] : e.value.name})
        }
        
        if(name == 'business_pincode'){
            const isValid = isNaN(Number(e.target.value));
            if(!isValid){
                const trimmed = e.target.value?.trim();
                setBussinessPincode(trimmed)
                setProceedPayload({...proceedPayload, [name] : trimmed})
            }
        }

        if(name == 'shipping_pincode'){
            const isValid = isNaN(Number(e.target.value));
            if(!isValid){
                const trimmed = e.target.value?.trim();
                setShippingPincode(trimmed)
                setProceedPayload({...proceedPayload, [name] : trimmed})
            }
        }

        if(name == 'shipping_city'){
            setSelectedCity(e.value)
            setProceedPayload({...proceedPayload, [name] : e.value.name})
        }

        if(name == 'is_power' || name == 'is_space' || name == 'is_water' || name == 'is_earthing' ){
            setProceedPayload({...proceedPayload, [name] : e.checked})
        }

        if(name == 'cup_price'){

            if(e.target.value){   
                const calculatedGST = Number(e.target.value) * 18 / 100;
                const totalGstIncluded = Number(calculatedGST) + Number(e.target.value);
                setPacketRateAfterGST(totalGstIncluded.toFixed(2))
                const cupRate = Number(e.target.value) / 50;
                setCupRate(cupRate.toFixed(2));
                setProceedPayload({...proceedPayload, [name] : Number(cupRate.toFixed(2))})

            }else{
                setPacketRateAfterGST(null)
                setCupRate(null)
            }

        }
    }


    const changeFiles = (e) =>{
        

        if(e.target.name == 'user_image'){
            const image_as_files = e.target.files;
            console.log(image_as_files);
            setProceedPayload({...proceedPayload, [e.target.name] : image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('keProfile').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('keProfile').src = 'assets/demo/images/user/default-user.png';
        }

        if(e.target.name == 'proof_front'){
            const image_as_files = e.target.files;
            console.log(image_as_files);
            setProceedPayload({...proceedPayload, [e.target.name] : image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('addhar-Front').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('addhar-Front').src = 'assets/demo/images/user/id-front.png';
        }

        if(e.target.name == 'proof_back'){
            const image_as_files = e.target.files;
            setProceedPayload({...proceedPayload, [e.target.name] : image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('addhar-back').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('addhar-back').src = 'assets/demo/images/user/id-back.png';
        }

        if(e.target.name == 'proof_store'){
            const image_as_files = e.target.files;
            console.log(image_as_files?.length);
            setProceedPayload({...proceedPayload, [e.target.name] : image_as_files?.length > 0 ? [image_as_files] : "" })
            image_as_files?.length > 0 ? document.getElementById('store-photo').src = window?.URL.createObjectURL(e.target.files[0]) : document.getElementById('store-photo').src = 'assets/demo/images/user/default-store.png';
        }
    }

    const submitKettleForm = async(e) =>{

        setIsClicked(true)

        e.preventDefault()

        const payloadData = {
            ...proceedPayload,
            "business_lat": props.latLong?.currentLatitude || '18.6013908',
            "business_lng": props.latLong?.currentLongitude || '74.0058594',
            "address_lat": props.latLong?.currentLatitude || '18.6013908',
            "address_lng": props.latLong?.currentLongitude || '74.0058594', 
            "user_id" : props.verifiedUserData?.id || initialValue?.app_data?.id,
            "contact" : props.verifiedUserData?.contact
        };

        console.log(payloadData);

        const payload = new FormData();
        Object.entries(payloadData).forEach(([key, value]) => { payload.append(key, value)});

        if(payloadData?.proof_front){

            payloadData?.proof_front?.forEach((x, i)=>{
                for (let index = 0; index < x.length; index++) {
                    payload.append( "proof_front", x[index], x[index]?.name);
                }
            })
        }
        
        if(payloadData?.proof_back){

            payloadData?.proof_back?.forEach((x, i)=>{
                for (let index = 0; index < x.length; index++) {
                    payload.append( "proof_back", x[index], x[index]?.name);
                }
            })
        }

        if(payloadData?.proof_store){

            payloadData?.proof_store?.forEach((x, i)=>{
                for (let index = 0; index < x.length; index++) {
                    payload.append( "proof_store", x[index], x[index]?.name);
                }
            })
        }

        if(payloadData?.user_image){

            payloadData?.user_image?.forEach((x, i)=>{
                for (let index = 0; index < x.length; index++) {
                    payload.append( "user_image", x[index], x[index]?.name);
                }
            })
        }
        
        console.log(payload);

        const response = await apiServices.saveKettleStore(payload);
        if(response?.data?.response_code == 1){
            successToast(toast, 'User Registered Successfully');
            onSuccess(response?.data?.response_obj)
            hideKettleDialog()
        }else{
            failureToast(toast,'Failed to Add');
            setIsClicked(false)
        }
    }

    const onSuccess = (data) =>{
        console.log(data);
        setIsRegistered(true)
        setOpenKettleFormDialog(false)
        props.onSubmitKettleReg()
        setRegisteredUser(data)
    }

    const hideKettleDialog = () =>{
        setOpenKettleFormDialog(false)
        setSelectedCity(null)
        setBussinessPincode('')
        setProceedPayload(payloadData)
        setSelectedStates(null)
        setCities(null)
        setShippingPincode('');
        setSelectedPrefix(prefixOptions[0]);
        setSelectedBStates(null);
        setBussCities(null);
        setSelectedBCity(null);
        setIsClicked(false);
        setPacketRateAfterGST(null);
        setCupRate(null)
    }

    const selectionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideKettleDialog}/>
            {
                (proceedPayload?.user_image?.length > 0 && proceedPayload?.proof_front?.length > 0 && proceedPayload?.proof_back?.length > 0 && proceedPayload?.proof_store?.length > 0) &&
                <Button label="Submit" icon={isClicked ? 'pi pi-spin pi-spinner' : 'pi pi-check'} type='submit' form="kettle-form" className="p-button-success p-mr-2 p-mb-2" disabled={isClicked}  /> 
            }
        </>
    );

    return (
        <>
            <Toast ref={toast} position="center" />

            {
                !isRegistered &&
                <Dialog visible={openKettleFormDialog} style={{ width: '1000px' }} header={`Tapri Kettle Order (+91 ${props.verifiedUserData?.contact || initialValue?.cno})`} modal footer={selectionDialogFooter} onHide={hideKettleDialog} className='p-fluid' blockScroll={true}>
                    <form onSubmit={submitKettleForm} encType="multipart/form-data" id="kettle-form">
                        <h5>Personal Information</h5>
                        <div className="p-grid">  
                            
                            <div className="p-col-6 p-md-2">
                                <div className="p-field">
                                    <label htmlFor="prefix">Prefix</label>
                                    <Dropdown id="prefix" name="prefix" value={selectedPrefix} options={prefixOptions} optionLabel="name" placeholder="Select Prefix" onChange={changeKettle} />
                                </div>
                            </div>
                            <div className="p-col-4 p-md-4">
                                <div className="p-field">
                                    <label htmlFor="name">First Name</label>
                                    <InputText id="name" name="name" placeholder="First Name" onChange={changeKettle} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4 p-md-4">
                                <div className="p-field">
                                    <label htmlFor="last_name">Last Name</label>
                                    <InputText id="last_name" name="last_name" placeholder="Last Name" onChange={changeKettle} required />
                                </div>
                            </div>

                            <div className="p-col-4 p-md-2">
                            <center>
                                <div className="user-detail-wrapper">
                                <div className="user-detail-content">
                                    <label htmlFor="kettle-profile">
                                        <img id="keProfile" src={'assets/demo/images/user/default-user.png'} alt="Profile" className="kettle-profile-image"/>
                                    </label>
                                    <InputText id="kettle-profile" type="file" name="user_image" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{display:'none'}} />
                                </div>
                                </div>
                            </center>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="add">Shipping Address</label>
                                    <InputText id="add" name="shipping_address" placeholder="Address" onChange={changeKettle} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="country">Country</label>
                                    <Dropdown id="country" name="country" value={countryOption[0]} options={countryOption} optionLabel="name" placeholder="Select Country" disabled />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="state">State</label>
                                    <Dropdown id="state" name="shipping_state" value={selectedStates} onChange={changeKettle} options={states} optionLabel="name" placeholder="Select State" filter required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="city">City</label>
                                    <Dropdown id="city" name="shipping_city" value={selectedCity} onChange={changeKettle} options={cities} optionLabel="name" placeholder="Select City"  filter required />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="pincode">Pincode</label>
                                    <InputText id="pincode" name="shipping_pincode" placeholder="Pincode" minLength={6} maxLength={6} value={shippingPincode ? shippingPincode : ''} onChange={changeKettle} required/>
                                </div>
                            </div>
                        </div>
                       
                        <h5>BUSINESS INFORMATION</h5>
                        <div className="p-grid">                        
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="bname">Bussiness Name</label>
                                    <InputText id="bname" name="business_name" placeholder="Bussiness Name" onChange={changeKettle} required/>
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="badd">Address</label>
                                    <InputText id="badd" name="business_address" placeholder="Address" onChange={changeKettle} required/>
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="state">State</label>
                                    <Dropdown id="state" name="business_state" value={selectedBStates} onChange={changeKettle} options={states} optionLabel="name" placeholder="Select State" filter required />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="city">City</label>
                                    <Dropdown id="city" name="business_city" value={selectedBCity} onChange={changeKettle} options={bussCities} optionLabel="name" placeholder="Select City" filter required />
                                </div>
                            </div>

                            {/*  */}

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="pincode">Pincode</label>
                                    <InputText id="pincode" name="business_pincode" placeholder="Pincode" minLength={6} maxLength={6} value={bussinessPincode ? bussinessPincode : ''} onChange={changeKettle} required/>
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="bregNum">Business Registration Number</label>
                                    <InputText id="bregNum" name="business_registration" placeholder="Business Registration Number" onChange={changeKettle} />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="gstRegNum">GST Number <small className="p-error">(example : 18AABCU9603R1ZM) </small></label>
                                    <InputText id="gstRegNum" name="gst" placeholder="GST Registration Number" minLength={15} maxLength={15} pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" onChange={changeKettle} />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="fssaiReg">FSSAI Registration</label>
                                    <InputText id="fssaiReg" name="fsaai" placeholder="FSSAI Registration" onChange={changeKettle} />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="cup_prices">Packet Rate (without GST)</label>
                                    <InputText id="cup_prices" type="number" name="cup_price" min={240} max={999} placeholder="Packet Rate (without GST)" onChange={changeKettle} required/>
                                </div>
                            </div>

                            {
                                packetRateAfterGST &&
                                <>  
                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="packetwithGSt">Packet Rate (with GST)</label>
                                            <InputText id="packetwithGSt" placeholder="Packet Rate (with GST)" value={packetRateAfterGST} disabled />
                                        </div>
                                    </div>

                                    <div className="p-col-4">
                                        <div className="p-field">
                                            <label htmlFor="cupRate">Cup Rate</label>
                                            <InputText id="cupRate" placeholder="Cup Rate" value={cupRate} disabled />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                        
                        <h5>INSTALLATION CHECKLIST</h5>
                        <div className="flex justify-content-center">
                            <div className="flex flex-column gap-3">
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_power" checked={proceedPayload?.is_power} onChange={changeKettle} />
                                    <label  className="p-ml-2">16A Power Plug & Supply available</label>
                                </div> 
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_space" checked={proceedPayload?.is_space} onChange={changeKettle} />
                                    <label  className="p-ml-2">Space for Tapri Kettle (10 in * 10 in) available</label>
                                </div> 
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_water" checked={proceedPayload?.is_water} onChange={changeKettle} />
                                    <label  className="p-ml-2">20L water bottle available</label>
                                </div> 
                                <div className="flex align-items-center">
                                    <Checkbox className="p-mt-4" name="is_earthing" checked={proceedPayload?.is_earthing} onChange={changeKettle} />
                                    <label  className="p-ml-2">Earthing available</label>
                                </div> 
                            </div>
                        </div>

                        <h6>AADHAR CARD </h6>
                        <div className="p-grid">  
                            <div className="p-col-6">
                                <div className="p-field">
                                    {/* <label htmlFor="adhf">Aadhar Card (Front)</label> */}
                                    <InputText id="adhf" name="proof_front" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{display:'none'}} required/>
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    {/* <label htmlFor="adhb">Aadhar Card (back)</label> */}
                                    <InputText id="adhb" name="proof_back" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{display:'none'}} required/>
                                </div>
                            </div>

                            <div className="p-col-6">
                                <label htmlFor="adhf">
                                    <img id="addhar-Front" src={'assets/demo/images/user/id-front.png'} alt="Aadhar Card (Front)" style={{height:'230px', width:'100%'}} />
                                </label>
                            </div>

                            <div className="p-col-6">
                                <label htmlFor="adhb">
                                    <img id="addhar-back" src={'assets/demo/images/user/id-back.png'} alt="Aadhar Card (Back)" style={{height:'230px', width:'100%'}} />
                                </label>
                            </div>

                        </div>

                        <h6>STORE PHOTO </h6>
                        <div className="p-grid">  
                            <div className="p-col-7">
                                <div className="p-field">
                                    {/* <label>Store Photo</label> */}
                                    <InputText id="storePhoto" name="proof_store" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={changeFiles} style={{display:'none'}} required/>
                                </div>
                            </div>
                            <div className="p-col-7">
                                <label htmlFor="storePhoto">
                                    <img id="store-photo" src={'assets/demo/images/user/default-store.png'} alt="Store-Img" style={{height:'230px', width:'100%'}} />
                                </label>
                            </div>
                        </div>

                    </form>
                </Dialog>
            }

            {
                isRegistered && <KettleProducts setIsRegistered={setIsRegistered} registeredUser={registeredUser} latLong={props.latLong} setIsProceeded={props.setIsProceeded} />
            }

        </>
    )
}