import React, {useState,useEffect} from "react";
import { SubAnalyticalStat } from "./SubAnalyticalStat";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
// Common
import { CommonBodyForTable, srBodyTemplate } from "../Common/CommonComponents";
// Services
import io from "socket.io-client";
import ApiServices from "../../service/api/ApiServices";
import { managementCheck } from "../../service/admin/superadmin";
import { getFormattedAmount, getFormattedStatForAnalytics } from "../Common/CommonFunctions";

const apiServices = new ApiServices()
const socket = io(process.env.REACT_APP_IOT);

// Logged User Information
const initialValue = JSON.parse(localStorage.getItem("user"));

// ==============================================================================
// ==============================================================================

export const AnalyticalStat = (props) =>{

    const [DemoLiveData,setDemoLiveData] = useState([])
    const [flavourChartData,setFlavourChartData] = useState([])
    const [flavourData,setFlavourData] = useState([])
    const [flavourNameData,setFlavourNameData] = useState([])
    const [flavourColorData,setFlavourColorData] = useState([])
    const [userStatsTemp, setUserStatsTemp] = useState([])
    const [loading, setLoading] = useState(true)
    const [superstarValue, setSuperstarValue] = useState(null);
    const [CTVMDemoData,setCTVMDemoData] = useState({machineCount:'-',cupConsumption:'-'})
    const [totalCupConsumption, setTotalCupConsumption] = useState('');
    const [organicCupConsumption, setOrganicCupConsumption] = useState('');
    const [inorganicCupConsumption, setInorganicCupConsumption] = useState('');
    const [staticOrganicCupConsumption, setStaticOrganicCupConsumption] = useState('');
    const [inorganicMachineCount, setInorganicMachineCount] = useState('');
    const [timeSlot,setTimeSlot] = useState('this_month')
    const [throttleValue,setThrottleValue] = useState(true)
    const [filteredDate,setFilteredDate] = useState(null)
    const [singleMachineConsumption,setSingleMachineConsumption] = useState(null)
    const [singleMachine,setSingleMachine] = useState(false)
    const [machineArr, setMachineArr] = useState([])
    const [viewMachinesDialog,setViewMachinesDialog] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(()=>{
        getData(props.userStatParam)
    },[props.userStatParam])

    useEffect(()=>{
        getdate(props.filteredDate)
    },[props.filteredDate])

    useEffect(()=>{
        setMachineArr(props.allMachineData)
    },[props.allMachineData])


    const getdate = (data) =>{
        if(data){
            const startDate = new Date(data?.startDate);
            const endDate = new Date(data?.endDate);
            const finalDate = {startDate : startDate.toLocaleDateString() , endDate : endDate.toLocaleDateString()}
            setFilteredDate(finalDate)
        }
    }


    const getData = (data) =>{
        console.log(props.isKettleSelectedForStat);
        console.log(data);
        if(data){
            setLoading(true)
            const { params, time } = data;
            if(props.isKettleSelectedForStat){
                getUserStaticStats(params)
            }else{
                setTimeSlot(time)
                const liveTimes = ['all', 'today', 'this_week', 'this_month'];
                const pastTimes = ['yesterday', 'last_month', 'custom'];

                if(liveTimes.includes(time)){
                    if(params?.id?.length == 1){
                        setSingleMachine(true)
                        getMachineLiveData(params)
                    }else{
                        setSingleMachine(false)
                        getUserStats(params)
                    }
                }else if(pastTimes.includes(time)) {
                    getUserStaticStats(params)
                }
            }
        }
    }

    // const getData = (data) =>{
    //     console.log(props.isKettleSelectedForStat);
    //     console.log(data);
    //     if(data){
    //         setLoading(true)
    //         if(props.isKettleSelectedForStat){
    //             getUserStaticStats(data.params)
    //         }else{
    //             setTimeSlot(data.time)
    //             if((data.time == 'all'|| data.time == 'today' || data.time == 'this_week' || data.time == 'this_month')){
    //                 if(data.params?.id?.length == 1){
    //                     setSingleMachine(true)
    //                     getMachineLiveData(data.params)
    //                 }else{
    //                     setSingleMachine(false)
    //                     getUserStats(data.params)
    //                 }
    //             }else if((data.time == 'yesterday' || data.time == 'last_month' || data.time == 'custom')) {
    //                 getUserStaticStats(data.params)
    //             }   
    //         }   
    //     }
    // }

    // const getUserStaticStats = async(data) =>{
    //     console.log('one');
    //     const response1 = await apiServices.getMaterialType();
    //     const response = await apiServices.getUserStats(data);
    //     const totalConsumption =  getCommonOps(response1?.data?.mate,response?.data?.data);
    //     console.log(totalConsumption);
    //     setStaticOrganicCupConsumption(totalConsumption)
    //     setLoading(false)
    // }

    const getUserStaticStats = async (data) => {
        try {
            console.log('one');
            const [materialTypeResponse, userStatsResponse] = await Promise.all([ apiServices.getMaterialType(), apiServices.getUserStats(data)]);
            const totalConsumption = getCommonOps( materialTypeResponse?.data?.mate, userStatsResponse?.data?.data );
            console.log(totalConsumption);
            setStaticOrganicCupConsumption(totalConsumption);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getUserStats = async(data) =>{
        if(data){

            const response1 = await apiServices.getMaterialType();
            const response = await apiServices.getUserStats(data);
            const totalConsumption =  getCommonOps(response1?.data?.mate,response?.data?.data);
            console.log(totalConsumption);
            setOrganicCupConsumption(totalConsumption)
            inorganicCal([],totalConsumption)
            const Livearr = [];
            socket.on("consumption", data1 => {
                if(machineArr?.includes(data1?.data?.data?.id)){
                    Livearr.push(data1)
                    setDemoLiveData(Livearr)
                    inorganicCal(Livearr,totalConsumption)    
                }
            });

            setLoading(false)
        }
    }  

    const getMachineLiveData = async(data) =>{
        console.log("============Single Machine Live Data ====================");

        const response1 = await apiServices.getMaterialType();
        var response = await apiServices.getUserStats(data);
        const totalConsumption =  getCommonOps(response1?.data?.mate,response?.data?.data);
        console.log(totalConsumption);
        setSingleMachineConsumption(totalConsumption)
        const Livearr = [];
        socket.on("consumption", data1 => {
            console.log({'New Cup Consumption on ' : data1?.data?.data?.id});
            if(data1?.data?.data?.id == data?.id[0]){
                console.log(data1?.data?.data?.id)
                Livearr.push(data1)
                setDemoLiveData(Livearr)
                setSingleMachineConsumption(totalConsumption + Livearr.length)
            }
        });

        setLoading(false)
    }

    const getCommonOps = (data1,data2) =>{
        const arr = [];
        data1?.forEach((element) => {
            const materialName = element.materialName;
            data2?.brvStats.map((x) => {        
                if(materialName == x._id){
                    arr.push({...x,color:element.color}) 
                }
              });
        });

        arr.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));
        let result = arr.map(a => a.totalCup);
        let resultedNames = arr.map(a => a._id);
        let resultedColors = arr.map(a => a.color);
    
        setFlavourChartData(arr)
        setFlavourData(result)
        setFlavourNameData(resultedNames)
        setFlavourColorData(resultedColors)
        setUserStatsTemp(arr)

        let superStarCal = data2?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        let superStarCalc = superStarCal?superStarCal[0]:null;
        setSuperstarValue(superStarCalc)
        let totalConsumption = data2?.usagesStats[0]?.totalCup;
        console.log(totalConsumption)
        setLoading(false)
        return totalConsumption;
    }

    const inorganicCal = (Livearr,totalConsumption) =>{

        console.log("===============Inorganic Calculation=============");
        console.log(totalConsumption);
        const date1 = new Date('3/27/2022');
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        const machinesCount = 512+diffDays;
        console.log(machinesCount);
        setInorganicMachineCount(machinesCount)

        let organicCups = totalConsumption + Livearr.length ;
        let NoofCups = 62;
        let NoOfMachines = machinesCount;
        let DaysPerMonth = 25;
        let NoOfMonths = 18;

        console.log(organicCups);
        setOrganicCupConsumption(organicCups)

        const inorganic = (NoofCups * NoOfMachines * DaysPerMonth * NoOfMonths) + organicCups;
        // console.log(inorganic);
        setInorganicCupConsumption(inorganic)
        setCTVMDemoData({machineCount:machinesCount?machinesCount:'-',cupConsumption: inorganic?inorganic:'-'})
        setTotalCupConsumption(totalConsumption + Livearr.length)
    }
    
    const viewMachines = (data) =>{
        if(!managementCheck()){
            setViewMachinesDialog(true)
            console.log(data);
        }
    }

    const addBodyForTable = (data, props) =>( <> <span className="p-column-title">{props.header}</span>{data?.mchLocation?.addr ? data?.mchLocation?.addr : 'NA'} </> )
    const brandBodyForTable = (data, props) =>{
        const brandLookup = { 'TAPRI': 'Tapri Kiosk','TKAFFE': 'Tkaffe Kiosk', 'MERI_TAPRI': 'Tapri Kettle'};
        return ( <> <span className="p-column-title">{props.header}</span>{brandLookup[data[props.field]] || ''} </> )
    }

    const viewMachineFooter = () =>( <button className="p-button p-button p-button-danger" onClick={()=>{setViewMachinesDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Close</span><span className="p-ink"></span></button> )

    const header = (
            <div className="p-grid">
                <div className="p-col-8"></div>
                <div className="p-col-4">
                    <div className="table-header">
                        <span className="p-input-icon-right">
                            <div className="p-inputgroup p-m-1">
                                <Button className='custom-group-btn' icon="pi pi-search" />
                                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                            </div>
                        </span>        
                    </div>
                </div>
        </div>
    );


    return (
        <>
            {
                props.filter != 'meri_tapri' ?

                <div className="p-grid p-my-5">

                {
                    <>
                        {
                            !props.customerValue && 

                            <div className="p-col-12 p-md-4">
                            <div className="card widget-overview-box widget-overview-box-1" onClick={()=>{viewMachines(props.machinesForCount)}} style={{cursor:'pointer'}}>
                                <span className="overview-title" >
                                {'No. of Onboarded CVES'}
                                </span>
                                <div className="p-jc-between">
                                    <div className="overview-detail p-d-flex p-jc-between">
                                        <div className="overview-text">
                                            {
                                                loading ?
                                                <b><i className="pi pi-spin pi-spinner" /></b>:
                                                <b>{props.allMachineCount?.length ? props.allMachineCount?.length : '-'}</b>
                                            }
                                        </div>                               
                                    </div>
                                </div>
                                <i className={`pi pi-bars side-icon`} style={{ fontSize: '2rem' }}></i>
                            </div>
                            </div>
                        }

                        
                        {/* {
                            (!props.customerValue) &&
                            <SubAnalyticalStat icon="pi pi-bars" header="No. of CTVM" value={props.allMachineCount?.length ? props.allMachineCount?.length : '-'} loading={loading} />
                        } */}

                        {
                            props.customerValue &&
                            <SubAnalyticalStat  header="Company Name" value={props.customerValue ? props.customerValue : '-'} loading={loading} />
                        }

                        <SubAnalyticalStat icon="pi pi-star" header="SuperStar" value={superstarValue?._id ? superstarValue?._id : '-'} loading={loading}/>

                        {
                            ((!singleMachine && (timeSlot == 'all' || timeSlot == 'today' || timeSlot == 'this_week' || timeSlot == 'this_month') && !props.isKettleSelectedForStat)) &&
                            <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption (Live)" value={organicCupConsumption ? getFormattedStatForAnalytics(organicCupConsumption) : '-'} loading={loading} />
                        }

                        {
                            ((!singleMachine && (timeSlot == 'yesterday'|| timeSlot == 'last_month')) || props.isKettleSelectedForStat) && 
                            <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption" value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={loading} />
                        }

                        {
                            (!singleMachine && (timeSlot == 'custom')) &&
                            <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption (${filteredDate?.startDate} - ${filteredDate.endDate})`} value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={loading} />
                        }

                        {
                            (singleMachine && (timeSlot == 'all' || timeSlot == 'today' || timeSlot == 'this_week' || timeSlot == 'this_month')) &&
                            <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption By CVES (Live)" value={singleMachineConsumption ? getFormattedStatForAnalytics(singleMachineConsumption)  : '-'} loading={loading} />
                        }

                        {
                            (singleMachine && (timeSlot == 'yesterday'|| timeSlot == 'last_month')) &&
                            <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption" value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={loading} />
                        }

                        {
                            (singleMachine && (timeSlot == 'custom')) &&
                            <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption (${filteredDate?.startDate} - ${filteredDate.endDate})`} value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={loading} />
                        }

                        {
                            (props.onlineData && initialValue?.userType != "customer") &&
                            <>
                                <SubAnalyticalStat icon="pi pi-calendar" header='RFID' value={props.onlineData?.online ? props.onlineData?.online : 0} />
                                <SubAnalyticalStat icon="pi pi-calendar" header='Paytm' value={props.onlineData?.paytm ? props.onlineData?.paytm : 0} />
                            </>
                        }

                    </>
                }

                </div> 
                :
                <div className="p-grid p-my-5">
                    
                    {
                        props.selectedkettle ? 
                        <SubAnalyticalStat icon="pi pi-bars" header="Site Name" value={props.selectedkettle ? props.selectedkettle : 'NA'} />
                        :
                        <SubAnalyticalStat icon="pi pi-bars" header="No. of Tapri Kettles's" value={props.totalMachineCount?.length ? props.totalMachineCount?.length : '-'} />
                    }

                    <SubAnalyticalStat icon="pi pi-star" header="SuperStar" value={props.tapriSuperstar ? props.tapriSuperstar : '-'} />
                    <SubAnalyticalStat icon="pi pi-bars" header="Total Consumption" value={props.totalTapriConsumption ? props.totalTapriConsumption : 0} />
                </div>

                }

                <Dialog visible={viewMachinesDialog} style={{ width: '1150px' }} header={`${props.machineselectValue?.displayLabel} CVES Details`} footer={viewMachineFooter}  onHide={()=>{setViewMachinesDialog(false)}} modal className="p-fluid" maximizable={true} blockScroll={true}>
                    <div className="card">
                        <div className="p-grid table-demo p-m-2">
                            <DataTable value={props.machinesForCount} className="p-datatable-customers" dataKey="id" rowHover emptyMessage="No Data found." 
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            globalFilter={globalFilter} header={header}
                                exportFilename={`CVES Details`}>
                                <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                <Column field="id" header="CVES id" sortable body={CommonBodyForTable} ></Column>
                                <Column field="subid" header="Sub id" sortable body={CommonBodyForTable} ></Column>
                                {/* <Column field="app_cat" header="Category" sortable body={CommonBodyForTable} ></Column> */}
                                <Column field="brand" header="Brand" sortable body={brandBodyForTable} ></Column>
                                <Column field="addr" header="Location" sortable body={addBodyForTable} ></Column>
                                <Column field="siteName" header="Site Name" sortable body={CommonBodyForTable} ></Column>
                            </DataTable>
                        </div>
                    </div>
                </Dialog> 

        </>
    )
}


