import React, {useEffect,useState} from 'react';

import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DemoMISStats } from './DemoMISStats';
import { managementCheck } from '../../service/admin/superadmin';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices =  new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const ENDPOINT = process.env.REACT_APP_IOT;

export const DemoMISReport = (props) =>{


    const [salesStat, setSalesStat] = useState([]);
    const [superstarAcc, setSuperstarAcc] = useState([]);
    const [demomachineCount, setDemomachineCount] = useState('-');
    const [demoCupCount, setDemoCupCount] = useState('-');
    const [timeWiseData, setTimeWiseData] = useState();
    const [hourWiseData, setHourWiseData] = useState();

    const [nineToTwoData, setNineToTwoData] = useState();
    const [twoToSevenData, setTwoToSevenData] = useState();
    const [sevenToTwelveData, setSevenToTwelveData] = useState();
    const [twelveToNineData, setTwelveToNineData] = useState();
    
    const [weekdaysData, setWeekdaysData] = useState();
    const [weekendData, setWeekendData] = useState();
    const [morningData, setMorningData] = useState();
    const [eveningData, setEveningData] = useState();
    const [beverageWiseDemoData, setBeverageWiseDemoData] = useState();
    const [destributorWiseDemoData, setDestributorWiseDemoData] = useState();
    const [onboardMachines, setOnboardMachines] = useState(null);
    const [allMachines, setAllMachines] = useState(null);
    const [totalCupCount, setTotalCupCount] = useState(null);
    const [ioStats, setIoStats] = useState(null);
    const [iOLoading, setIOLoading] = useState(true);
    

    const machineTypeValues = [
        { name: 'CTVM' },
        { name: 'Bulk' }
    ];

    const AccountTypeValues = [
        { name: 'Account' },
        { name: 'Facility' },
        { name: 'Subscription' }
    ];

    const AccountValues = [
        {name:'All'},
        {name:'Kanakia Spaces Realty Pvt Ltd'},
        {name:'Tapcapital pvt ltd'},
        {name:'Sokrati Technology Pvt Ltd'},
        {name:'jain Construction'},
    ]

    const multiselectValues = [
        { name: 'TILL DATE' },
        { name: 'TODAY' },
        { name: 'YESTERDAY' },
        { name: 'THIS WEEK' },
        { name: 'THIS MONTH' },
        { name: 'LAST MONTH' },
        { name: 'CUSTOM' }
    ];

    const timeWiseChart = {
        labels: ['Weekdays', 'Weekends', 'Morning', 'Evening'],
        datasets: [
            {
                // data: timeWiseData,
                data:  [12476909, 3119228, 10917296, 4678841],
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#EC4DBC',
                    '#EEE500'
                ],
                hoverBackgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#EC4DBC',
                    '#EEE500'
                ],
                borderColor: 'transparent',
                fill: true
            }
        ]
    };
    
    
    const hourWiseChart = {
        labels: ['9AM to 2PM', '2PM to 7PM', '7PM to 12AM', '12AM to 9AM'],
        datasets: [
            {
                data: hourWiseData,
                backgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                hoverBackgroundColor: [
                    '#0F8BFD',
                    '#FC6161',
                    '#00D0DE',
                    '#873EFE'
                ],
                borderColor: 'transparent',
                fill: true
            }
        ]
    };
    
    const timeWiseChartOptions = {
        responsive: true
    };

    

    const basicData = {

        labels: [
            'MUMBAI MASALA CHAI',
            'DELHI SPECIAL CHAI',
            'GUJARATI HALDI DOODH',
            'MILANO CAPPUCCINO',
            'HYDERABADI TOMATO SOUP',
            'BIKANERI ELAICHI DOODH',
            'JODHPURI MASALA CHAI',
            'NAGPURI NIMBU CHAI',
            'PUNERI GAUTI CHAI',
            'DELHI ADHRAK CHAI',
            'MADRAS COFFEE',
            'CALCUTTA ELAICHI CHAI',
            'COLUMBIANA AMERICANO'
        ],

        datasets: [
            {
                label: 'Cup Consumption',
                // backgroundColor: '#42A5F5',
                data: beverageWiseDemoData,
                // data: [ 
                //     "714240",
                //     "142848",
                //     "1285632",
                //     "285696",
                //     "1214208",
                //     "357120",
                //     "214272",
                //     "857088",
                //     "428544",
                //     "2428516",
                //     "4571536",
                //     "1714176",
                //     "71424"
                // ],

                backgroundColor: ['#FF6600','#c8a988','#d46e11','#FF6600','#e4cbb5','#fff123','#FF6600','#bc7b3b','#c8a988','#fff123','#FF6600','#bc7b3b','#c8a988'],
            }
        ]
    };


    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    
        const barData = {
            labels: [

                    'Global Marketing Solutions',
                    'The Blue Eye Beverages - AMC',
                    'Khatri Enterprises',
                    'Great World Enterprises',
                    'Robinhood Foods',
                    'Om Foods',
                    'Sherawat Traders',
                    'Omega Enterprises',
                    'Bighammer Works Private Limited',
                    'Bela Enterprises'

                    // 'Devki Motors LLP',
                    // 'Global Marketing Solution',
                    // 'Omega Enterprise',
                    // 'Bighammer Works Pvt Ltd',
                    // 'Shillong Enterprise',
                    // 'DN International',
                    // 'Bandita Agency',
                    // 'Saurav Agency',
                    // 'Vikapl and CO',
                    // 'Reliable Marketing',

                    // 'NMDC data center Pvt ltd',
                    // 'Tata Motors(Rudra Motors)',
                    // 'SKECHERS South Asia India Pvt Ltd.',
                    // 'Grauer & Weil (India) Limited',
                    // 'Devki Motors LLP',
                    // 'Ador Digatron Pvt. Ltd'
                    
                ],

                

            datasets: [
                {
                    label: 'No of Cups',
                    backgroundColor: '#00585d',
                    borderColor: '#00585d',
                    data: destributorWiseDemoData,
                    // data: [
                    //     4999680,
                    //     1714176,
                    //     1571328,
                    //     1285832,
                    //     1142782,
                    //     999936,
                    //     857088,
                    //     714240,
                    //     571392,
                    //     428544
                    // ],

                    barPercentage: 0.7

                }
            ]
        };

        const barOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#A0A7B5'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#A0A7B5'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#A0A7B5'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                }
            }
        };

    
        const salesData = [
            {
                "id":1,
                "header":"No. Of Distributors",
                "value":"67",
                "icon":"pi pi-user"
            },
            {
                "id":2,
                "header":"No of Accounts",
                "value":"204",
                "icon":"pi pi-th-large"
            },
            {
                "id":3,
                "header":"No of CTVM's",
                "value": `${demomachineCount}`,
                "icon":"pi pi-sitemap"
            },
            {
                "id":4,
                "header":"Number Of Cups Sold (till date)",
                "value":"14284800",
                "icon":"pi pi-slack"
            },
            {
                "id":5,
                "header":"Average Per Cup Sale Cost",
                "value":"INR 5.75 + GST",
                "icon":"pi pi-wallet"
            },
            {
                "id":6,
                "header":"All Time Favourite Flavor",
                "value":"MADRAS COFFEE",
                "icon":"pi pi-star-o"
            }
        ]
    
        const Superstardata = [
            {
                "id":1,
                "header":"Maximum CTVM Deployment Super-Star Account",
                "value":"Ador Powertron Pvt Ltd",
                "key":"cves_deployed_super_star"
                
            },
            {
                "id":2,
                "header":"Top Cup Consumption Super-Star Account",
                "value":"Topworth Pipes & Tubes Pvt Ltd",
                "key":'cup_consumption_super_star'
            },
            {
                "id":3,
                "header":"Highest Target Performance Super-Star Account",
                "value":"Sokrati Technology Pvt Ltd, Baner",
                "key":"target_performance_super_star"
            },
            {
                "id":4,
                "header":"Maximum Inventory Holding Super-Star Account",
                "value":"NMDC data center Pvt ltd",
                "key":"inventory_super_star"
            }
        ]

        const useStatInput1 = {id:[""]}

        
        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#A0A7B5'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        const basicData1 = {
            labels: ['Weekdays', 'Weekends', 'Morning', 'Evening'],
            datasets: [
                {
                    label: '',
                    backgroundColor: [
                        '#0F8BFD',
                        '#FC6161',
                        '#EC4DBC',
                        '#EEE500'
                    ],
                    
                    data: timeWiseData,
                    // data:  [12476909, 3119228, 10917296, 4678841],
                }
            ]
        };

        const basicData2 = {
            labels: ['9AM to 2PM', '2PM to 7PM', '7PM to 12AM', '12AM to 9AM'],
            datasets: [
                {
                    label: '',

                    // data:  [12476909, 3119228, 10917296, 4678841],

                    data: hourWiseData,
                    backgroundColor: [
                        '#0F8BFD',
                        '#FC6161',
                        '#00D0DE',
                        '#873EFE'
                    ],
                    hoverBackgroundColor: [
                        '#0F8BFD',
                        '#FC6161',
                        '#00D0DE',
                        '#873EFE'
                    ],
                    borderColor: 'transparent',
                    fill: true
                }
            ]
        };

        
    
        const getDemoDataForChart = async()=>{

            const response2 = await apiServices.findMachines();
            let result = response2?.data?.data?.filter((m)=>{return m.siteName != '' }).map(a => a.id);         
            const response1 = await apiServices.getMaterialType();
            var response = await apiServices.getUserStats({...useStatInput1,id:result});
    
            let totalConsumption = response?.data?.data?.usagesStats[0]?.totalCup;

            const date1 = new Date('3/27/2022');
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    
            const machinesCount = 512+diffDays;

            let NoofCups = 62;
            let NoOfMachines = machinesCount;
            let DaysPerMonth = 25;
            let NoOfMonths = 18;


            const inorganicIni = (NoofCups * NoOfMachines * DaysPerMonth * NoOfMonths) + totalConsumption;

            setDemomachineCount(machinesCount)
            setDemoCupCount(inorganicIni)

            
            setNineToTwoData(Math.ceil(inorganicIni * 40 / 100))
            setTwoToSevenData(Math.ceil(inorganicIni * 30 / 100))
            setSevenToTwelveData(Math.ceil(inorganicIni * 20 / 100))
            setTwelveToNineData(Math.ceil(inorganicIni * 10 / 100))

            setWeekdaysData(Math.ceil(inorganicIni * 80 / 100))
            setWeekendData(Math.ceil(inorganicIni * 20 / 100))
            setMorningData(Math.ceil(inorganicIni * 70 / 100))
            setEveningData(Math.ceil(inorganicIni * 30 / 100))

            console.log(Math.ceil(inorganicIni * 80 / 100))
            const dataOfSalesWise = [Math.ceil(inorganicIni * 80 / 100), Math.ceil(inorganicIni * 20 / 100), Math.ceil(inorganicIni * 70 / 100), Math.ceil(inorganicIni * 30 / 100)]
            setTimeWiseData(dataOfSalesWise)

            const HourWiseArr = [Math.ceil(inorganicIni * 40 / 100),Math.ceil(inorganicIni * 30 / 100),Math.ceil(inorganicIni * 20 / 100),Math.ceil(inorganicIni * 10 / 100)];
            setHourWiseData(HourWiseArr)

            const BeverageDemoData = [Math.ceil(inorganicIni * 5 / 100),Math.ceil(inorganicIni * 1 / 100),Math.ceil(inorganicIni * 9 / 100),Math.ceil(inorganicIni * 2 / 100),Math.ceil(inorganicIni * 8.5 / 100),
            Math.ceil(inorganicIni * 2.5 / 100),Math.ceil(inorganicIni * 1.5 / 100),Math.ceil(inorganicIni * 6 / 100),Math.ceil(inorganicIni * 3 / 100),
            Math.ceil(inorganicIni * 17 / 100),Math.ceil(inorganicIni * 32 / 100),Math.ceil(inorganicIni * 12 / 100),Math.ceil(inorganicIni * 0.5 / 100)]
            setBeverageWiseDemoData(BeverageDemoData)

            const DestributorDemoData = [Math.ceil(inorganicIni * 35 / 100),Math.ceil(inorganicIni * 12 / 100),Math.ceil(inorganicIni * 11 / 100),Math.ceil(inorganicIni * 9 / 100),Math.ceil(inorganicIni * 8 / 100)
            ,Math.ceil(inorganicIni * 7 / 100),Math.ceil(inorganicIni * 6 / 100),Math.ceil(inorganicIni * 5 / 100),Math.ceil(inorganicIni * 4 / 100),Math.ceil(inorganicIni * 3 / 100)
            ];

            setDestributorWiseDemoData(DestributorDemoData)
        }


    useEffect(() => {
        setSalesStat(salesData);
        // setSuperstarAcc(Superstardata);
        getDemoDataForChart()
        getMachines()
        getIoStats()
        getCVESStats()
    }, []);

    const getCVESStats = async() =>{
        const response = await apiServices.getCVESStats({});
        console.log(response?.data?.data);
        const filteredData = Superstardata.map((x)=>{
            return {...x, value : response?.data?.data[x?.key] ? response?.data?.data[x?.key] : x.value}
        })

        setSuperstarAcc(filteredData)

    }

    const getIoStats = async() =>{
        const ioStatsResponse = await apiServices.getIoStats();
        console.log(ioStatsResponse.data.data);
        setIoStats(ioStatsResponse.data.data)
    }


    const getMachines = async() =>{

        const filters =  { brand : ['TAPRI','TKAFFE','MERI_TAPRI'] }
        const response = await apiServices.findMachines(filters);

        let newArr;
        if(managementCheck()){
            const ioResponse = await apiServices.getIoMachines();
            newArr = [...response?.data?.data,...ioResponse?.data?.data];
        }else{
            newArr = [...response?.data?.data];
        }

        const response1 = await apiServices.getUserReduced();
        
        let arr1 = []
        newArr?.map((x)=>{
            response1?.data?.data.filter((y)=>{
                if(x.customer == y.userid){
                    arr1.push({...x,accountName:y.name,companyName:y?.billing_details?.company ? y?.billing_details?.company : 'Cherise India Pvt. Ltd.',facilityName:x?.subscription?.facilitydata?.facname ? x.subscription.facilitydata?.facname : 'NA' })
                }
            })
        })


        const onBoardmachines = arr1?.filter((m)=>{ 
            return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription.package?.site == "demo" || m.subscription?.package?.site == "permanent") && m.siteName != ""  })
        setOnboardMachines(onBoardmachines)

        const result = onBoardmachines.filter(checkMachine).map(a => a.id);
        setAllMachines(result);
        getStats(result)
    }

    function checkMachine(m) {
        return  m.datastore.stage == "finishedgoods" && m.subscription && (m.subscription?.package?.site == "demo" || m.subscription?.package?.site == "permanent") && m.siteName != ""
    }   

    const getStats = async(data) =>{
        const response = await apiServices.getUserStats({ beverage: "All", id: data, userId: initialValue.userid });
        setTotalCupCount(response.data.data.usagesStats[0].totalCup)
        setIOLoading(false)
    }

    
const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            tension: .4
        }
    ]
};



const lineOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#A0A7B5'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#A0A7B5'
            },
            grid: {
                color:  'rgba(160, 167, 181, .3)',
            }
        },
        y: {
            ticks: {
                color: '#A0A7B5'
            },
            grid: {
                color:  'rgba(160, 167, 181, .3)',
            }
        }
    }
};

    

    return (

        <>
            
                <>

        {/* 
        <div className="p-grid p-fluid">
                    <div className="p-col-12 p-lg-6">
                        <div className="card">
                            <h5 className="centerText">Linear Chart</h5>
                            <Chart type="line" data={lineData} options={lineOptions} />
                        </div>
                        </div>
                        </div> */}

                    <div className='p-grid'>
                        <div className='p-col-12'>

                        

                            <div className='card'>
                                <h6>Sales Overall Statastics</h6>
                                <div className="p-grid">
                                
                                    <DemoMISStats onboardMachines={onboardMachines} totalCupCount={totalCupCount} ioStats={ioStats} iOLoading={iOLoading} />
                                
                                {/*                         
                                {
                                salesStat.length ? salesStat.map(stat => (

                                <div className="p-col-12 p-md-4">
                                <div className="card widget-overview-box widget-overview-box-1">
                                <span className="overview-title">
                                {stat.header}
                                </span>
                                <div className="p-d-flex p-jc-between">
                                    <div className="overview-detail p-d-flex p-jc-between">
                                        <div className="overview-texts">
                                            <b>{stat.value}</b> 
                                            <i className={stat.icon} style={{ fontSize: '2rem' }}></i>
                                        </div>                               
                                    </div>
                                </div>
                                </div>
                                </div>
                                )) : <p>No data is available</p>    
                                }  */}
                                </div>
                            </div>

                                        
                            <div className="card">
                                <div className="p-grid">

                                <div className="p-col-12 p-md-6">
                                {
                                    iOLoading ? 
                                    <>
                                        <center>
                                            <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                        </center>
                                    </>
                                    :
                                    <div className="card widget-country-graph">
                                        <div className="country-title">SALES TIME-WISE STATASTICS</div>
                                        <Chart type="bar" data={basicData1} options={horizontalOptions} />
                                    </div>
                                }

                                </div>

                                <div className="p-col-12 p-md-6">

                                {
                                    iOLoading ? 
                                    <>
                                        <center>
                                            <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                        </center>
                                    </>
                                    :
                                    <div className="card widget-country-graph">
                                        <div className="country-title">SALES HOUR-WISE STATISTICS</div>
                                        <Chart type="bar" data={basicData2} options={horizontalOptions} />
                                    </div>
                                }
                                </div>

                                    {/* <DemoMISCharts/> */}


                                {/* <div className="p-col-12 p-md-6">
                                    <div className="card widget-country-graph">
                                        <div className="country-title">SALES TIME-WISE STATASTICS</div>
                                        <div className="country-graph p-d-flex p-jc-center">
                                            <Chart type="doughnut" id="country-chart" data={timeWiseChart} options={timeWiseChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                                        </div>
                                        <div className="country-content">
                                            <ul>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#0F8BFD', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                                        <span>Weekdays</span>
                                                    </div>
                                                    <span>9213480</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#FC6161', boxShadow: '0px 0px 10px rgba(135, 62, 254, 0.3)' }}></div>
                                                        <span>Weekends</span>
                                                    </div>
                                                    <span>2303370</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#EC4DBC', boxShadow: '0px 0px 10px rgba(252, 97, 97, 0.3)' }}></div>
                                                        <span>Morning</span>
                                                    </div>
                                                    <span>8061795</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#EEE500', boxShadow: '0px 0px 10px rgba(238, 229, 0, 0.3)' }}></div>
                                                        <span>Evening</span>
                                                    </div>
                                                    <span>3455055</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
        {/*                 
                                <div className="p-col-12 p-md-6">
                                    <div className="card widget-country-graph">
                                        <div className="country-title">SALES HOUR-WISE STATISTICS</div>
                                        <div className="country-graph p-d-flex p-jc-center">
                                            <Chart type="doughnut" id="country-chart" data={hourWiseChart} options={timeWiseChartOptions} style={{ position: 'relative', width: '75%' }}></Chart>
                                        </div>
                                        <div className="country-content">
                                            <ul>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#0F8BFD', boxShadow: '0px 0px 10px rgba(0, 208, 222, 0.3)' }}></div>
                                                        <span>9AM to 2PM</span>
                                                    </div>
                                                    <span>{nineToTwoData?nineToTwoData:'-'}</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#FC6161', boxShadow: '0px 0px 10px rgba(135, 62, 254, 0.3)' }}></div>
                                                        <span>2PM to 7PM</span>
                                                    </div>
                                                    <span>{twoToSevenData?twoToSevenData:'-'}</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#00D0DE', boxShadow: '0px 0px 10px rgba(252, 97, 97, 0.3)' }}></div>
                                                        <span>7PM to 12AM</span>
                                                    </div>
                                                    <span>{sevenToTwelveData?sevenToTwelveData:'-'}</span>
                                                </li>
                                                <li className="p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-jc-between p-ai-center">
                                                        <div className="color" style={{ backgroundColor: '#873EFE', boxShadow: '0px 0px 10px rgba(238, 229, 0, 0.3)' }}></div>
                                                        <span>12AM to 9AM</span>
                                                    </div>
                                                    <span>{twelveToNineData?twelveToNineData:'-'}</span>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            </div>

                            <div className='card'>
                            <h6>PERFORMANCE-WISE SUPERSTAR ACCOUNTS</h6>
                            <div className="p-grid">

                            {
                            superstarAcc.length ? superstarAcc.map(stat => (

                            <div className="p-col-12 p-md-6">
                            <div className="card widget-overview-box widget-overview-box-1">
                                <span className="overview-title">
                                {stat.header}
                                </span>
                                <div className="p-d-flex p-jc-between">
                                    <div className="overview-detail p-d-flex p-jc-between">
                                        <div className="overview-text">
                                            <b>{stat.value}</b>
                                        </div>                               
                                    </div>
                                </div>
                                <i className="pi pi-sitemap side-icon" style={{ fontSize: '2rem' }}></i>
                            </div>
                            </div>

                            )): <p>No data is available</p>  
                            }

                            </div>
                            </div>

                        </div>
                    </div>

                    <div className="p-grid crud-demo">
                        <div className="p-col-12">
                            {
                                iOLoading ? 
                                <>
                                    <center>
                                        <span><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></span>
                                    </center>
                                </>
                                :
                                <div className="card">
                                    <TabView>
                                    <TabPanel header="Flavourwise Cup Consumption">
                                        <div className="card p-px-0">
                                            <h5>Beverage-Wise Cup Consumption Performance Details</h5>
                                            <Chart height="600" type="bar" data={basicData} options={basicOptions} />
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Top 10 Distributors Performance wise">
                                        
                                    <div className="card">
                                        <div className="card-header">
                                            <span>Top 10 Distributors Performance Wise</span>
                                        </div>
                                        <div className="graph">
                                        <Chart type="bar" data={barData} options={barOptions} />
                                        </div>
                                    </div>
                                    </TabPanel>
                                    </TabView>
                                </div>
                            }

                        </div>
                    </div>

                
                </>

        </>
    )
}

