

export const billingTypeOptions = [
    { name: "Cup Wise", code: "Cupwise" },
    { name: "Packet Wise", code: "PacketWise" }
]

export const paymentTermOptions = [
    { name: "Prepaid", code: "PREPAID" },
    { name: "Postpaid", code: "POSTPAID" },
    { name: "Partial", code: "PARTIAL" }
]

export const dispensingOptions = [
    { name: "Free", code: "FREE" },
    { name: "Paid", code: "PAID" },
]


export const paidBeverageSetting = {
    rfid: true,
    nfc: false,
    fingerprint: false,
    qr: false,
    onlinePayment: false,
    password: false,
    all: false
}

export const paymentTypeOptions = [
    { name: "Direct Bank Account", code: "Direct Bank Account" },
    { name: "Other than Direct Bank Account", code: "Other than Direct Bank Account" }
]

export const prefixOptions = [
    { name: 'Mr.' },
    { name: 'Mrs.' }
]

export const rentleOptions = [
    { name: 'Monthly Maintenance Charge', code: 'monthly' },
    { name: 'Annual Maintenance Charge', code: 'yearly' },
    // { name: 'Quarterly', code: 'quaterly' },
    // { name: 'Half Yearly', code: 'half-yearly' }
]

export const extentionPeriodOptions = [
    { value: 3 },
    { value: 5 },
    { value: 7 },
    { value: 15 },
    { value: 30 },
    { value: 45 },
    { value: 60 },
    { value: 90 },
]


export const subScriptionOptions = [
    { name: 'Free', code: 'FREE' },
    { name: 'Maintenance Charge', code: 'RENT' },
]

export const entityOptions = [
    { name: "Private Limited Company" },
    { name: "Limited Company" },
    { name: "Proprietary Firm" },
    { name: "Limited Liability Partnership" },
    { name: "Partnership Firm" },
    { name: "NA" }
]

export const defaultValues = {
    // prefix : "Mr.",
    cup_cost: 0,
    packet_cost: 0,
    refundable_deposit: 0,
    billing_type: "Cupwise",
    personal_prefix: "Mr.",
    signing_authority_prefix: "Mr.",
    payment_terms: {
        mode: "PREPAID",
        prepaid_payment_type: 'Direct Bank Account',
        extPeriod: 7
    },
    cup_quantity: 60,
    amc: 0,
    subscription_type: 'FREE',
    number_of_cves: 1,
    legal_entity_name: 'Private Limited Company',
    dispensing_option: 'FREE',
    rfid: false,
    nfc: false,
    onlinePayment: false,
    password: false
}

export const billingDefault = {
    country: 'India'
}


export const payloadData = {
    "name": '',
    "city": '',
    "address": '',
    "business_name": '',
    "business_address": '',
    "business_registration": '',
    "gst": '',
    "fsaai": '',
    "is_power": false,
    "is_space": false,
    "is_water": false,
    "business_lat": '',
    "business_lng": '',
    "business_state": '',
    "business_pincode": '',
    "address_lat": '',
    "address_lng": '',
    "user_id": '',
    "user_image": "",
    "proof_front": "",
    "proof_back": "",
    "proof_store": "",
}

export const categories = [
    { name: '16A Power Plug & Supply available', key: 'is_power' },
    { name: 'Space for Tapri Kettle (10 in * 10 in) available', key: 'is_space' },
    { name: '20L water bottle available', key: 'is_water' }
];

export const machineData = [
    {
        name: 'Tapri Kiosk',
        img: 'type_tapri_machine.png',
        brand: 'TAPRI'
    },
    {
        name: 'Buddy',
        img: 'type_capsule_machine.png',
        brand: 'BUDDY'
    },
    {
        name: 'Tapri Kettle',
        img: 'type_meri_tapri_machine.png',
        brand: 'MERI_TAPRI'
    },
    {
        name: 'TKaffe Kiosk',
        img: 'type_tkaffe_machine.png',
        brand: 'TKAFFE'
    },
]
