import React, { useState, useEffect, useRef } from 'react';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
// RazorPay
import useRazorpay from "react-razorpay";
// Common
import { CommonBodyForTable } from '../Common/CommonComponents';
import { copyData, failureToast, infoToast, successToast } from '../Common/CommonFunctions';
// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =====================================================================================
// =====================================================================================

export const ProductsForMachineOrder = (props) => {
    // console.log("ProductsForMachineOrder", props);
    const Razorpay = useRazorpay();
    const isLive = true;
    const RPAY_KEY = isLive ? "rzp_live_33WIg85yHAfbEv" : "rzp_test_J1drhhEipPb1Rl"
    const RPAY_SECRET = isLive ? "H4QUfaVxXmL2mHKj1jCVpuZX" : "c7FvosSLkmvjE7anmzS8fNiu"

    const billingDefault = { "is_same": false, "name": "", "gst": "", "address": "", "city": "", "state": "", "pincode": "" }

    const [loading, setLoading] = useState(true)
    const [allComboBeverages, setAllComboBeverages] = useState(null)
    const [comboBeverages, setComboBeverages] = useState(null)
    const [packsBeverages, setPacksBeverages] = useState(null)
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [cartItems, setCartItems] = useState("0");
    const [value18, setValue18] = useState(0);
    const [totalCups, setTotalCups] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [packsTotalCups, setPacksTotalCups] = useState(0);
    const [packsTotalPrice, setPacksTotalPrice] = useState(0);
    const [reviewOrderFlag, setReviewOrderFlag] = useState(true);
    const [paymentType, setPaymentType] = useState('ONLINE');
    const [orderSummary, setOrderSummary] = useState(null);
    const [mainOrderSummary, setMainOrderSummary] = useState(null);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [couponCodeDialog, setCouponCodeDialog] = useState(false);
    const [allCouponsList, setAllCouponsList] = useState([]);
    const [couponLoading, setCouponLoading] = useState(true);
    const [typedCouponCode, setTypedCouponCode] = useState('');
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [proceedClicked, setProceedClicked] = useState(false)

    const [packetCombo, setPacketCombo] = useState([])
    const [inCartProducts, setInCartProducts] = useState([])

    const [successDialog, setSuccessDialog] = useState(false)
    const [placedOrderData, setPlacedOrderData] = useState(null)

    const [visible, setVisible] = useState(false);
    const [addressDialog, setAddressDialog] = useState(false);
    const [addressType, setAddressType] = useState(null);
    const [addressPayload, setAddressPayload] = useState(null);
    const [addressData, setAddressData] = useState([]);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [allAddressesDialog, setAllAddressesDialog] = useState(false);
    const [addBillingDialog, setAddBillingDialog] = useState(false);
    const [billingAddress, setBillingAddress] = useState(null);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
    const [countries, setCountries] = useState(null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [defaultDashData, setDefaultDashData] = useState(null);
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [selectedfacility, setSelectedfacility] = useState(null);
    const [selectedfacilityData, setSelectedfacilityData] = useState(null);
    const [addressForCheckout, setAddressForCheckout] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        getData(props.productPayload)
    }, [props.productPayload]);

    useEffect(() => {
        setSelectedfacility(props.selectedFac)
    }, [props.selectedFac])

    useEffect(() => {
        console.log(props.registeredUser);
        getFacData(props.registeredUser)
    }, [props.registeredUser])


    const getFacData = (data) => { setAddressForCheckout(data) }

    const getSortedData = (data) => {
        console.log(data);
        const billingData = {
            ...data?.facility_billing_details,
            country: data?.facility_billing_details?.country?.name,
            state: data?.facility_billing_details?.state?.name,
            city: data?.facility_billing_details?.city?.name
        }

        const shippingData = {
            ...data?.shipping_address,
            country: data?.shipping_address?.country?.name,
            state: data?.shipping_address?.state?.name,
            city: data?.shipping_address?.city?.name
        }

        const payload = { ...data, facility_billing_details: billingData, shipping_address: shippingData }
        console.log(payload);
        setSelectedfacilityData(payload)
    }

    const appOptions = {
        headers: {
            "Authorization": props.verifiedUserData?.token,
            "Content-type": "application/json"
        }
    };

    const orderTemplate = {
        "combos": '',
        "shipping_address": {
            "address": props?.selectedFacility?.address?.address,
            "country": props?.selectedFacility?.address?.country?.name,
            "state": props?.selectedFacility?.address?.state?.name,
            "city": props?.selectedFacility?.address?.city?.name,
            "pincode": props?.selectedFacility?.address?.pincode
        },
        "billing_address": {
            "company_name": props?.selectedFacility?.billing_details?.cmpname,
            "gst": props?.selectedFacility?.billing_details?.gstnumber,
            "address": props?.selectedFacility?.billing_details?.addr,
            "country": props?.selectedFacility?.billing_details?.country?.name,
            "state": props?.selectedFacility?.billing_details?.state?.name,
            "city": props?.selectedFacility?.billing_details?.city?.name,
            "pincode": props?.selectedFacility?.billing_details?.pincode
        },
        "payment_mode": paymentType,
        "IOT_FacilityId": props?.selectedFacility?.facid,
        "IOT_UserId": props?.selectedFacility?.userid,
        "coupon": typedCouponCode,
        "price_per_cup": props.selectedAccount?.cup_cost,
        "customer_name": props.selectedAccount?.name,
        "customer_contact": props.selectedAccount?.cno,
        "customer_email": props.selectedAccount?.email,
        "company_name": props.selectedAccount?.compnyName,
        // "offset": props.selectedAccount?.beverages_offset,
        "offset": props.selectedAccount?.beverages_offset?.toFixed(2),
        "mode": props.selectedAccount?.payemntTerms?.mode,
        "extPeriod": props.selectedAccount?.payemntTerms?.extPeriod,
        "minAmtPercent": props.selectedAccount?.payemntTerms?.minAmtPercent,
        "payment_terms": props.selectedAccount?.payemntTerms,
    }

    const sortOptions = [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
    ];

    const getGST = (amt) => {
        const calculatedGst = amt * 18 / 100.0; // 18% Gst
        return calculatedGst;
    }

    const getDefaultStates = () => {

        setAllComboBeverages(null)
        setComboBeverages(null)
        setPacksBeverages(null)
        setLayout('grid');
        setSortKey(null);
        setSortOrder(null);
        setSortField(null);
        setCartItems("0");
        setValue18(0);
        setTotalCups(0);
        setTotalPrice(0);
        setPacksTotalCups(0);
        setPacksTotalPrice(0);
        setReviewOrderFlag(true);
        setPaymentType('ONLINE');
        setOrderSummary(null);
        setMainOrderSummary(null);
        setOrderPlaced(false);
        setCouponCodeDialog(false);
        setAllCouponsList([]);
        setCouponLoading(true);
        setTypedCouponCode('');
        setIsCouponApplied(false);
        setProceedClicked(false)
        setPacketCombo([])
        setInCartProducts([])
        setSuccessDialog(false)
        setPlacedOrderData(null)
        setVisible(false);
        setAddressDialog(false);
        setAddressType(null);
        setAddressPayload(null);
        setAddressData([]);
        setSelectedAddress(null);
        setAllAddressesDialog(false);
        setAddBillingDialog(false);
        setBillingAddress(null);
        setSelectedBillingAddress(null);
        setCountries(null);
        setStates(null);
        setCities(null);
        setSelectedCountry(null);
        setSelectedState(null);
        setSelectedCity(null);
        setDefaultDashData(null);
        setFacilitiesData([]);
        setSelectedfacility(null);
        setSelectedfacilityData(null);
        setAddressForCheckout(null);
    }

    const setDefaultOrderValues = () => {
        setOrderPlaced(false)
        setTotalCups(0)
        setTotalPrice(0)
        setReviewOrderFlag(true)
        setIsCouponApplied(false)
        setMainOrderSummary(null)
        setOrderSummary(null)
        setTypedCouponCode('')
        setProceedClicked(false)
        getData(props.productPayload)
    }

    const getData = async (payload) => {
        console.log("getData", payload);
        const data = await apiServices.getMachineOrderProducts(payload, appOptions);
        const mappedArr = data?.data?.response_obj.combos?.map(v => ({ ...v, cartQuantity: 0 }))

        // console.log("mappedArr", mappedArr);

        if (props.selectedBrand?.type == "PacketWise") {

            const arr = mappedArr.map((x) => { return { ...x, price: Number(props.selectedBrand?.price) } })
            // console.log(arr);
            setAllComboBeverages(arr)
            setComboBeverages(arr)

            const packsArr = data?.data?.response_obj.packs?.map((v) => {
                const qty = v?.combos?.reduce((partialSum, a) => partialSum + a.qty, 0);
                return { ...v, price: Number(props.selectedBrand?.price) * qty, cartQuantity: 0 };
            })

            setPacksBeverages(packsArr)

        } else {

            const arr = mappedArr?.map((x) => { return { ...x, price: (Number(props.selectedBrand?.price) * Number(x.cups_count)) } })
            // console.log(arr);
            setAllComboBeverages(arr)
            setComboBeverages(arr)
            const packsArr = data?.data?.response_obj.packs?.map(v => ({ ...v, cartQuantity: 0 }))
            setPacksBeverages(packsArr)
        }

        getSomeData();
        setLoading(false)
    }

    const changeFac = (e) => {
        setSelectedfacility(e.value)
        getSortedData(e.value)
    }

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const changePackCartValue = (data) => {

        const newD = data?.combos?.map((x) => { return { id: x.combo_id, qty: x.qty } })

        const arr1 = [];
        comboBeverages.forEach((x) => { newD.forEach((y) => { if (x._id == y.id) { arr1.push({ ...x, cartQuantity: y.qty }) } }) })

        const arr2 = [];
        comboBeverages.forEach((x) => {
            const again = newD.findIndex((obj) => x._id === obj.id);
            if (again == -1) { arr2.push({ ...x }) }
        })

        const comb = [...inCartProducts, ...arr1];
        setInCartProducts(comb)

        const hey = [];
        comb.map((x) => {
            const val = hey.findIndex((obj) => x._id === obj._id)
            if (val == -1) {
                hey.push(x);
            } else {
                const getId = hey[val]._id;
                const result = comb.filter((x) => {
                    if (x._id == getId) {
                        return x;
                    }
                }).reduce((acc, obj) => {
                    console.log(obj);
                    return acc + obj.cartQuantity;
                }, 0);
                hey.push({ ...x, cartQuantity: result });
            }
        })

        setComboBeverages([...arr1, ...arr2])
        setPacketCombo(newD)

        const arr = [];
        packsBeverages.forEach((x) => { (x._id == data._id) ? arr.push({ ...x, cartQuantity: 1 }) : arr.push({ ...x }) })
        setPacksBeverages(arr)
        packsCalculations(arr)
        successToast(toast, 'Pack added in Cart')
    }

  
    const removeFromCart = (data) => {
        failureToast(toast, 'Pack removed from Cart', 'Removed')

        const arr = [];
        packsBeverages.forEach((x) => { (x._id == data._id) ? arr.push({ ...x, cartQuantity: 0 }) : arr.push({ ...x }) });

        const unSelectedArr = [];
        inCartProducts.map((x) => {
            const again = data?.combos.findIndex((obj) => obj.combo_id === x._id);
            if (again != -1) { unSelectedArr.push({ ...x, cartQuantity: 0 }) }
        });

        const bev = [];
        comboBeverages.forEach((x) => {
            const again = data?.combos.findIndex((obj) => obj.combo_id === x._id);
            (again != -1) ? bev.push({ ...x, cartQuantity: 0 }) : bev.push({ ...x });
        });

        setComboBeverages(bev)
        const inCartData = bev.map((x) => { return x.cartQuantity > 0; })
        setInCartProducts(inCartData)
        setPacksBeverages(arr)
        packsCalculations(arr)
    }

    const packsCalculations = (arr) => {
        const totalC = arr.map((x) => { return x.cartQuantity == 1 ? x.cups_count : 0; }).reduce(function (previousValue, currentValue) { return previousValue + currentValue }, 0);
        const totalSum = arr.map((x) => { return x.cartQuantity == 1 ? x.price : 0; }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        setTotalCups(totalC)
        setTotalPrice(totalSum)
    }

    const changeCartValue = (e, data) => {
        const QuantityValue = e.value > 999 ? 0 : e.value;
        const arr = [];
        comboBeverages.forEach((x) => { x._id == data._id ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) });
        // console.log(arr);
        setInCartProducts(arr);
        setComboBeverages(arr);
        const conclude = arr.filter((x) => { if (x.cartQuantity > 0) { return x; } });
        const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        const sum = conclude.map((x) => { return x.cartQuantity * x.price }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        setTotalCups(totalC);
        setTotalPrice(sum)
    }

    const changeCartValueFromCart = (e, data) => {
        const QuantityValue = e.value > 999 ? 0 : e.value;
        const arr = [];
        inCartProducts.forEach((x) => { x._id == data._id ? arr.push({ ...x, cartQuantity: QuantityValue }) : arr.push({ ...x }) });
        setInCartProducts(arr);
        const conclude = arr.filter((x) => { if (x.cartQuantity > 0) { return x; } });
        const totalC = conclude.map((x) => { return x.cartQuantity * x.cups_count }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        const sum = conclude.map((x) => { return x.cartQuantity * x.price }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; }, 0);
        setTotalCups(totalC);
        setTotalPrice(sum);
    }


    const reviewOrder = () => {
        const added = comboBeverages.filter((x) => { if (x.cartQuantity > 0) { return x; } })
        setInCartProducts(added)
        setReviewOrderFlag(false)
        window.scrollTo(0, 0)
    }

    const removeCouponCode = () => {
        setTypedCouponCode('')
        setIsCouponApplied(false)
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `Coupon code removed`, life: 3000 });
        setOrderSummary(mainOrderSummary)
    }

    const openCouponList = () => {
        setCouponCodeDialog(true)
        getCouponCodes()
    }

    const getCouponCodes = async () => {
        const response = await apiServices.getCouponCodes({ "page": 1 });
        console.log(response.data.response_obj);
        setAllCouponsList(response.data.response_obj)
        setCouponLoading(false)
    }

    const hideCouponDialog = () => { setCouponCodeDialog(false) }

    const changeCouponValue = (e) => { setTypedCouponCode(e.target.value) }

    const applyCouponCode = async (e) => {
        e.preventDefault();
        const comboData = comboBeverages.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        if (typedCouponCode) {
            const data = { ...orderTemplate, "combos": comboData, }
            applyCouponFn(data)
        } else {
            console.log('Enter Coupon Code');
        }
    }

    const applyCouponFn = async (data) => {
        const response = await apiServices.applyCouponCode(data);
        if (response?.data?.response_code == 1) {
            successToast(toast, response?.data?.response_message);
            setIsCouponApplied(true)
            const calculatedGst = (Number(response?.data?.response_obj?.subtotal) - Number(response?.data?.response_obj?.discount)) * 18 / 100;
            const finalValue = Number(response?.data?.response_obj?.total) + calculatedGst
            setOrderSummary({ ...orderSummary, discount: response?.data?.response_obj?.discount, tax: calculatedGst, subtotal: response?.data?.response_obj?.total, total: finalValue })
        } else {
            failureToast(toast, response?.data?.response_message);
        }
    }

    const placeOrder = async () => {
        window.scrollTo(0, 0)
        setOrderPlaced(true);

        // NOTES
        // PostPaid => bank Transfer 
        // Prepaid / Partial -> Direct Bank Account => bank Transfer 
        // Prepaid / Partial -> Other Than Bank Account => Online Payment 

        // props.registeredUser?.payment_terms?.mode == "PREPAID" && setPaymentType('ONLINE');
        // props.registeredUser?.payment_terms?.mode == "POSTPAID" && setPaymentType('COD');
        // props.registeredUser?.payment_terms?.mode == "PARTIAL" && setPaymentType('ONLINE');

        if (props.registeredUser?.payment_terms?.mode == "POSTPAID") {
            setPaymentType('COD');
        } else if ((
            (props.registeredUser?.payment_terms?.mode == 'PREPAID' || props.registeredUser?.payment_terms?.mode == "PARTIAL") && (props.registeredUser?.payment_terms?.prepaid_payment_type == 'Direct Bank Account' || props.registeredUser?.payment_terms?.Prepaid_Payment_Type_in_word == 'Direct Bank Account'))) {
            setPaymentType('COD');
        } else if (((props.registeredUser?.payment_terms?.mode == 'PREPAID' || props.registeredUser?.payment_terms?.mode == "PARTIAL") && (props.registeredUser?.payment_terms?.prepaid_payment_type == 'Other Than Bank Account' || props.registeredUser?.payment_terms?.Prepaid_Payment_Type_in_word == 'Other Than Bank Account'))) {
            setPaymentType('ONLINE');
        }

        const amcAmount = props.registeredUser?.amc ? Number(props.registeredUser?.amc).toFixed(2) : 0;
        const depositeAmount = props.registeredUser?.refundable_deposit ? Number(props.registeredUser?.refundable_deposit).toFixed(2) : 0;
        const rentAmount = props.registeredUser?.rent_amount ? Number(props.registeredUser?.rent_amount).toFixed(2) : 0;
        const payable = Number(packsTotalPrice) + Number(totalPrice) + Number(amcAmount) + Number(depositeAmount) + Number(rentAmount);
        const tax = getGST(payable);

        const obj = {
            amcAmount: amcAmount,
            depositeAmount: depositeAmount,
            rentAmount: rentAmount,
            subtotal: payable,
            tax: tax,
            total: payable + tax
        }

        console.log(obj);
        setOrderSummary(obj)
    }

    const getAddress = async () => {
        const response = (initialValue?.userType == "customer") ? await apiServices.getCustAddress() : await apiServices.getCustAddress(appOptions);
        setAddressData(response?.data?.response_obj)
        setSelectedAddress(response?.data?.response_obj[0])
    }

    const proceedWithOrder = async () => {
        setProceedClicked(true)
        const comboData = inCartProducts.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        const userIdForOrder = initialValue?.userType == "customer" ? initialValue?.app_data?.id : props.verifiedUserData?.id;
        console.log("props", props);
        const payload = {
            "user_id": userIdForOrder,
            "plan_id": props.selectedPlan?._id,
            "amount": { "taxes": orderSummary?.tax?.toFixed(2), "machine_amount": "0.00", "refill_amount": Number(orderSummary?.subtotal).toFixed(2), "free_amount": "0.00", "shipping_charges": "0" },
            "plan_type": props.selectedPlan?.free?.plan_type,
            "payment_mode": (initialValue.userType == 'owner' || initialValue.userType == 'accountmanager') ? "ONLINE" : paymentType,
            "combos": comboData,
            "ctvm_user_id": props.newUserData?._id,
            "facilityZohoId": selectedfacility?.facilityZohoId,
            "iot_facility_id": selectedfacility?.id ? selectedfacility?.id : '',
            "iot_user_id": props.verifiedUserData?.ctvm_user_data?.userid ? props.verifiedUserData?.ctvm_user_data?.userid : '',
            "shipping_address": addressForCheckout?.shipping_address,
            // "billing_address": addressForCheckout?.billing_address,
            "billing_address": { ...addressForCheckout?.billing_address, gst: addressForCheckout?.billing_address?.gst_no },
            "dispensing_option": props.registeredUser?.dispensing_option ? props.registeredUser?.dispensing_option : 'FREE',
            "rfid": props.registeredUser?.rfid ? props.registeredUser?.rfid : false,
            "nfc": props.registeredUser?.nfc ? props.registeredUser?.nfc : false,
            "password": props.registeredUser?.password ? props.registeredUser?.password : false,
            "online": props.registeredUser?.onlinePayment ? props.registeredUser?.onlinePayment : false,
            "upi_amount": props.registeredUser?.upi_amount ? props.registeredUser?.upi_amount: props.registeredUser?.cup_cost,
            "passwordText": props.registeredUser?.password_key,
            "facility_name":  props.registeredUser?.facility_name,
            "latitude": props.registeredUser?.latitude,
            "longitude": props.registeredUser?.longitude,
        }
        console.log("final payload", payload);
        const response = await apiServices.saveCtvmOrderData(payload, appOptions);
        console.log(response?.data);
        if (response?.data?.response_code == 1) {
            setSuccessDialog(true)
            setPlacedOrderData(response?.data?.response_obj)
        } else if (response?.data?.response_code == 0) {
            failureToast(toast, 'Failed to place order');
            setProceedClicked(false);
        } else {    
            failureToast(toast, 'Something went wrong');
            setProceedClicked(false)
        }
    }


    const handlePayment = async (data) => {

        const options = {
            key: RPAY_KEY,
            amount: mainOrderSummary?.payable * 100,
            currency: "INR",
            name: "Cherise",
            description: "Cherise Refill",
            order_id: data?.razorpay_order_id,
            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature);
            },
            prefill: {
                name: initialValue.name,
                email: initialValue.email,
                contact: initialValue.cno,
            },
            // notes: {
            //     address: "Razorpay Corporate Office",
            // },
            'notes.shopping_order_id': data?.shoppingOrderId,
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) { failureToast(toast, 'Payment Failed') });
        rzp1.on("payment.error", function (response) { failureToast(toast, 'Payment Failed') });
        rzp1.on("payment.external_wallet", function (response) { failureToast(toast, 'Payment Failed') });
        rzp1.on("payment.success", function (response) {
            updateTransaction(response)
            setDefaultOrderValues()
        });
        rzp1.open();
    };

    const updateTransaction = async (data) => {
        const params = { "razorpay_payment_id": data?.paymentId, "razorpay_order_id": data?.orderId, "razorpay_signature": RPAY_SECRET };
        const response = await apiServices.transactionSucceed(params);
        if (response.responseCode == 1) {
            successToast(toast, 'Payment done');
            setReviewOrderFlag(true)
        } else {
            failureToast(toast, 'Payment Failed')
        }
    }


    const applyCode = (data) => {
        setTypedCouponCode(data.coupon)
        const comboData = comboBeverages.map((x) => { return { id: x.id, qty: x.cartQuantity } })
        const codeData = { ...orderTemplate, "combos": comboData, coupon: data.coupon }
        applyCouponFn(codeData)
        setCouponCodeDialog(false)
    }

    const backFromReviewOrder = () => { setReviewOrderFlag(true) }

    const backFromPlaceOrder = () => {
        setOrderPlaced(false)
        setOrderSummary(mainOrderSummary)
        setPaymentType('LINK')
        setTypedCouponCode(null)
        setIsCouponApplied(false)
    }

    const onclickThis = (e) => {
        const copyText = placedOrderData?.razorpay_url;
        copyData(copyText);
        infoToast(toast, 'Link Copied to Clipboad', 'Copied');
    }

    const hideSuccessDialog = () => {
        setSuccessDialog(false)
        // getData(props.productPayload)
        setPlacedOrderData(null)
        setReviewOrderFlag(true)
        setOrderPlaced(false)
        setTotalCups(0)
        setTotalPrice(0)
        setPacksTotalCups(0)
        setPacksTotalPrice(0)
        props.setIsProceeded(false)
        getDefaultStates()
    }

    const changeBillingAddress = (e) => {

        if (e.target.name == 'state') {
            setSelectedState(e.value)
            getCities({ state_id: e.value.id })
            setBillingAddress({ ...billingAddress, [e.target.name]: e.value.name })
        }

        if (e.target.name == 'name' || e.target.name == 'gst' || e.target.name == 'address' || e.target.name == 'pincode' || e.target.name == 'city') {
            setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value })
        }
    }

    const saveBillingAddress = (e) => {
        e.preventDefault()
        console.log(billingAddress);
        setSelectedBillingAddress({ ...billingAddress, is_same: false })
        // setBillingAddress(billingAddress)
        hideAddDialog()
    }

    const changeAddress = (e) => {

        if (e.target.name == 'address' || e.target.name == 'landmark' || e.target.name == 'pincode' || e.target.name == 'contact_name' || e.target.name == 'state' || e.target.name == 'city') {
            setAddressPayload({ ...addressPayload, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'contact_number') {
            const isValid = isNaN(Number(e.target.value));
            !isValid && setAddressPayload({ ...addressPayload, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'type') {
            setAddressType(e.value)
            setAddressPayload({ ...addressPayload, [e.target.name]: e.value })
        }
    }

    const saveAddress = async (e) => {
        e.preventDefault()
        hideAddDialog()
        const lastPayload = { ...addressPayload, type: "work", "is_default": true, "lat": props.latLong?.currentLatitude ?? 0.0, "lng": props.latLong?.currentLongitude ?? 0.0, user_id: props.verifiedUserData?.id || initialValue?.app_data?.id }
        console.log(lastPayload);
        const response = initialValue?.userType == "customer" ? await apiServices.saveCustAddress(lastPayload) : await apiServices.saveCustAddress(lastPayload, appOptions);
        response?.data?.response_code == 1 && successToast(toast, 'Address is Saved Successfully');
    }

    const deleteSelectedAdrr = async (data) => {
        const response = initialValue?.userType == "customer" ? await apiServices.deleteCustAddr({ id: data?.id }) : await apiServices.deleteCustAddr({ id: data?.id }, appOptions);
        console.log(response);
        if (response?.data?.response_code == 1) {
            successToast(toast, 'Address is deleted successfully');
            getAddress()
        }
    }

    const changeSelectedAdd = (data) => {
        if (!data.is_default) {
            const arr = [];
            addressData.forEach((x) => { x.address == data.address ? arr.push({ ...x, is_default: true }) : arr.push({ ...x, is_default: false }) });
            setAddressData(arr);
            const filtered = arr.filter((x) => { return x.is_default == true });
            setSelectedAddress(filtered ? filtered[0] : null);
            successToast(toast, 'Address is changed successfully');
            setAllAddressesDialog(false)
        }
    }

    const addBilling = () => {
        setAddBillingDialog(true)
        getSomeData()
        // const regData = props.registeredUser;

        // console.log(regData);

        // const dataFromRegistry = {
        //     billing_details : {
        //         company: regData?.company_name,
        //         gstNumber: regData?.billing_address?.gst_no,
        //         addr: regData?.billing_address?.address,
        //         pincode: regData?.billing_address?.pincode,
        //         country: regData?.billing_address?.country,
        //         state: regData?.billing_address?.state,
        //         city: regData?.billing_address?.city,
        //     }
        // }

        // const dashData = props.verifiedUserData?.dashData || dataFromRegistry;
        // console.log(dashData);
        // setDefaultDashData(dashData);

        // getBillingCSC(dashData)
    }

    const getSomeData = () => {

        const regData = props.registeredUser;
        console.log(regData);

        const dataFromRegistry = {
            billing_details: {
                company: regData?.company_name,
                gstNumber: regData?.billing_address?.gst_no,
                addr: regData?.billing_address?.address,
                pincode: regData?.billing_address?.pincode,
                country: regData?.billing_address?.country,
                state: regData?.billing_address?.state,
                city: regData?.billing_address?.city,
            }
        }
        const dashData = props.verifiedUserData?.dashData || dataFromRegistry;
        console.log(dashData);
        setDefaultDashData(dashData);
        getBillingCSC(dashData)
    }

    const getBillingCSC = async (dData) => {

        const response = await apiServices.getState({ country_id: "101" });
        setStates(response?.data)

        const data = response.data.filter((x) => { if (x.name == dData?.billing_details?.state?.name) { return x; } });
        setSelectedState(data[0])
        const cityResponse = await apiServices.getCity({ state_id: data[0]?.id });
        setCities(cityResponse?.data)
        const cityOp = cityResponse?.data.filter((x) => { if (x.name == dData?.billing_details?.addr?.city?.name) { return x; } });
        setSelectedCity(cityOp[0])

        const payload = {
            state: dData?.billing_details?.state?.name,
            city: dData?.billing_details?.city?.name,
            name: dData?.billing_details?.company,
            gst: dData?.billing_details?.gstNumber ? dData?.billing_details?.gstNumber : '',
            address: dData?.billing_details?.addr,
            pincode: dData?.billing_details?.pincode
        }

        setBillingAddress(payload);
        setSelectedBillingAddress({ ...payload, is_same: false })
    }

    const successDialogFooter = () => (<> <Button label="OK" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={hideSuccessDialog} /></>)

    const addressDialogFooter = () => {
        return (
            <>
                <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideAddDialog} />
                <Button label="Submit" icon="pi pi-check" type='submit' form="add-form" className="p-button-success p-mr-2 p-mb-2" />
            </>
        )
    }

    const addressesDialogFooter = () => (<Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setAllAddressesDialog(false) }} />)

    const billingAddressesDialogFooter = () => {
        return (
            <>
                <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideAddDialog} />
                <Button label="Submit" icon="pi pi-check" type='submit' form="billing-add-form" className="p-button-success p-mr-2 p-mb-2" />
            </>
        )
    }

    const couponDialogFooter = () => (<Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideCouponDialog} />)


    const codeBodyTemplate = (rowData, props) => (<Tag className="mr-2" severity="success" value={rowData[props.field]} rounded></Tag>)
    const addressBodyTemplate = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field] + ", " + data?.city + ", " + data?.state + ", " + data?.country + ", " + data?.pincode} </>)

    const addressActionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions">
                    {
                        rowData.is_default ?
                            <Button icon="pi pi-check" className="p-button-primary p-button-rounded p-button-sm p-m-1" onClick={() => { changeSelectedAdd(rowData) }} />
                            :
                            <Button icon="pi pi-times" className="p-button-danger p-button-rounded p-button-sm p-m-1" onClick={() => { changeSelectedAdd(rowData) }} />
                    }

                    <Button icon="pi pi-trash" className="p-button-danger p-button-rounded p-button-sm p-m-1" onClick={() => { deleteSelectedAdrr(rowData) }} />
                </div>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions"> <Button style={{ width: '60px' }} label="APPLY" className="p-button-primary p-button-sm" onClick={() => { applyCode(rowData) }} /> </div>
            </>
        )
    }

    const dataviewGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-3">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            {/* <span className="product-category">{data.category}</span> */}
                            <span className="product-category">{data?.brand}</span>
                        </div>
                        {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                    </div>
                    <div className="product-grid-item-content p-mb-2">
                        <img src={data.image} alt={data.title} style={{ boxShadow: 'none', margin: '0px' }} />
                        <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                        {/* <div className="product-description">{data.description}</div> */}
                        {/* <div className="product-name">{data.description} - {`(${data?.cups_count} Cups)`} </div> */}
                    </div>

                    <div className='product-grid-item-bottom'>
                        <div className='p-col-7'>
                            <span className="product-price">₹{Number.isInteger(data.price) ? data.price : data?.price?.toFixed(2)} <small> {props.productPayload?.type == 'Cupwise' && `(₹${props.productPayload?.price}/Cup)`}</small></span>
                            {/* <span className="product-price">₹{data.price}</span> */}
                        </div>

                        <div className='p-col-5'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValue(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                        decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItemForCart = (data) => {

        return (
            <div className="p-col-12 p-md-3">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            {/* <span className="product-category">{data.category}</span> */}
                            <span className="product-category">{data?.brand}</span>
                        </div>
                        {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                    </div>
                    <div className="product-grid-item-content">
                        <img src={data.image} alt={data.title} style={{ boxShadow: 'none', margin: '0px' }} />
                        <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                        {/* <div className="product-description">{data.description}</div> */}
                        {/* <div className="product-name">{data.description} - {`(${data?.cups_count} Cups)`} </div> */}
                    </div>

                    <div className='product-grid-item-bottom'>
                        <div className='p-col-7'>
                            <span className="product-price">₹{Number.isInteger(data.price) ? data.price : data?.price?.toFixed(2)} <small> {props.productPayload?.type == 'Cupwise' && `(₹${props.productPayload?.price}/Cup)`}</small> </span>
                            {/* <span className="product-price">₹{data.price}</span> */}
                        </div>

                        <div className='p-col-5'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    <InputNumber inputStyle={{ textAlign: 'center' }} inputId="horizontal" value={data.cartQuantity} onChange={(e) => { changeCartValueFromCart(e, data) }} showButtons buttonLayout="horizontal" min={0} max={999}
                                        decrementButtonClassName="p-button-primary" incrementButtonClassName="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dataViewForPacksGridItem = (data) => {
        return (
            <div className="p-col-12 p-md-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            {/* <span className="product-category">{data.category}</span> */}
                            <span className="product-category">{data?.brand}</span>
                        </div>
                        {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                    </div>
                    <div className="product-grid-item-content">
                        <img src={data.image} alt={data.title} style={{ boxShadow: 'none', margin: '0px' }} />
                        <div className="product-name">{data.title} {`(${data?.cups_count} Cups)`} </div>
                        <div className="product-description">{data.description}</div>
                        {/* <Rating value={data.rating} readonly cancel={false}></Rating> */}
                    </div>

                    <div className='product-grid-item-bottom'>
                        <div className='p-col-10'>
                            <span className="product-price">₹{Number.isInteger(data.price) ? data.price : data?.price?.toFixed(2)} <small> {props.productPayload?.type == 'Cupwise' && `(₹${props.productPayload?.price}/Cup)`}</small></span>
                            {/* <span className="product-price">₹{data.price}</span> */}
                        </div>

                        <div className='p-col-2'>
                            <div className="grid p-fluid">
                                <div className="col-12">
                                    {data.cartQuantity == 0 ? <Button icon="pi pi-shopping-cart" onClick={() => { changePackCartValue(data) }}></Button> : <Button className="p-button-danger" icon="pi pi-trash" onClick={() => { removeFromCart(data) }}></Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const productItemTemplate = (data, layout) => { return dataviewGridItem(data) };
    const packItemTemplate = (data, layout) => { return dataViewForPacksGridItem(data) };
    const productItemTemplateForCart = (data, layout) => { return dataviewGridItemForCart(data) };

    const setDefaultAdd = () => {
        setCountries(null)
        setStates(null)
        setCities(null)
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
    }

    const hideAddDialog = () => {
        setAddressDialog(false)
        setAddBillingDialog(false)
        setDefaultAdd()
    }

    const openAddressDialog = () => {
        console.log(props.verifiedUserData);
        setAddressType(null)
        setAddressDialog(true)

        if (addressData?.length == 0) {
            const regData = props.registeredUser;
            console.log(regData);
            const dataFromRegistry = {
                addr: {
                    addr: regData?.shipping_address?.address || regData?.dashData?.addr?.addr,
                    pincode: regData?.shipping_address?.pincode || regData?.dashData?.addr?.pincode,
                    country: regData?.shipping_address?.country || regData?.dashData?.addr?.country?.name,
                    state: regData?.shipping_address?.state || regData?.dashData?.addr?.state?.name,
                    city: regData?.shipping_address?.city || regData?.dashData?.addr?.city?.name,
                },
                cno: regData?.contact_no || regData?.contact_string,
                name: regData?.name || regData?.dashData?.name,
            }

            const dashData = props.verifiedUserData?.dashData || dataFromRegistry;
            setDefaultDashData(dashData);

            const defaultData = {
                address: regData?.shipping_address?.address || regData?.dashData?.addr?.addr,
                contact_number: regData?.contact_no || regData?.contact_string,
                contact_name: regData?.name || regData?.dashData?.name,
                pincode: regData?.pincode || regData?.dashData?.addr?.pincode || regData?.shipping_address.pincode,
                country: regData?.shipping_address?.country || regData?.dashData?.addr?.country?.name,
                state: regData?.shipping_address?.state || regData?.dashData?.addr?.state?.name,
                city: regData?.shipping_address?.city || regData?.dashData?.addr?.city?.name,
            }
            console.log(defaultData);
            setAddressPayload(defaultData);

        } else {
            getCountry();
            setDefaultDashData(null);
        }
    }

    const getCSC = async (dashData) => {
        const response = await apiServices.getCountry();
        setCountries(response?.data)
        const data = response?.data.filter((x) => { if (x.id == 101) { return x; } });

        setSelectedCountry(data[0])
        const StateResponse = await apiServices.getState({ country_id: data[0]?.id });
        setStates(StateResponse?.data)
        const sta = StateResponse?.data.filter((x) => { if (x.name == dashData?.addr?.state?.name) { return x } });
        setSelectedState(sta[0]);
        const cityResponse = await apiServices.getCity({ state_id: sta[0]?.id });
        setCities(cityResponse?.data)
        const cityOp = cityResponse?.data.filter((x) => { if (x.name == dashData?.addr?.city?.name) { return x; } });
        setSelectedCity(cityOp[0])

        const payload = {
            address: dashData?.addr?.addr,
            city: cityOp[0]?.name,
            contact_name: dashData?.name,
            contact_number: dashData?.cno,
            country: data[0]?.name,
            is_default: true,
            landmark: "",
            pincode: dashData?.addr?.pincode,
            state: sta[0]?.name
        }

        setAddressPayload({ ...addressPayload, ...payload })
    }

    const getCountry = async () => {
        const response = await apiServices.getCountry();
        console.log(response?.data);
        setCountries(response?.data)
    }

    const getStates = async (data) => {
        const response = await apiServices.getState(data);
        setStates(response?.data)
    }

    const getCities = async (data) => {
        const response = await apiServices.getCity(data);
        setCities(response?.data)
    }

    const openAddDialog = () => { setAllAddressesDialog(true) }

    const rightToolbarTemplate = () => (<> <div className="p-inputgroup"> <Button className='custom-group-btn' icon="pi pi-plus" label="Add New Address" onClick={openAddressDialog} /> </div> </>)
    const placedToolbarTemplate = () => (<Button icon="pi pi-backward" label='Go Back' className="p-custom-btn" aria-label="Back" onClick={backFromPlaceOrder} />)
    const reviewToolbarTemplate = () => (<Button icon="pi pi-backward" label='Go Back' className="p-custom-btn" aria-label="Back" onClick={backFromReviewOrder} />)

    return (
        <>
            <Toast ref={toast} position='center' />
            <div className="p-grid list-demo">

                {
                    !orderPlaced &&
                    <div className="p-col-12">
                        {!reviewOrderFlag &&
                            <div className='card'>
                                <Toolbar className="p-toolbar" left={reviewToolbarTemplate}></Toolbar>
                            </div>
                        }

                        {
                            reviewOrderFlag ?
                                <>
                                    <div className="card">
                                        <h5>Quick Select Packs</h5>
                                        <DataView value={packsBeverages} layout={layout} paginator rows={6} sortOrder={sortOrder} sortField={sortField} itemTemplate={packItemTemplate} loading={loading}></DataView>
                                    </div>

                                    <div className="card">
                                        <h5>Material Products</h5>
                                        <DataView value={comboBeverages} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplate} loading={loading}></DataView>
                                    </div>
                                </> :
                                <>
                                    <div className="card">
                                        <h5>Material Products ({`${inCartProducts?.length} Products`}) </h5>
                                        <DataView value={inCartProducts} layout={layout} paginator rows={8} sortOrder={sortOrder} sortField={sortField} itemTemplate={productItemTemplateForCart} loading={loading}></DataView>
                                    </div>
                                </>
                        }


                        {
                            ((packsTotalCups != 0 || totalCups != 0) && reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6>{totalCups} Cups | ₹ {Number(totalPrice).toFixed(2)} </h6>
                                {
                                    (totalCups >= 400) && <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='REVIEW ORDER' onClick={reviewOrder} ></Button>
                                }
                            </div>
                        }

                        {
                            ((packsTotalCups != 0 || totalCups != 0) && !reviewOrderFlag) &&
                            <div className='card'>
                                <h6>Beverages</h6>
                                <h6>{inCartProducts?.reduce((accumulator, current) => accumulator + Number(current?.cartQuantity), 0)} Packets |  {totalCups} Cups | ₹ {Number(totalPrice).toFixed(2)} </h6>
                                {/* {(totalCups > 400 || packsTotalCups) && <Button className='p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='CONFIRM ORDER' onClick={placeOrder}></Button>} */}
                                {(totalCups >= 400) && <Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon="pi pi-arrow-right" iconPos="right" label='CONFIRM ORDER' onClick={placeOrder}></Button>}
                            </div>
                        }

                    </div>
                }

                {
                    orderPlaced &&
                    <>
                        <div className='p-col-12'>
                            <div className='card'>
                                <Toolbar className="p-toolbar" left={placedToolbarTemplate}></Toolbar>
                            </div>
                            {/*                             
                            <Button icon="pi pi-arrow-left" label='Go Back' className="p-button-rounded p-button-outlined p-button-danger p-mb-2" aria-label="Back" onClick={backFromPlaceOrder} /> */}
                            <div className='card p-m-2'>
                                <div className='p-grid'>
                                    <div className='p-col-5'>
                                        <div className='card p-m-0'>
                                            <h5 className='p-mt-2'>Delivered to : </h5>
                                            <h5>Shipping Address</h5>
                                            <p><b>Contact Number :</b> {props.verifiedUserData?.contact}</p>
                                            <p><b>Account Name :</b> {addressForCheckout?.company_name}</p>
                                            <p>{addressForCheckout?.shipping_address?.address}</p>
                                            <p>{addressForCheckout?.shipping_address?.country}, {addressForCheckout?.shipping_address?.state}, {addressForCheckout?.shipping_address?.city}, {addressForCheckout?.shipping_address?.pincode}</p>
                                            <hr></hr>
                                            <h5>Billing Address</h5>
                                            <p>{addressForCheckout?.billing_address?.address}</p>
                                            <p>{addressForCheckout?.billing_address?.country}, {addressForCheckout?.billing_address?.state}, {addressForCheckout?.billing_address?.city}, {addressForCheckout?.billing_address?.pincode}</p>
                                            <p>GST Number : {addressForCheckout?.billing_address?.gst_no}</p>
                                            <hr></hr>
                                        </div>
                                    </div>

                                    <Divider layout="vertical" />
                                    <div className='p-col-6'>
                                        <h5>Order Summary</h5>
                                        <div id="invoice-content">
                                            <div className="invoice p-p-0">
                                                <div className="invoice-summary">
                                                    <table>
                                                        <tbody>
                                                            <tr> <td>Machine Cost(Free)</td> <td>₹{0.00}</td> </tr>
                                                            <tr> <td>AMC Charges</td> <td>₹{Number(orderSummary?.amcAmount)?.toFixed(2)}</td> </tr>
                                                            <tr> <td>Rent Charges</td> <td>₹{Number(orderSummary?.rentAmount)?.toFixed(2)}</td> </tr>
                                                            <tr> <td>Refundable Deposite</td> <td>₹{Number(orderSummary?.depositeAmount)?.toFixed(2)}</td> </tr>
                                                            <tr> <td>Beverage ({totalCups} Cups)</td> <td>₹{Number(totalPrice).toFixed(2)}</td> </tr>
                                                            <tr> <td>Sub Total:</td> <td>₹{Number(orderSummary?.subtotal)?.toFixed(2)}</td></tr>
                                                            <tr> <td>Taxes (GST):</td> <td>₹{Number(orderSummary?.tax)?.toFixed(2)}</td> </tr>
                                                        </tbody>
                                                        <tbody>
                                                            <tr> <td><b>Total Payable Amount:</b> </td> <td><b>₹{Number(orderSummary?.total)?.toFixed(2)}</b></td> </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <h5>PAYMENT MODE</h5>
                                        <div className='card'>

                                            {
                                                (paymentType == 'COD') &&
                                                <>
                                                    <div className="field-radiobutton p-ml-0 p-mb-4">
                                                        <RadioButton inputId="OnlinePay" name="paymentMethod" value="COD" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'COD'} />
                                                        <label htmlFor="OnlinePay"> Bank Transfer </label>
                                                    </div>
                                                </>
                                            }

                                            {
                                                (paymentType == 'ONLINE') &&
                                                <>
                                                    <div className="field-radiobutton p-ml-0 p-mb-4">
                                                        <RadioButton inputId="OnlinePay" name="paymentMethod" value="ONLINE" onChange={(e) => setPaymentType(e.value)} checked={paymentType == 'ONLINE'} />
                                                        <label htmlFor="OnlinePay"> Online Payment (Get Payment Link on email and SMS) </label>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                {<Button className='p-custom-btn p-button-lg p-col-12 p-mt-2' icon={proceedClicked ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'} iconPos="right" label='PROCEED' onClick={proceedWithOrder} disabled={proceedClicked} ></Button>}

                            </div>
                        </div>
                    </>
                }

            </div>

            <Dialog visible={couponCodeDialog} style={{ width: '750px' }} header={`Available Coupon Codes`} modal className="p-fluid" footer={couponDialogFooter} onHide={hideCouponDialog} maximizable={true} blockScroll={true}>
                <div className="p-grid table-demo p-m-4">
                    <DataTable value={allCouponsList} className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No coupons found." loading={couponLoading} >
                        <Column field="coupon" header="Coupon Code" body={codeBodyTemplate}></Column>
                        <Column field="description" header="Description" body={CommonBodyForTable}></Column>
                        <Column header="Action" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </Dialog>

            <Dialog header="Success" footer={successDialogFooter} visible={successDialog} style={{ width: '30vw' }} onHide={hideSuccessDialog}>
                <center>
                    <i className="pi pi-check-circle" style={{ fontSize: '8rem', color: "green" }}></i>
                    <h3>Order Placed</h3>
                    <h6>Order Id : {placedOrderData?.order_no} </h6>
                    {
                        placedOrderData?.razorpay_url &&
                        <h6>Payment Link : <br></br> <a href={placedOrderData?.razorpay_url} target='_blank'>{placedOrderData?.razorpay_url}</a>  <i className="p-m-1 pi pi-copy" style={{ cursor: 'pointer' }} onClick={onclickThis} ></i>  </h6>
                    }
                </center>
            </Dialog>

            <Dialog visible={addressDialog} style={{ width: '1250px' }} header={"Add New Address"} modal footer={addressDialogFooter} onHide={hideAddDialog} className='p-fluid' blockScroll={true}>
                <form onSubmit={saveAddress} id="add-form">
                    <h5>SELECT DELIVERY LOCATION</h5>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="addr">Address</label>
                                <InputText id="addr" name="address" placeholder="Address" defaultValue={defaultDashData?.addr?.addr} onChange={changeAddress} autoFocus required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="landm">Landmark (Optional)</label>
                                <InputText id="landm" name="landmark" placeholder="Landmark, Additional info, etc (Optional)" onChange={changeAddress} />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="pincode">Area PIN Code</label>
                                <InputText id="pincode" name="pincode" placeholder="Area PIN Code" defaultValue={defaultDashData?.addr?.pincode} maxLength={6} onChange={changeAddress} required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="state">State</label>
                                <InputText id="state" name="state" placeholder="State" defaultValue={defaultDashData?.addr?.state?.name || defaultDashData?.addr?.state} onChange={changeAddress} required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="city">City</label>
                                <InputText id="city" name="city" placeholder="City" defaultValue={defaultDashData?.addr?.city?.name || defaultDashData?.addr?.city} onChange={changeAddress} required />
                            </div>
                        </div>

                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="cno">Contact Number</label>
                                <InputText id="cno" name="contact_number" placeholder="Contact Number" defaultValue={defaultDashData?.cno} minLength={10} maxLength={13} onChange={changeAddress} required />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">
                                <label htmlFor="cname">Contact Name</label>
                                <InputText id="cname" name="contact_name" placeholder="Contact Name" defaultValue={`${defaultDashData?.name ? defaultDashData?.name : ''} ${defaultDashData?.lastName ? defaultDashData?.lastName : ''}`} onChange={changeAddress} required />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={allAddressesDialog} style={{ width: '750px' }} header={`All Saved Addresses`} modal className="p-fluid" footer={addressesDialogFooter} onHide={() => { setAllAddressesDialog(false) }} maximizable={true} blockScroll={true}>
                <div className='card'>
                    <Toolbar right={rightToolbarTemplate}></Toolbar>
                    <div className="p-grid table-demo p-m-4">
                        <DataTable value={addressData} className="p-datatable-customers" rows={10} dataKey="id" rowHover emptyMessage="No Addresses found." >
                            <Column field="address" header="Address" body={addressBodyTemplate} style={{ width: '80%' }}></Column>
                            <Column header="Selected? / Action" body={addressActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={addBillingDialog} style={{ width: '750px' }} header={`Add Billing Address`} modal className="p-fluid" footer={billingAddressesDialogFooter} onHide={hideAddDialog} maximizable={true} blockScroll={true}>
                <form onSubmit={saveBillingAddress} id="billing-add-form">
                    <div className='p-fluid'>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="addr">Company Name</label>
                                    <InputText id="addr" name="name" placeholder="Company Name" defaultValue={defaultDashData?.billing_details?.company} onChange={changeBillingAddress} autoFocus required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="addr">Enter GSTIN Details</label>
                                    <InputText id="addr" name="gst" placeholder="Enter GSTIN Details" defaultValue={defaultDashData?.billing_details?.gstNumber || defaultDashData?.billing_details?.gstnumber} onChange={changeBillingAddress} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="addr">Company Address</label>
                                    <InputText id="addr" name="address" placeholder="Company Address" defaultValue={defaultDashData?.billing_details?.addr} onChange={changeBillingAddress} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="addr">City</label>
                                    <InputText id="addr" name="city" placeholder="City" defaultValue={defaultDashData?.billing_details?.city?.name || defaultDashData?.billing_details?.city} onChange={changeBillingAddress} required />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="addr">Pincode</label>
                                    <InputText id="addr" name="pincode" placeholder="Pincode" defaultValue={defaultDashData?.billing_details?.pincode} onChange={changeBillingAddress} maxLength={6} required />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

        </>
    )
}

